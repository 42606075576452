$img-url: 'https://empresas.serasaexperian.com.br/prints/periscope';
$temp-img-url: '/assets/temp';

// <------------- COLORS -------------->

@import "styles/colors";

// <------------- GLOBAL -------------->
$global-weight-normal: 400;
$global-weight-bold: 700;
$global-weight-medium: 500;
$global-weight-light: 300;
$global-weight-thin: 100;

$font-family-sans-serif: "Roboto", sans-serif;
$font-size-base: 16px;
$body-font-weight: $global-weight-light;
$line-height-base: 1.25;

$text-color: palette("grey", dark);
$link-color: $text-color;

$headings-font-weight: $global-weight-thin;

$font-size-h1: 46px;
$font-size-h2: 38px;
$font-size-h3: 26px;
$font-size-h4: 20px;
$font-size-h5: 16px;
$font-size-h6: 14px;

// <------------- HEADER -------------->

$height-top-bar: 35px;
$height-top-fixed: 80px;
$height-top-fixed-xs: 80px;

$bg-top-bar: palette("grey", light);
$bg-top-fixed: #ffffff;

// <--------- HEADER LOGIN ---------->
$hl-max-width: 1600px;
$hl-min-width:320px;

$mobile-max-width: 320px;

$desktop-height: 80px;

$mobile-height: 56px;

// <------------- BTN -------------->

$btn-height: 44px;
$btn-padding: 40px;
$btn-radius: 6px;
$submit-radius: $btn-radius;
$btn-font-size: 16px;
$btn-font-weight: $global-weight-light;

// <------------- FORM -------------->

$ipt-border-color: palette("grey", medium);
$ipt-placeholder-color: palette("grey", medium);
$ipt-text-color: palette("grey", dark);
$ipt-color-error: palette(experian, "red");
$ipt-color-fill-label: palette(experian, light-blue);
$ipt-height: 44px;
$ipt-padding: 10px;
$ipt-radius: 5px;
$ipt-font-size: 12px;
$ipt-font-size-active: 9px;
$form-group-margin: 10px;
$width-over-register: 410px;

// <------------- QUESTIONÁRIO PERFIL -------------->

$color-pergunta: palette("grey");
$color-pergunta-num: palette(experian, "pink");
$color-resposta-first: palette(experian, "violet");
$fz-pergunta: 15px;
$fz-resposta-first: 40px;
$fz-resposta-first-xx: 60px;

$fz-btn-questionario: 16px;
$radius-btn-questionario: 12px;
$height-btn-questionario: 45px;
$width-btn-questionario: 220px;

$color-pontos: palette(experian, "dark-blue");
$color-pontos-selected: palette(experian, "pink");
$fz-pontos: 15px;
$width-pontos: 35px;
$border-pontos: 1px;
$border-pontos-selected: 3px;
$radius-pontos: 5px;

// <------------- FONT AWSOME -------------->

$fa-font-path: "~font-awesome/fonts";

// <------------- VAR BOOTSTRAP -------------->

//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

