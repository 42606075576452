// VARS
$circle-width: 0.08em;

// colors default
$primary-color: #4d97ff;
$secondary-color: #e0e0e1;
$bg-color: #fff;

// CIRCLE
// classes 2 extend
.rect-auto{
	clip: rect(auto, auto, auto, auto);
}

.pie {
	position: absolute;
	border: $circle-width solid $primary-color;
	width: 1em;
	height: 1em;
	clip: rect(0em, 0.5em, 1em, 0em);
	border-radius: 50%;
	transform: rotate(0deg);
}

.pie-fill {
	transform: rotate(180deg);
}



// main
.c100 {

	position: relative;
	font-size: 120px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	float: left;
	background-color: $secondary-color;

	// center circle to its parent
	&.center{
		float: none;
		margin: 0 auto;
	}

	// bigger size
	&.big{
		font-size: 240px;
	}

	// smaller size
	&.small{
		font-size: 48px;
	}

	// centered value inside circle
	> span {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 4em;
		line-height: 4em;
        font-size: 0.25em;
        font-weight: 500;
		display: block;
		text-align: center;
        white-space: nowrap;
        transition-property: all;
        transition-duration: .2s;
        transition-timing-function: ease-out;
	}

	// background inside the circle
	&:after{
		position: absolute;
		top: $circle-width;
		left: $circle-width;
		display: block;
		content: " ";
		border-radius: 50%;
		background-color: $bg-color;
		width: 1 - (2 * $circle-width);
		height: 1 - (2 * $circle-width);
		transition-property: all;
        transition-duration: .2s;
        transition-timing-function: ease-in;

	}

	// the slice (mask)
	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
	}

	// circle to show the status
	.bar {
		@extend .pie;
	}


	// loop to create all needed elements automatically
	@for $j from 51 through 100 {

		&.p#{$j} .slice {
			@extend .rect-auto;
		}

		&.p#{$j} .bar:after{
			@extend .pie-fill;
		}

		&.p#{$j} .fill{
			@extend .pie;
			@extend .pie-fill;
		}

	}

	// loop to rotate all 100 circles
	@for $j from 1 through 100 {
		&.p#{$j} .bar {
			transform: rotate((3.6 * $j) + deg);
		}
	}


}