/*--------------------------------------------------------------*/
// FLOATING LABEL
/*--------------------------------------------------------------*/

.floating-label {
  position: relative;
  input[type="text"],
  input[type="password"],
  input[type="search"],
  input[type=number],
  .field-email,
  textarea {
    color: $ipt-text-color;
    margin-bottom: 0;
    padding-top: 6px;
  }

  input:focus {
    outline: none;
  }

  /* LABEL ======================================= */
  label {
    //color: $ipt-placeholder-color;
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    left: 10px;
    top: 16px;
    // transition: 0.2s ease all;
    // -moz-transition: 0.2s ease all;
    // -webkit-transition: 0.2s ease all;
  }

  /* active state */
  textarea:not(:placeholder-shown) + label,
  textarea:valid:required + label,
  textarea:disabled + label,
  input:not(:placeholder-shown) + label,
  input:valid:required + label,
  input:disabled + label {
    visibility: initial;
    color: $ipt-color-fill-label;
    font-size: $ipt-font-size-active;
    top: 5px;
  }


  textarea:not(:placeholder-shown)
  input:not(:placeholder-shown) {
    //--aquichange
    margin-top: 0.2em !important;
    margin-bottom: 0em !important;
    padding-bottom: 0em !important;
  }

  textarea:not(:placeholder-shown) {
    padding-top: 16px;
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    width: 300px;
  }
  .bar:before,
  .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $ipt-color-fill-label;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after,
  input:disabled ~ .bar:before,
  input:disabled ~ .bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: $ipt-color-fill-label;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: $ipt-color-fill-label;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: $ipt-color-fill-label;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
}

/*--------------------------------------------------------------*/
// FORM
/*--------------------------------------------------------------*/

form {
  font-size: $ipt-font-size;
  a {
    color: palette(experian, "light-blue");
    display: block;
    text-decoration: underline;
  }
}

.form-group {
  margin-bottom: $form-group-margin;
  position: relative;

  .icons-preview {
    color: palette("grey", dark);
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 20px;
  }
}

// input{
//   @include placeholder {
//     color: $ipt-placeholder-color;
//   }
// }

input[type="text"],
input[type="password"],
input[type="search"],
input[type=number],
.field-email,
textarea {
  border: 1px solid $ipt-border-color;
  border-radius: $ipt-radius;
  color: $ipt-text-color;
  display: inline-block;
  height: $ipt-height;
  /* line-height: $ipt-height; */
  padding: 0 $ipt-padding;
  margin-bottom: 5px;
  width: 100%;
}

@media (min-width: 1279px) {
  .id-validation-text {
    width: 70%;
  }
}

textarea {
  resize: none;
  height: 150px;
}

[type="checkbox"],
[type="radio"] {
  margin-right: 5px;

  &:last-child {
    margin-left: 20px;
  }
}

input[disabled] {
  background-color: transparent;
}

.check-box {
  display: inline-block;

  label {
    font-size: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 17px;
      height: 18px;
      float: left;
      margin-right: 10px;
      background: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/check-box.png") 0 0;
      background-repeat: no-repeat;
      border: 1px solid;
      border-color: transparent;
    }
  }

  input[type="checkbox"] {
    display: none;
    &:checked ~ label:before {
      background-position-y: -18px;
    }
  }

  &.new-check-box {
    // display: inline-block !important;
    padding: 0 0 0 25px;
    position: relative;
    label {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
/*
* new form
*/
.new-form {
  h3,
  label,
  small,
  p {
    color: palette("grey", "dark");
  }

  small {
    font-size: 1rem;
  }

  .form-group {
    margin-bottom: 1.2rem;
    .help-block {
      position: absolute;
      font-size: 11px;
    }
  }
  .has-error {
    //--aquichange
    input {
      background-color: #ffced5 !important;
    }
    textarea {
      background-color: #ffced5 !important;
    }
    select {
      border-color: palette(experian, "pink");
      background-color: #ffced5 !important;
    }
    label {
      color: palette(experian, "pink");
      max-height: 2em !important;
    }
    /* active state */
    input:focus ~ label,
    input:valid ~ label,
    input:disabled ~ label {
      color: palette(experian, "pink");
    }
    input::placeholder {
      color: palette(experian, "pink");
    }
    &:after {
      content: "!";
      position: absolute;
      right: 11px;
      color: palette(experian, "pink");
      top: 14px;
      font-weight: bold;
    }
  }
}

/*
* New form implemented in recomenda
*/

.form-v2 {
  label {
    font-size: 12px;
    padding-left: 7px;
    margin-bottom: 0.25rem;
  }

  input[type="text"],
  input[type=number] {
    @include placeholder {
      color: #bbbbba;
    }

    &.field-invalid {
      @include placeholder {
        color: #ba2f7d;
      }
      background-color: #ffced5;
    }
  }

  select {
    color: #bbbbba;
    &.ng-touched {
      color: palette("grey", dark);
    }
    option {
      color: palette("grey", dark);
      &[disabled] {
        color: #bbbbba;
      }
    }
    &.form-control:not([size]):not([multiple]) {
      height: 44px;
    }

    &.field-invalid {
      background-color: #ffced5;
      color: #ba2f7d;
    }
  }

  .btn {
    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }
  }
}

/*
*  CUSTOM CHECKBOX
*/
.custom-checkbox {
  input[type="checkbox"] {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  input[type="checkbox"] + label {
    padding-left: 26px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;

    &.checkbox-mini {
      height: 16px;
      line-height: 16px;
    }
  }

  input[type="checkbox"]:checked + label {
    background-position: 0 -20px;

    &.checkbox-mini {
      background-position: 0 -16px;
    }
  }

  label {
    background-image: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/checkbox-medium.png");
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.checkbox-mini {
      background-image: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/checkbox-mini.png");
    }
  }
}
