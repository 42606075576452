div.tree::before{
  content: "";
  position: absolute;
//  border-left: 1px dotted #29ff15;
  height: 100%;
  //left: 24px;
}
.treeview-container {
  input[type='checkbox'] {
    content: url(/../../assets/svg/checkbox-outline-empty.svg);
    appearance: none;
    color: #1d4f91;
  }
  input[type='checkbox']:checked {
    content: url(/../../assets/svg/tree-check.svg);
    appearance: none;
    background: #FFFFFF;
  }
  .fa-caret-down + .form-check input[type=checkbox] {
    content:  url(/../../assets/svg/round-done.svg);
    appearance: none;
    background: #FFFFFF;
  }

  .row-item {
    .fa {
      display: none !important;
    }

    .fa-caret-down {
      content: url('/../../assets/svg/round-arrow-drop-down.svg') !important;
      display: inline-block !important;
      width: 30px !important;
      //border-bottom: 1px dotted black;
    }

    .fa-caret-right {
      content: url('/../../assets/svg/arrow-right.svg') !important;
      display: inline-block !important;
      width: 30px !important;
     // border-bottom: 1px dotted black;
    }

  }
}

