.tree {
  position: relative;
}
//div.tree::before{
//  content: "";
//  position: absolute;
//  border-left: 1px dotted #29ff15;
//  height: 100%;
//  left: 24px;
//}

div.tree div.tree-children::before {
  content: '';
  position: absolute;
  border-left: 1px dotted #ff392b;
  height: 100%;
  top: -14px;
  left: 5px;
}

div.tree {
  padding-left: 0;
  margin-left: -5px
}

div.tree div.tree-children {
  position: relative;
  padding-left: 0;
  margin-left: 16px
}

div.tree tree-node>div>.node-wrapper {
   margin-left: 24px
 }
div.tree tree-node>div>.node-wrapper:first-child {
  margin-left: 32px
}
div.tree tree-node>div>.node-wrapper>.node-content-wrapper {
  margin-left: 4px
}

div.tree tree-node>div.tree-node-leaf>.node-wrapper {
  margin-left: 0
}

div.tree tree-node>div::before {
  content: "";
  position: absolute;
  border-bottom: 1px dotted #d741ff;
  width: 7px;
  margin-top: 12px;
  left: 7px
}

div.tree tree-node>div:first-child::before {
  content: url('/../../assets/svg/arrow-right.svg') !important;
  position: absolute;
  border-bottom: 1px dotted #23527c;
  width: 7px;
  margin-top: 12px;
  left: 7px
}

div.tree tree-node>div .toggle-children-wrapper {
  width: 13px;
  height: 13px;
  //border: 1px solid #23527c;
  position: absolute;
  left: 20px;
  margin-top: 5px;
  margin-left: 0;
  display: inline-block;
  //background-color: #fff;
  z-index: 1
}

div.tree tree-node>div .toggle-children-wrapper::before {
  content: "";
  display: inline-block;
  width: 7px;
  //border-top: 1px solid #23527c;
  position: absolute;
  top: 5px;
  left: 2px
}

div.tree tree-node>div .toggle-children-wrapper.toggle-children-wrapper-collapsed::after {
  content: "";
  display: inline-block;
  height: 7px;
  //border-left: 1px solid #23527c;
  position: absolute;
  top: 2px;
  left: 5px
}

div.tree tree-node>div .toggle-children-wrapper .toggle-children {
  display: none
}

div.tree tree-node>div .node-content-wrapper {
  margin-left: 4px
}

div.tree>tree-node>div::before {
  left: 14px
}

div.tree>tree-node>div>.node-wrapper>tree-node-expander>.toggle-children-wrapper {
  left: 22px
}

.card-tree input[type='checkbox'] {
  content: url(/../../assets/svg/round-done.svg);
  -webkit-appearance: none;
}
.card-tree input[type='checkbox']:checked {
  content: url(/../../assets/svg/tree-check.svg);
  -webkit-appearance: none;
}
.card-tree input[type='checkbox']:before:checked {
content:url('/../../assets/svg/round-arrow-drop-down.svg') !important;
-webkit-appearance: none;
}
//.tree-node-checkbox  {
//  content: url('/../../assets/svg/round-arrow-drop-down.svg') !important;
//  display: inline-block !important;
//  width: 30px !important;
//}

//.tree-node-checkbox  {
//  content: url('/../../assets/svg/arrow-right.svg') !important;
//  display: inline-block !important;
//  width: 30px !important;
//}
