/*
/* Line Height
*/

.lh {
  &-14 {
    line-height: 14px;
  }
  &-16 {
    line-height: 16px;
  }
  &-18 {
    line-height: 18px;
  }
  &-22 {
    line-height: 22px;
  }
  &-26 {
    line-height: 26px;
  }
  &-30 {
    line-height: 30px;
  }
  &-56 {
    line-height: 56px;
  }
  &-66 {
    line-height: 66px;
  }
  &-93 {
    line-height: 93px;
  }
  &-128 {
    line-height: 128px;
  }

  &-sm {
    @media (min-width: 768px) {
      &-14 {
        line-height: 14px;
      }
      &-16 {
        line-height: 16px;
      }
      &-18 {
        line-height: 18px;
      }
      &-22 {
        line-height: 22px;
      }
      &-26 {
        line-height: 26px;
      }
      &-30 {
        line-height: 26px;
      }
      &-56 {
        line-height: 56px;
      }
      &-66 {
        line-height: 66px;
      }
      &-93 {
        line-height: 93px;
      }
      &-128 {
        line-height: 128px;
      }
    }
  }

  &-md {
    @media (min-width: 991px) {
      &-14 {
        line-height: 14px;
      }
      &-16 {
        line-height: 16px;
      }
      &-18 {
        line-height: 18px;
      }
      &-22 {
        line-height: 22px;
      }
      &-26 {
        line-height: 26px;
      }
      &-30 {
        line-height: 26px;
      }
      &-56 {
        line-height: 56px;
      }
      &-66 {
        line-height: 66px;
      }
      &-93 {
        line-height: 93px;
      }
      &-128 {
        line-height: 128px;
      }
    }
  }
}

.line-normal {
  line-height: normal;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-60 {
  line-height: 60px;
}

.line-h-70 {
  line-height: 70px;
}
