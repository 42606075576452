@import "styles/mixins";
@import "styles/variables";

// Tooltip

.consult-tooltip {
  width: 16px;
}

::ng-deep .tooltip {
  z-index: 100;
}

::ng-deep .consult-tooltip-component .tooltip-inner {
  font-family: Roboto, sans-serif;
  background-color: #598cc9;
  padding: 10px 16px;
}

::ng-deep .bs-tooltip-right .arrow {
  top: 44% !important;
}

::ng-deep .bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #598cc9;
}


::ng-deep .bs-tooltip-bottom .arrow {
  left: 48% !important;
}

::ng-deep .bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #598cc9;
}
