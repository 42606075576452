.bg {
  &-pink {
    background: palette(experian, 'pink');
  }

  &-light-pink {
    background-color: palette(experian, 'light-pink');
  }

  &-light-blue {
    background-color: palette(experian, 'light-blue');
  }

  &-dark-blue {
    background-color: palette(experian, 'dark-blue');
  }

  &-polo-blue {
    background-color: $color-polo-blue;
  }

  &-blue-marine {
    background-color: $color-san-marino;
  }

  &-sapphire {
    background-color: palette(experian, 'sapphire');
  }

  &-violet {
    background-color: palette(experian, 'violet');
  }

  &-violet-light {
    background-color: palette('violet', 'light');
  }

  &-white {
    background-color: white;
  }

  &-light-white {
    background-color: palette('white', 'light');
  }

  &-medium-white {
    background-color: palette('white', 'medium');
  }

  // Purple
  &-purple {
    background-color: $color-purple;
  }

  &-light-purple {
    background-color: $color-purple-light;
  }

  &-dark-purple {
    background-color: $color-purple-dark;
  }

  &-red {
    background: palette(experian, 'red');
  }

  &-danger, &-monza {
    background-color: $color-monza;
  }

  &-orange {
    background: palette(experian, 'orange');
  }

  &-light-grey {
    background-color: $color-custom-grey-light;
  }

  &-light-grey-2 {
    background-color: $color-light-gray;
  }

  &-medium-grey {
    background-color: palette('grey', 'medium');
  }

  &-dark-gray-2 {
    background-color: #999999;
  }

  &-alabaster {
    background-color: $color-alabaster;
  }

  &-seanse {
    background-color: $color-seanse;
  }

  &-buttercop {
    background-color: $color-buttercop;
  }

  &-royal-heath {
    background-color: $color-royal-heath;
  }

  &-prim {
    background-color: $color-prim;
  }

  &-cerise {
    background-color: $color-cerise;
  }

  &-honey-flower {
    background-color: $color-honey-flower;
  }

  &-west-side {
    background-color: $color-west-side;
  }

  &-matisse {
    background-color: $color-matisse;
  }

  &-friar-gray {
    background-color: $color-friar-gray;
  }

  &-danube {
    background-color: $color-danube;
  }

  &-orchid {
    background-color: $color-orchid;
  }

  &-malibu {
    background-color: $color-malibu;
  }

  &-wisteria {
    background-color: $color-wisteria
  }

  &-oslo-gray {
    background-color: $color-oslo-gray;
  }

  &-emerald {
    background-color: $color-emerald;
  }
}
