$white-color: #ffffff;
$black-color: #000000;
$metal-black-color: #333333;
$silver-color: #CACBCC;
$silver-sand-color: #C1C2C3;
$dark-gray-color: #828588;
$gray-f7: #f7f7f8;
$gray-a9: #a9abae;
$gray-ef: #eff0f0;
$gray-e0: #e0e0e1;
$gray-e6: #e6e6e6;
$gray-8c: #8c8d8f;
$gray-de: #dedede;
$gray-63: #63666a;
$gray-5b: #5b666b;
$royal-blue-color: #1D4F91;
$blue-59: #598cc9;
$blue-90: #90accf;
$blue-dc: #dceaf9;
$indigo-blue-color: #426DA9;
$light-blue-color: #8EA7CB;
$polo-blue-color: #8ea7cb;
$magenta-color: #AF1685;
$hot-pink-color: #E63888;
$zircon-color: #f4f9ff;
$green-00: #009f4d;
$green-f2: #f2faf5;
$orange-d9: #d9a509;
$orange-d: #fff6d0;
$danger-color: #e4002b;
$danger-fd: #fdf2f4;

$colors: (
  black: (
    metal: $metal-black-color
  ),
  blue: (
    royal: $royal-blue-color,
    indigo: $indigo-blue-color,
    polo: $polo-blue-color
  ),
  gray: (
    dark: $dark-gray-color
  ),
  magenta: (
    dark: #AF1685
  ),
  pink: (
    hot: $hot-pink-color
  )
);



//SEDS TOKEN Brand Primary
$seds-experian-color-primary: #2A5595;
$seds-experian-color-primary-light:	#687DB0;
$seds-experian-color-primary-dark:	#1E437A;

//SEDS TOKEN Brand Primary Alternative
$seds-experian-color-primary-alt:	#C6CBE1;
$seds-experian-color-primary-alt-light:	#F7F8FB;
$seds-experian-color-primary-alt-dark:	#A6AFD0;

//SEDS TOKEN Brand Secondary
$seds-experian-color-secondary:	#E80070;
$seds-experian-color-secondary-light:	#FEFB90;
$seds-experian-color-secondary-dark:	#A31B50;

//SEDS TOKEN Brand Secondary Alternative
$seds-experian-color-secondary-alt:	#FFBACB;
$seds-experian-color-secondary-alt-light:	#FFF6F8;
$seds-experian-color-secondary-alt-dark:	#FE5B90;

//SEDS TOKEN Brand Tertiary
$seds-experian-color-tertiary:	#77127B;
$seds-experian-color-tertiary-light:	#A768A6;
$seds-experian-color-tertiary-dark:	#4A154B;

//SEDS TOKEN Brand Tertiary Alternative
$seds-experian-color-tertiary-alt:	#DEC4DD;
$seds-experian-color-tertiary-alt-light:	#FBF7FA;
$seds-experian-color-tertiary-alt-dark:	#B985B7;

//SEDS TOKEN Cores neutras
$seds-color-neutral-white:	#FFFFFF;
$seds-color-neutral-grey:	#E2E4E7;
$seds-color-neutral-grey-light:	#F8F8F9;
$seds-color-neutral-grey-dark:	#C8CCD1;
$seds-color-neutral-black:	#384553;
$seds-color-neutral-black-light:	#5E6976;
$seds-color-neutral-black-dark:	#252C34;

//SEDS TOKEN feedback
$seds-color-feedback-success:	#1B7749;
$seds-color-feedback-success-light:	#0FAC67;
$seds-color-feedback-success-dark:	#1A4D31;
$seds-color-feedback-success-alt:	#C7EDD4;
$seds-color-feedback-success-alt-light:	#F1FBF4;
$seds-color-feedback-success-alt-dark:	#92DCAE;
$seds-color-feedback-warning:	#E57B08;
$seds-color-feedback-warning-light:	#FF9735;
$seds-color-feedback-warning-dark:	#9E5612;
$seds-color-feedback-warning-alt:	#FFE0C2;
$seds-color-feedback-warning-alt-light:	#FFF7F0;
$seds-color-feedback-warning-alt-dark:	#FFBF85;
$seds-color-feedback-error:	#D0141A;
$seds-color-feedback-error-light:	#FA1320;
$seds-color-feedback-error-dark:	#851B15;
$seds-color-feedback-error-alt:	#FFDCD3;
$seds-color-feedback-error-alt-light:	#FFF6F3;
$seds-color-feedback-error-alt-dark:	#FFBDAD;

//SEDS TOKEN support colors
$seds-color-data-teal:	#188C84;
$seds-color-data-teal-light:	#54C2B8;
$seds-color-data-teal-dark:	#1C5F59;
$seds-color-data-teal-alt:	#CAEBE7;
$seds-color-data-teal-alt-light:	#F2FAF9;
$seds-color-data-teal-alt-dark:	#98D8D1;
$seds-color-data-sapphire:	#0C7092;
$seds-color-data-sapphire-light:	#3097AA;
$seds-color-data-sapphire-dark:	#165B76;
$seds-color-data-sapphire-alt:	#DAE6EE;
$seds-color-data-sapphire-alt-light:	#F6F9FB;
$seds-color-data-sapphire-alt-dark:	#8EB6CC;
$seds-color-data-yellow:	#E57B08;
$seds-color-data-yellow-light:	#FF9A00;
$seds-color-data-yellow-dark:	#9E5612;
$seds-color-data-yellow-alt:	#F8EFC4;
$seds-color-data-yellow-alt-light:	#FFFCF0;
$seds-color-data-yellow-alt-dark:	#FFC400;
$seds-color-data-yellow-alt:	#F8EFC4;
$seds-color-data-yellow-alt-light:	#FFFCF0;
$seds-color-data-yellow-alt-dark:	#FFC400;
$seds-color-data-lime:	#7D8314;
$seds-color-data-lime-light:	#AFB904;
$seds-color-data-lime-dark:	#555915;
$seds-color-data-lime-alt:	#E9E7AF;
$seds-color-data-lime-alt-light:	#FAF9EB;
$seds-color-data-lime-alt-dark:	#CFD265;
