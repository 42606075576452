@import 'variables';
@import 'mixins';
@import 'components';
@import 'animations';

body {
  &.v2 {
    color: $default-color;

    .text {
      @include generator-color-class;
    }

    .background {
      @include generator-color-class('background');
    }

    @each $name, $color in $colors {
      .color {
        @include generator-color-class('states');
      }
    }
  }

  &.lock {
    overflow: hidden;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

.small-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 16px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
}