@mixin generator-color-class($attribute: 'color', $prefix: '-', $separator: '-', $base: 'base') {
  @each $name, $color in $colors {

    @if $attribute != 'states' {
      &#{$prefix}#{'' + $name} {

        @each $tone, $hex in $color {

          &#{if($tone != $base, #{$separator}#{'' + $tone}, '')} {
            #{$attribute}: $hex;
          }

        }

      }

    } @else {
      @each $tone, $hex in $color {

        &#{$prefix}#{'' + $tone}#{$separator}#{'' + $name} {
          &--hover {
            &:hover {
              color: $hex;
            }
          }
          &--active {
            color: $hex !important;
            svg {
              path {
                fill: currentColor;
              }
            }
          }
        }

      }
    }


  }
}
