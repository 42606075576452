// Menu blocks
//
.w {
  &70 {
    width: 70%;
  }

  &100 {
    width: 100%;
  }
}
.h-100 {
  height: 100%;
}

.absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.icon-check-circle-outer {
  width: 20px;
}

.menu-blocks {
  display: inline-block;
  padding: 25px 0;
  width: 100%;

  a {
    border: {
      color: palette(experian, "purple");
      style: solid;
      width: 1.5px;
    }
    background-clip: padding-box;
    border-radius: 12px;
    background-color: #fff;
    color: palette(experian, "purple");
    display: inline-block;
    font-size: 14px;
    font-weight: $global-weight-light;
    height: 140px;
    margin-left: 20px;
    padding: 20px 0;
    position: relative;
    text-align: center;
    width: 165px;

    &:first-child {
      margin: 0;
    }

    &.active,
    &:hover {
      border: {
        color: palette(experian, "purple");
        width: 3.5px;
      }
    }

    &.disabled {
      pointer-events: none;
      border: 1.5px solid rgba(152, 40, 129, 0.4);

      figcaption,
      img {
        opacity: 0.4;
      }
    }

    figure {
      margin: 0 auto;
      text-align: center;

      img {
        max-width: 50%;
        margin: 0 auto;
      }

      figcaption {
        font-size: 14px;
        margin: 10px auto 0 auto;
        text-transform: none;
      }
    }

    .snd-msg {
      font-size: 56px;
    }
  }
}

// <------------- HOME -------------->
.cards-home:not([class*="bg-arrow-"]) {
  @media (max-width: $screen-sm-max) {
    padding: 32px 0;
  }

  @media (min-width: $screen-md-min) {
    padding: 80px 0;
  }
}

.cards-home .card-perfil-inner {
  @media (min-width: $screen-md-min) {
    padding: 42px 0;
  }
}

.home-vh {
  @media (min-width: 992px) {
    height: calc(100vh - #{$height-top-fixed});
  }
}

figure {
  margin: 20px auto;
  text-align: center;

  img {
    display: block;
    margin: 0 auto 15px auto;
  }

  figcaption {
    font-size: 16px;
    text-transform: uppercase;
  }

  @media (min-width: $screen-sm-min) {
    margin: 50px auto;

    figcaption {
      font-size: 21px;
    }
  }
}

.tooltip-icon-info {
  width: 15px;
  height: 15px;
  color: #9b9b9b;
  font-size: 11px;
  border: 1px solid #9b9b9b;
  display: inline-block;
  border-radius: 100%;
  font-family: Roboto, sans-serif;
}

$height: calc(100vh - 115px);

@media screen and (min-width: 993px) {
  $height: $height;
}

.cards-home {
  .title {
    margin-bottom: 20px;
  }

  .description {
    font-size: 18px;
  }

  @media (min-width: $screen-md-min) {
    .title {
      margin-bottom: 30px;
    }

    .description {
      font-size: 22px;
    }
  }

  &.card-carousel {
    padding: 0 !important;
    position: relative;

    @media (min-width: $screen-md-min) {
      height: auto;
    }

    .swiper-slide {
      overflow: hidden !important;
    }

    .text-area {
      font-weight: $global-weight-thin;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;

      .inner {
        bottom: 50px;
        position: absolute;
        width: 100%;
      }

      .title {
        color: palette(experian, "yellow");
        margin: 0;
      }
    }

    @media (max-width: 767px) {
      height: auto;

      .font-43 {
        font-size: 30px;
      }

      .text-area {
        position: relative;
        height: auto;
        z-index: -1;
        padding: 23px 0px;
        background: #632574;
        /* Old browsers */
        background: -moz-linear-gradient(top, #632574 0%, #284789 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #632574 0%, #284789 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #632574 0%, #284789 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#632574', endColorstr='#284789', GradientType=0);
        /* IE6-9 */
      }
    }

    .scroll-anchor-content {
      z-index: 1030;
      bottom: 15px;
      left: calc(50% - 300px);
      position: absolute;
      min-height: 40px;
      width: 300px;
      text-align: center;

      & > span {
        display: block;
        margin-right: 25px;
      }

      .scroll-anchor {
        transform: translateX(-50%);
        background: transparent;
        border: 2px solid palette("white");
        color: palette("white");
        padding: 6px 0;
        border-radius: 15px;
        height: 48px;
        width: 29px;
        display: block;
        margin: auto;

        i {
          display: block;
          animation: scrollArrow 700ms linear infinite alternate;
        }
      }

      @media (max-width: $screen-md-min) {
        display: none;
      }
    }
  }
}

// <------------- PAGES -------------->
.header-pages {
  min-height: 250px;

  > .container {
    min-height: 250px;
  }
}

.tips-pwd {
  background-color: #fff;
  border-radius: 6px;
  //font-size: 13px;
  // margin-bottom: 20px;
  padding: 10px 15px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      // font-weight: $global-weight-light;
      // padding-bottom: 10px;
      &.error {
        color: #c91841;

        .icons {
          @extend .fa-times;
        }
      }

      &.ok {
        color: palette("green", "base");

        .icons {
          @extend .fa-check;
        }
      }

      &.clear {
        color: palette("experian", "dark-blue");

        .icons {
          font-size: 5px;
          position: relative;
          top: -2px;
          @extend .fa-circle;
        }
      }

      .icons {
        min-width: 18px;
        display: inline-block;

        @media (max-width: 767px) {
          min-width: 14px;
        }
      }
    }
  }
}

[class*="master-area-interna-"] {
  background: palette("grey", medium);
}

.overflow-70 {
  max-height: 70vh;
  min-height: 70vh;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.overflow-h-650 {
  max-height: 650px;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bg-transparent {
  .modal-content {
    background-color: transparent;
    border: 0 none;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) {
    text-align: center;
    padding: 0!important;
  }
}

.modal-footer {
  padding-bottom: 2rem !important;
}

.modal.in {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;

  @media (max-width: 767px) {
    margin: 0.5vh;
  }

  .close-modal {
    background: none;
    border: none;
    font-weight: $global-weight-light;
    padding: 0;
    margin: 0;
    opacity: 1;
    font-size: 16px;
    line-height: normal;
    transition: unset;
  }
}

.modal-alert {
  .modal-content {
    background: none;
  }

  .modal-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: right;
    border: none;
    min-height: 122px;
    padding-top: 4px;
    padding-right: 9px;
  }

  .modal-body {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    min-height: 100px;
    padding: 0 15px 20px;
  }

  .content-alert {
    border-radius: 6px;
  }

  .title {
    font-weight: $global-weight-thin;
    font-size: 36px;
  }

  .subTitle {
    font-weight: $global-weight-thin;
    font-size: 26px;
  }

  .desc {
    font-weight: $global-weight-light;
    font-size: 20px;
  }

  .close {
    .fa {
      font-size: 15px;
      color: white;
      margin-left: 0.5rem;
    }
  }

  .alert-title-ico {
    border-radius: 50%;
    background: #f5f5f5;
    width: 80px;
    height: 80px;
    margin: -40px auto 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alert-warning {
    background: palette(experian, "purple");

    .title {
      color: palette(experian, "purple");
    }

    .subTitle {
      color: palette(experian, "purple");
    }

    .alert-title-ico {
      .fa {
        color: palette(experian, "purple");
      }
    }
  }

  .alert-success {
    background: palette(experian, "light-green");

    .title {
      color: palette(experian, "light-green");
    }

    .subTitle {
      color: palette(experian, "light-green");
    }

    .alert-title-ico {
      .fa {
        color: palette(experian, "light-green");
      }
    }
  }

  .alert-danger {
    background: palette(experian, "red");

    .title {
      color: palette(experian, "red");
    }

    .subTitle {
      color: palette(experian, "red");
    }

    .alert-title-ico {
      .fa {
        color: palette(experian, "red");
      }
    }
  }

  .alert-info {
    background: palette(experian, "light-blue");

    .title {
      color: palette(experian, "light-blue");
    }

    .subTitle {
      color: palette(experian, "light-blue");
    }

    .alert-title-ico {
      .fa {
        color: palette(experian, "light-blue");
      }
    }
  }
}

.modal-default {
  .modal-header {
    min-height: auto;
    padding-top: 0.5rem;
    background-color: #fafafa;
  }
}

.modal-feedback {
  .modal-content {
    background: transparent;
    border: 0 none;
    position: static;
  }

  margin: 100px auto 30px;

  .modal-header {
    //padding-left: 200px;
    padding-top: 30px;
    border-bottom: 0;
    margin-bottom: 1rem;
  }

  .img-feedback {
    position: absolute;
    width: 200px;
    height: auto;
    top: -37px;
    left: -37px;
  }

  .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.modal-button {
  padding: 15px;

  .btn-modal {
    margin: 0 0.5rem;
  }
}

.text-close {
  font-weight: $global-weight-light;
  font-size: 13px;
  color: white;
}

.btn-modal {
  color: white;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.btn-experian-purple {
  color: #ffffff !important;
}

.btn-tundora {
  background-color: $color-tundora !important;
}

.btn-experian-primary {
  color: #632678 !important;
}

.btn-experian-secondary {
  background-color: #ffffff !important;
  border: solid 1px #575755 !important;
  color: #575755 !important;
}

.btn-experian-yellow {
  color: #575755 !important;
}

.btn-purple {
  background: palette(experian, "purple");
}

.btn-dark-blue {
  background: palette(experian, "dark-blue");
  &:not(.close) {
    span {
      vertical-align: baseline;
    }
  }
}

.btn-light-green {
  background: palette(experian, "light-green");
}

.btn-red {
  background: palette(experian, "red");

  &:not(.close) {
    span {
      vertical-align: baseline;
    }
  }
}

.btn-light-blue {
  background: palette(experian, "light-blue");
}

.btn-sapphire {
  background: palette(experian, "sapphire");
}

.btn-dark-gray {
  background: palette("grey", "dark");

  &.color-white:hover {
    color: white;
  }
}

.font-roboto {
  font-family: $font-family-sans-serif;
}

.font-roboto-thin {
  font-weight: $global-weight-thin;

  & * {
    font-weight: inherit;
  }
}

.font-roboto-regular {
  font-weight: $global-weight-normal;
}

.font-roboto-medium {
  font-weight: $global-weight-medium;
}

.font-roboto-light {
  font-weight: $global-weight-light;
}

.font-roboto-bold {
  font-weight: $global-weight-bold;
}

.clickable {
  cursor: pointer;
}

.rounded-circle {
  border-radius: 50% !important;
}

.border-dark-grey {
  border-color: palette("grey", "dark");
}

.border-medium-grey {
  border-color: palette("grey", "medium");
}

.border-light-grey {
  border-color: $color-custom-grey;
}

.border-dark-blue {
  border-color: palette("experian", "dark-blue") !important;
}

.card-border {
  border-color: $color-light-gray !important;
}

/*
* Gutter Percentual
*/
.ppx {
  &-4 {
    padding-left: 4%;
    padding-right: 4%;
  }

  &-8 {
    padding-left: 8%;
    padding-right: 8%;
  }

  &-md {
    @media (min-width: 768px) {
      &-4 {
        padding-left: 4%;
        padding-right: 4%;
      }

      &-8 {
        padding-left: 8%;
        padding-right: 8%;
      }
    }
  }
}

/*
* border
*/
.brd-yellow {
  border-color: palette(experian, "yellow");
}

.btn {
  &:hover {
    opacity: 0.85;
  }
}

.f-1 {
  flex: 1;
}

.f-3 {
  flex: 3;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.btn-link-step {
  padding-right: 20px;
  padding-left: 20px;

  &:hover {
    text-decoration: none;
  }
}

.oferta-quantidade {
  line-height: 140px;
}

@media (max-width: 991px) {
  .modal-feedback {
    .modal-header {
      padding-top: 20px;
      border-bottom: 0;
      margin-bottom: 1rem;
    }

    .img-feedback {
      width: 150px;
      top: -29px;
      left: -29px;
    }

    .font-65 {
      font-size: 50px;
    }

    .font-50 {
      font-size: 35px;
    }

    .font-27 {
      font-size: 24px;
    }

    .font-23 {
      font-size: 16px;
    }

    .line-height-30 {
      line-height: 24px;
    }
  }
}

@media (max-width: 767px) {
  .modal-feedback {
    padding-left: 15px;
    padding-right: 15px;

    .modal-header {
      margin-bottom: 0;
    }

    .close-modal {
      right: 24px;
    }

    .img-feedback {
      width: 80px;
      top: 10px;
      left: 24px;
    }

    .font-65 {
      font-size: 50px;
    }
  }
}

@media (max-width: 575px) {
  .modal-feedback {
    .modal-header {
      padding-top: 30px;

      .img-feedback {
        width: 57px;
        top: -7px;
        left: 3px;
      }
    }
  }
}

.cursor-point {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

// DETALHES LIÇõES
.lesson-detail {
  padding: 30px 0px;

  &-welcome {
    h3 {
      color: palette(experian, "purple");
    }
  }

  .master-rating {
    .rating-box {
      h4 {
        display: inline-block;
        margin-top: 13px;
      }

      &:nth-of-type(1) {
        float: left;
      }

      &:nth-of-type(2) {
        float: right;
      }

      .btn {
        margin-top: 0px;
      }

      .master-select-rating {
        display: inline-block;
        margin: 0px 10px;

        a {
          margin: 0 2px;
        }
      }

      i {
        font-size: 25px;
        color: palette("grey", medium);

        &:hover {
          color: palette(experian, "yellow");
        }
      }
    }
  }

  .lesson-box-video {
    width: 100%;

    iframe,
    embed {
      width: 100%;
    }
  }

  h3.title-detail {
    border-bottom: 2px solid palette("grey", dark);
    padding-bottom: 10px;
    float: left;
    font-size: 26px;
  }

  .title-description {
    width: 100%;
    margin: 20px 0px;
    display: inline-block;

    .parceiro {
      float: right;

      h4 {
        display: inline-block;
      }

      img {
        display: inline-block;
        margin-left: 26px;
      }
    }
  }

  .description-wrapper {
    h3:nth-of-type(1) {
      font-size: 26px;
    }

    .sub-title {
      font-weight: $global-weight-light;
      margin: 20px 0px;

      &-serasa-descomplica {
        color: palette(experian, "sapphire");
      }

      &-estrategia {
        color: palette(experian, "purple");
      }

      &-organizacao {
        color: palette(experian, "dark");
      }
    }

    p {
      strong {
        font-weight: $global-weight-medium;
      }
    }
  }
}

// BOX DOS VÍDEOS DA VIDEOTECA
.video-box {
  margin-bottom: 120px;

  .master-thumb {
    background: #000;
    position: relative; // height: 143px;

    img {
      opacity: 0.5;
      width: 100%;
    }

    .play-ico-o {
      width: 44px;
      height: 44px;
      position: absolute;
      z-index: 50;
      color: white;
      font-size: 50px;
      left: 50%;
      top: 50%;
      margin: -22px 0 0 -22px;
      background: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/ic-play-o.png");
    }
  }

  h4.title {
    height: 21px;
  }

  .master-detail-box-video {
    width: 100%;
    height: 92px;
    display: table-cell;
    vertical-align: bottom;

    h4 {
      font-family: Roboto, sans-serif;
      font-size: 18px;
    }

    .detail-rating {
      width: 100%;

      .startemp-row {
        margin: 0 5px;
      }

      * {
        display: inline-block;
      }

      p {
        font-size: 13px;
      }
    }

    .master-description-box {
      width: 100%;

      h6 {
        color: palette(experian, "dark-blue");
        font-size: 13px;
        font-weight: $global-weight-medium;
      }

      p {
        font-size: 13px;
      }
    }
  }

  h4 {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    display: inline-block;
    overflow: hidden;

    &.organizacao {
      color: #27478d;
    }

    &.comportamental {
      color: #4c6fae;
    }

    &.serasa-descomplica {
      color: #406eb3;
    }

    &.estrategia {
      color: #643778;
    }

    &.atitudinal {
      color: #bc3a42;
    }
  }

  .master-access {
    width: 100%;

    * {
      display: inline-block;
    }

    .btn-assistir {
      background-color: #26478e;
      height: 35px;
      line-height: 33px;
      padding: 0 30px;
      font-family: Roboto, sans-serif;
      font-weight: 100;
      color: #ffffff;
    }
  }

  .parceiro {
    font-size: 12px;
    color: #868686;

    img {
      width: 60px;
      height: 30px;
    }
  }
}

//estrelas de rating
.bs-rating-star {
  font-size: 22px;
}

.partner {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 12px;
  float: right;
}

// PAGINATION
.master-pagination {
  width: 100%;
  display: inline-block;
  text-align: center;

  * {
    display: inline-block;
  }

  .page-link {
    background: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-left: 7px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .page-item {
    width: 45px;
    height: 35px;
    border: 1px solid palette(experian, "dark-blue");
    border-radius: 5px;
    color: palette(experian, "dark-blue") !important;
    cursor: pointer;
    text-align: center;
    line-height: 35px;
    background: none !important;

    .page-link {
      border: 0 !important;
      color: palette(experian, "dark-blue") !important;

      &:hover {
        color: #ffffff !important;
      }
    }

    &:hover {
      background: palette(experian, "dark-blue") !important;
      border: 0 !important;

      .page-link {
        color: #ffffff !important;
      }
    }

    &.disabled {
      background: #ffffff !important;
      border: 1px solid palette(experian, "dark-blue") !important;

      .page-link {
        background: none !important;
        border: 0 !important;
        color: palette(experian, "dark-blue") !important;

        &:hover {
          border: 1px solid palette(experian, "dark-blue") !important;
        }
      }
    }

    &.active {
      background-color: palette(experian, "dark-blue") !important;

      .page-link {
        background: none !important;
        color: #ffffff !important;
        border: 0 !important;
      }
    }
  }
}

// STAR
.fa-star {
  margin: 0 2px;
}

.rating-star-unrated {
  color: #b0b0af;
}

.rating-star-rated {
  color: palette(experian, "yellow");
}

.container-auto-servico {
  background-color: #f7f7f7;
  padding: 46px 0 0;

  .subtitle {
    font-size: 15px;
  }
}

.my-modal {
  .modal-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 42.32px 3.68px rgba(0, 0, 0, 0.5);
    margin: 5% auto;
    padding: 50px;
    position: relative;
    width: 60%;
  }

  .close {
    background: none;
    border: none;
    color: palette(experian, "purple");
    position: absolute;
    top: 25px;
    right: 25px;
  }
}

.modal-apelido {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1.75rem 0;
  display: flex;
  align-items: center;

  .modal-content {
    background-color: transparent;
  }
}

.strength-parent {
  height: 6px;
  background-color: rgb(167, 167, 167);
  border-radius: 15px;
}

// .password-strength {
//   margin-bottom: 15px;
//   margin-top: 15px;
// }
.strength-bar {
  height: 100%;
}

.strength-text {
  margin-top: -3px;
  margin-bottom: 7px;
}

.icon-zarolho {
  top: 30px !important;
}

.view-register {
  position: relative;
  z-index: 1;
}

.register-show .register-hide .over-register {
  z-index: 1001;
  opacity: 1;
}

.view-register-default .register-float {
  .over-register {
    right: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .view-register-default {
    .over-register {
      right: -475px !important;
    }
  }

  .register-float {
    .over-register {
      right: 0 !important;
      top: 80px !important;
    }
  }
}

.show-menu-d .register-hide .over-register {
  z-index: 1001;
  opacity: 1;
}

.z-index-10 .register-hide .over-register {
  z-index: 1001 !important;
  opacity: 1;
}

@media screen and (min-width: 992px) {
  .btn-open-form-mobile {
    display: none !important;
  }

  .tracejado-bottom-left {
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      height: 84px;
      width: 100%;
      background-image: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/direcional-arrow-left.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 34rem;
      z-index: 11;
    }
  }

  .tracejado-top-left {
    position: relative;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      height: 84px;
      width: 100%;
      background-image: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/direcional-line-left.png");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 34rem;
      z-index: 11;
    }
  }

  .tracejado-top-bottom-left {
    @extend .tracejado-bottom-left;
    @extend .tracejado-top-left;
  }

  .is-new-persona {
    .card-perfil {
      &:after {
        transform: rotateY(180deg);
      }
    }

    .card-educa {
      &:before {
        transform: rotateY(180deg);
      }
    }
  }

  .no-persona {
    .master-questionario-area,
    .card-oferta {
      &:before {
        transform: rotateY(180deg);
      }
    }

    .card-educa {
      &:after {
        transform: rotateY(180deg);
      }
    }

    .card-acesso-credito {
      &:before {
        transform: rotateY(180deg);
      }
    }
  }
}

@media (max-width: 991px) {
  .open-menu {
    .openBoxLogin {
      display: block !important;
    }
  }
}

@media (max-width: 767px) {
  .cards-home.card-carousel {
    height: auto;

    .text-area {
      .inner {
        position: relative;
        bottom: unset;
      }
    }
  }

  header:not(.header-modal, .modal-header, .v2-main-header) {
    height: 60px !important;

    .fixed-bar {
      height: 60px !important;

      .logo {
        line-height: 60px !important;
      }
    }
  }

  .container-mobile {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    min-width: 100%;
  }

  .over-register {
    top: 60px;
  }

  .register-show .over-register {
    position: fixed !important;
    height: calc(100vh - 60px);
    width: 100%;
  }

  .openBoxLogin {
    transform: translateY(100%);
  }

  .open-menu {
    .openBoxLogin {
      transform: translateY(0%);
      position: absolute;
      right: 0;
      top: 60px !important;
      width: 100%;
      z-index: 200;
      background-color: #fff;
      border-top: 1px solid #dedede;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in;

      .login-area {
        padding-bottom: 15px;
        padding-top: 15px;

        .form-group {
          margin-bottom: 1.5rem !important;
        }

        p {
          text-align: center;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }

    .btn-user {
      .fa-user-circle-o:before {
        content: "\f00d";
      }
    }
  }

  .open-form-cadastro {
    position: fixed;

    .register-show {
      .over-register {
        top: 0px !important;
        height: 100%;
        z-index: 1031;
      }
    }

    .new-form {
      .form-group {
        margin-bottom: 1.2rem;

        .label-form {
          margin-bottom: 0 !important;
        }
      }
    }

    .over-register {
      .text {
        padding-bottom: 5px;
        padding-top: 0;

        .title {
          font-size: 26px !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .show-menu-d .btn-float-register {
      display: none;
    }

    .show {
      display: block;
    }

    header {
      display: none;
    }

    .box-form {
      form {
        max-height: 420px;
        overflow: auto;
      }
    }
  }

  .menu-logo {
    padding-right: 12px;

    .user-infos {
      display: none !important;
    }
  }

  header {
    .menu-area {
      min-width: 50px;

      &.menu-view {
        background-color: palette(experian, "color-light-gray") !important;

        .btn-menu {
          border-color: #fff !important;

          span {
            background-color: #fff;

            &:after,
            &:before {
              background-color: #fff;
            }
          }
        }

        .nav-bar {
          right: 0 !important;
          transform: translateX(0%);
        }
      }

      .nav-bar {
        top: 60px !important;
        min-width: 100vw !important;
        transform: translateX(100%);
        right: -999px !important;
      }

      nav {
        p,
        a {
          background-position: 45px 50% !important;
          min-height: 45px;
          display: flex !important;
          align-items: center;
          background-color: #f4f5f8;
          border-top: 1px solid #c8c8c8;
        }

        p {
          background-color: #fff;
          min-height: 60px;
        }
      }
    }
  }

  .open-login {
    position: fixed;
  }
}

.swiper-persona .swiper.s-wrapper {
  height: auto;
  text-align: center !important;

  .swiper-button-next,
  .swiper-button-prev {
    filter: hue-rotate(-320deg) saturate(0.5);
  }
}

app-score-gauge {
  width: 350px;
  height: 350px;
  position: relative;
  display: block;
}

// cards home
.card-score-empreendedor {
  padding-top: 30px;

  .box {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .description {
    font-size: 17px;
  }

  img {
    margin-top: 0;
    margin-bottom: 8px;
  }

  p.description-tip {
    font-size: 16px;
    line-height: 1.2;
    margin: 20px 0 10px 0;
    padding: 0 30px 0 30px;
  }

  .mobile-perfil-imgs {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.bgc-grey-light {
  background-color: #fff;
}

// Section Serasa Educa
.mobile-serasa-educa.bgc-white-medium {
  &:after {
    border-top-color: transparent;
  }

  .btn {
    font-weight: 600;
  }

  h2 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    font-size: 46px;
    line-height: 1em;
    color: #2c4989;
  }

  .lista {
    font-family: $font-family-sans-serif;

    p {
      color: #333;
      font-size: 15px;
      line-height: 1.3em;
      font-family: $font-family-sans-serif;
    }

    .fa {
      margin-right: 6px;
      color: palette(experian, "purple");
    }
  }

  .novidade {
    background: palette(experian, "purple");
    color: #fff;
    padding: 5px;
    margin-bottom: 8px;
    width: 123px;
    position: relative;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      content: "";
      bottom: 0;
      top: 0;
      right: -17px;
      position: absolute;
      display: inline-block;
      border-style: solid;
      border-width: 16px;
      border-color: #982881 #fff #982881 #982881;
      overflow: hidden;
    }
  }
}

.count-control {
  font-size: 14px;

  & > button {
    @include size(22, true);
    border-radius: 50%;
    border: 1px solid palette("experian", "purple");
    font-size: 14px;
    background-color: #fff;

    &[disabled] {
      opacity: .2;
      &:hover {
        opacity: .2;
      }
    }
  }

  & > input[type="text"] {
    border: 1px solid palette("experian", "purple");
    height: 22px;
    color: palette("experian", "purple");
  }
}

// Begin Media Queries
// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
  // Home out
  // Section Perfil
  .mobile-perfil {
    margin-top: 20px;
    margin-bottom: 20px;

    .title {
      width: 100%;
    }

    strong {
      font-size: 16px;
      font-weight: 700;
    }

    .description {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      text-align: left;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 8px !important;
    }

    figure {
      margin: 16px auto 0 auto;
    }

    img {
      margin-top: 0;
      margin-bottom: 8px;
    }

    p.description-tip {
      font-size: 16px;
      line-height: 1.2;
      margin: 20px 0 10px 0;
      padding: 0 30px 0 30px;
    }

    .mobile-perfil-imgs {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .bgc-grey-light {
    background-color: #fff;
  }

  // Section Serasa Educa
  .mobile-serasa-educa.bgc-white-medium {
    &:after {
      border-top-color: transparent;
    }

    background-color: #fff;
  }

  .mobile-serasa-educa {
    .title {
      text-align: left;
    }

    .description {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 1.2;
    }

    img {
      margin-bottom: 30px !important;
    }

    p {
      margin-bottom: 0;

      a {
        width: 100%;
      }
    }
  }

  // Section Credito
  .mobile-credito {
    .title {
      text-align: left;
      font-size: 32px;
    }

    .description {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 25px;
    }

    p {
      text-align: left;
      margin-bottom: 0;
    }

    .embed-responsive {
      margin-top: 40px;
    }

    i {
      color: #fcd731;
      -webkit-text-stroke: 4px #982881;
      padding-top: 4px;
      margin-right: 8px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  // Home in
  // Section perfil
  section.mobile-card-perfil {
    padding: 0 !important;
  }

  .quiz-link {
    width: 100%;
    font-size: 20px;
    color: #fff;

    .position-text {
      text-align: center;
    }

    small {
      font-size: 12px;
    }

    .row {
      margin: 0;
      padding: 12px 0 12px 0;
      background-color: #622a76;
      align-items: center;
    }

    i.fa.fa-angle-right {
      color: #f8d949;
    }
  }

  // Section Educa
  .mobile-card-educa {
    background-color: #fff;

    &:before {
      display: none !important;
    }

    .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }

    h1 {
      font-size: 1.8rem !important;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px !important;
      text-align: center !important;
      line-height: 1.2 !important;
    }
  }
}

// Section Credita
.mobile-card-credita {
  background: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/credita/bg-credita-mulher.png")
    no-repeat;
  background-size: cover;
  height: 100vh;

  .bg-black {
    background: linear-gradient(to top, black, transparent);
    width: 100%;
    min-height: 44vh;
    position: absolute;
    padding-left: 42px;
    padding-right: 42px;
    z-index: 1;

    &::before {
      content: "";
      background: linear-gradient(to top, black, transparent);
      width: 100%;
      height: 55vh;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  h3 {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p {
    line-height: 1;
    margin-bottom: 40px;
  }

  a {
    display: block;
    width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 320px) {
  .cards-home {
    .title {
      margin-bottom: 10px;
    }

    .description {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }

    p.description-tip {
      padding: 10px 20px 0 20px;
    }
  }
}

.overflow {
  &-visible {
    overflow-y: visible;
  }

  &-hidden {
    overflow: hidden;
  }

  &-y {
    &-visible {
      overflow-y: visible;
    }

    &-hidden {
      overflow: hidden;
    }
  }

  &-x {
    &-visible {
      overflow-y: visible;
    }

    &-hidden {
      overflow: hidden;
    }
  }
}

.modal-calc {
  font-family: $font-family-sans-serif;

  .close {
    &:hover {
      color: palette("white");
    }
  }

  .modal-content {
    background: transparent;

    .modal-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .modal-body {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      & > .row {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .form-control {
          color: palette("experian", "violet");
        }

        ::-webkit-input-placeholder {
          color: palette("experian", "violet");
        }

        ::-moz-placeholder {
          color: palette("experian", "violet");
        }

        :-ms-input-placeholder {
          color: palette("experian", "violet");
        }

        :-moz-placeholder {
          color: palette("experian", "violet");
        }
      }
    }

    @media (max-width: 480px) {
      .btn-light-blue {
        white-space: normal;
        line-height: 1.4;
        padding: 15px;
      }
    }
  }
}

.tooltip-desc-parceito {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    max-width: 300px;
    background-color: #ededed;
    border: 1px solid #bbbbbb;
    color: #575756;
  }

  &[x-placement^="top"] .arrow::before,
  & .arrow::before {
    border-top-color: #bbbbbb;
  }
}

.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    max-width: 300px;
    font-size: 0.75rem;
    text-align: left;
  }
}

.tooltip-dange {
  .tooltip-inner {
    background-color: #fff;
    border: 1px solid #bb0047;
    color: #bb0047;
  }

  &[x-placement^="top"] .arrow::before,
  & .arrow::before {
    border-top-color: #bb0047;
  }
}

.message-q4 {
  .tooltip-inner {
    background-color: $color-custom-grey-tooltip-2;
    padding: 15px;
    position: relative;
    max-width: 280px;

    p {
      padding-right: 30px;

      &::before {
        content: "";
        background: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/acesso-acredito/ico-lamp.png")
          no-repeat center;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }

  &[x-placement^="top"] .arrow::before,
  .arrow::before {
    border-top-color: $color-custom-grey-tooltip-2;
  }
}

.modal-form-cadastro {
  .modal-body {
    border-radius: 6px;
    border: 1px solid #ab3d7b !important;
  }

  .over-register {
    width: 100% !important;
    max-width: initial !important;
    border-left: 0 !important;
    border-radius: 6px;
    padding: 1.5rem 1rem;
    height: auto !important;
  }
}

.modal-cancelar {
  .modal-content {
    border-radius: 1rem;
  }

  .modal-header {
    background-color: #bb0047;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.modal-validar {
  .modal-content {
    border-radius: 1rem;
  }

  .modal-header {
    background-color: palette(experian, "violet");
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .btn-trans {
    background: none;
    border-width: 1px;
    border-style: solid;
  }

  .brd-color-gray {
    border-color: #bbbbbb;
  }
}

.fx {
  height: 94px;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  &-2 {
    top: 96px;
  }

  &.fh {
    height: 100%;
  }

  &-right {
    left: auto;
    right: 0;
  }

  &-full-width {
    width: 100%;

    &-lg {
      @media (max-width: $screen-md-max) {
        width: 100%;
      }
    }

    &-md {
      @media (max-width: $screen-sm-max) {
        width: 100%;
      }
    }

    &-sm {
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
  }
}

.collapse.ease, .collapse-ease {
  max-height: 0;
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:not(tr) {
    display: block;
  }

  &.show {
    max-height: 1000px;

    .bt-excluir {
      display: inline-block;
    }
  }
}

aside {
  .product-aside {
    min-height: 94px;

    @media (max-width: 991px) {
      min-height: 40px;
    }
  }
}

.modal-pdf {
  @media (min-width: 768px) {
    max-width: 80%;
  }

  .modal-body {
    height: 80vh;
  }
}

@media (max-width: 991px) {
  .full-width-md {
    .container,
    &.container {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .w-md-100 {
    width: 100%;
  }
}

.range {
  b {
    font-weight: 500;
  }

  &-1 {
    b {
      color: #ab3d7b;
    }
  }

  &-2 {
    b {
      color: #7a377b;
    }
  }
  &-3 {
    b {
      color: #5b2c74;
    }
  }

  &-4 {
    b {
      color: #26478d;
    }
  }

  .sub-text {
    b {
      font-weight: 400;
    }
  }
}

// Border Ouline

.border-outline {
  border-radius: 6px;

  &-red {
    border-radius: 6px;
    border: 1px solid palette(experian, "red");
  }
}

.assistant-description-link  {
  outline: none !important;
  text-decoration: underline !important;
  color: #1d4f91 !important;
}
