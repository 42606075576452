@media (max-width: $screen-xs-max) {
  .text-xs-left {
    text-align: left;
  }
  .text-xs-right {
    text-align: right;
  }
  .text-xs-center {
    text-align: center;
  }
  .text-xs-justify {
    text-align: justify;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (min-width: $screen-lg-min) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
}

// Margin and Padding
$num-of-classes: 100;
$directions: (
  "top",
  "bottom",
  "left",
  "right"
);
$types: (
  "margin",
  "padding"
);
$queries: (
  $screen-xs: "xs",
  $screen-sm: "sm",
  $screen-md: "md",
  $screen-lg: "lg"
);
@mixin generate-margins {
  @each $type in $types {
    @each $direction in $directions {
      @for $i from 0 through ($num-of-classes) {
        .#{$type}-#{$direction}-#{$i} {
          #{$type}-#{$direction}: (#{$i}px !important);
        }
      }
    }
    @each $query, $z in $queries {
      @media (min-width: #{$query}) {
        @each $direction in $directions {
          @for $i from 0 through ($num-of-classes) {
            .#{$type}-#{$direction}-#{$z}-#{$i} {
              #{$type}-#{$direction}: (#{$i}px !important);
            }
          }
        }
      }
    }
  }
}
@include generate-margins();
