// Colors
// --------------

$palettes: (
  experian:
    (
      "dark-blue": #26478d,
      "light-blue": #406eb3,
      "violet": #632678,
      "purple": #982881,
      "pink": #ba2f7d,
      "red": #bb0048,
      "orange": #e2a235,
      "yellow": #fcd731,
      "green": #c8c922,
      "light-green": #1d9d74,
      "sapphire": #3d87af,
      "light-pink": #faf4f9,
    ),
  "grey": (base: #333333, medium: #dddddd, light: #fafafa, dark: #575755),
  "white": (base: #ffffff, medium: #f0f0f0, light: #f4f4f4),
  "black": (base: #000000),
  "violet": (light: #e3d7e7, light-100: #AF90BA),
  "green": (base: #349504),
  pilar:
    (
      "serasa-descomplica": #3d87af,
      "estrategia": #632678,
      "organizacao": #26478d,
      "empreendedorismo": #e2a235
    )
);


$color-white: #fff;
$color-rating: #fcd731;
$color-light-gray: #ebebeb;
$color-prim: #ead4e6;
$color-affair: #825193;
$color-pale-slate: #b9b8b9;
$color-alabaster: #f8f8f8;
$color-dusty-gray: #9b9b9b;
$color-tundora: #4a4a4a;
$color-mercury: #e9e9e9;
$color-seanse: #72227b;
$color-violet: #632678;
$color-buttercop: #f5a623;
$color-west-side: #FF8F1C;
$color-green-haze: #009f4d;
$color-alto: #d9d9d9;
$color-san-marino: #406eb3;
$color-royal-heath: #982881;
$color-prim: #faf4f9;
$color-custom-grey: #bcbcbb;
$color-custom-grey-light: #f9f9fa;
$color-custom-grey-images: #e6e6e6;
$color-custom-grey-tooltip-1: #797977;
$color-custom-grey-tooltip-2: #ad5399;
$color-purple: #632678;
$color-purple-lightest: #b193bc;
$color-purple-light: #803e97;
$color-purple-dark: #521866;
$color-zumthor: #ebf3ff;
$color-chicago: #575756;
$color-black-squeeze: #ebf0f7;
$color-cerise: #e63888;
$color-honey-flower: #6d2077;
$color-matisse: #1d4f91;
$color-friar-gray: #797978;
$color-tapestry: #ad539a;
$color-shuttle-gray: #63666a;
$color-danube: #598cc9;
$color-orchid: #e267c0;
$color-malibu: #7ebaf5;
$color-iron: #d9dadb;
$color-emerald: #52cc6c;
$color-oslo-gray: #8c8d8f;
$color-aqua-haze: #f7f9fa;
$color-wisteria: #9754ae;
$color-cornflower-blue: #26478d;
$color-monza: #e4002b;
$color-red-violet: #af1685;
$color-polo-blue: #8ea7cb;

/*** THEMES ***/
$theme-health: $color-honey-flower;
$theme-credit: $color-cornflower-blue;
$theme-consult: $color-matisse;

//SEDS TOKEN Brand Primary
$seds-experian-color-primary: #2A5595;
$seds-experian-color-primary-light:	#687DB0;
$seds-experian-color-primary-dark:	#1E437A;

//SEDS TOKEN Brand Primary Alternative
$seds-experian-color-primary-alt:	#C6CBE1;
$seds-experian-color-primary-alt-light:	#F7F8FB;
$seds-experian-color-primary-alt-dark:	#A6AFD0;

//SEDS TOKEN Brand Secondary
$seds-experian-color-secondary:	#E80070;
$seds-experian-color-secondary-light:	#FEFB90;
$seds-experian-color-secondary-dark:	#A31B50;

//SEDS TOKEN Brand Secondary Alternative
$seds-experian-color-secondary-alt:	#FFBACB;
$seds-experian-color-secondary-alt-light:	#FFF6F8;
$seds-experian-color-secondary-alt-dark:	#FE5B90;

//SEDS TOKEN Brand Tertiary
$seds-experian-color-tertiary:	#77127B;
$seds-experian-color-tertiary-light:	#A768A6;
$seds-experian-color-tertiary-dark:	#4A154B;

//SEDS TOKEN Brand Tertiary Alternative
$seds-experian-color-tertiary-alt:	#DEC4DD;
$seds-experian-color-tertiary-alt-light:	#FBF7FA;
$seds-experian-color-tertiary-alt-dark:	#B985B7;

//SEDS TOKEN Cores neutras
$seds-color-neutral-white:	#FFFFFF;
$seds-color-neutral-grey:	#E2E4E7;
$seds-color-neutral-grey-light:	#F8F8F9;
$seds-color-neutral-grey-dark:	#C8CCD1;
$seds-color-neutral-black:	#384553;
$seds-color-neutral-black-light:	#5E6976;
$seds-color-neutral-black-dark:	#252C34;

//SEDS TOKEN feedback
$seds-color-feedback-success:	#1B7749;
$seds-color-feedback-success-light:	#0FAC67;
$seds-color-feedback-success-dark:	#1A4D31;
$seds-color-feedback-success-alt:	#C7EDD4;
$seds-color-feedback-success-alt-light:	#F1FBF4;
$seds-color-feedback-success-alt-dark:	#92DCAE;
$seds-color-feedback-warning:	#E57B08;
$seds-color-feedback-warning-light:	#FF9735;
$seds-color-feedback-warning-dark:	#9E5612;
$seds-color-feedback-warning-alt:	#FFE0C2;
$seds-color-feedback-warning-alt-light:	#FFF7F0;
$seds-color-feedback-warning-alt-dark:	#FFBF85;
$seds-color-feedback-error:	#D0141A;
$seds-color-feedback-error-light:	#FA1320;
$seds-color-feedback-error-dark:	#851B15;
$seds-color-feedback-error-alt:	#FFDCD3;
$seds-color-feedback-error-alt-light:	#FFF6F3;
$seds-color-feedback-error-alt-dark:	#FFBDAD;

//SEDS TOKEN support colors
$seds-color-data-teal:	#188C84;
$seds-color-data-teal-light:	#54C2B8;
$seds-color-data-teal-dark:	#1C5F59;
$seds-color-data-teal-alt:	#CAEBE7;
$seds-color-data-teal-alt-light:	#F2FAF9;
$seds-color-data-teal-alt-dark:	#98D8D1;
$seds-color-data-sapphire:	#0C7092;
$seds-color-data-sapphire-light:	#3097AA;
$seds-color-data-sapphire-dark:	#165B76;
$seds-color-data-sapphire-alt:	#DAE6EE;
$seds-color-data-sapphire-alt-light:	#F6F9FB;
$seds-color-data-sapphire-alt-dark:	#8EB6CC;
$seds-color-data-yellow:	#E57B08;
$seds-color-data-yellow-light:	#FF9A00;
$seds-color-data-yellow-dark:	#9E5612;
$seds-color-data-yellow-alt:	#F8EFC4;
$seds-color-data-yellow-alt-light:	#FFFCF0;
$seds-color-data-yellow-alt-dark:	#FFC400;
$seds-color-data-yellow-alt:	#F8EFC4;
$seds-color-data-yellow-alt-light:	#FFFCF0;
$seds-color-data-yellow-alt-dark:	#FFC400;
$seds-color-data-lime:	#7D8314;
$seds-color-data-lime-light:	#AFB904;
$seds-color-data-lime-dark:	#555915;
$seds-color-data-lime-alt:	#E9E7AF;
$seds-color-data-lime-alt-light:	#FAF9EB;
$seds-color-data-lime-alt-dark:	#CFD265;
