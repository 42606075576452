.serasa-svg {
  display: inline-block;
  width: 50px;
  height: 50px;

  &.i-mini {
    @include size(25);
  }
  &.i-medium {
    @include size(40);
  }
  &.i-large {
    @include size(60);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:not(.custom-fill) {
    svg {
      fill: currentColor;


      path,
      text,
      circle,
      g {
        fill: currentColor;
      }
    }
  }

  &.use-opacity {
    &-30 {
      #icon-opacity {
        opacity: .3;
      }
    }
    &-40 {
      #icon-opacity {
        opacity: .4;
      }
    }
    &-50 {
      #icon-opacity {
        opacity: .5;
      }
    }
    &-60 {
      #icon-opacity {
        opacity: .6;
      }
    }
    &-70 {
      #icon-opacity {
        opacity: .7;
      }
    }
    &-80 {
      #icon-opacity {
        opacity: .8;
      }
    }
  }
}
