@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "~bootstrap/dist/css/bootstrap.css";
@import '~swiper/swiper-bundle.min.css';

@import 'styles/mixins/functions';
@import 'styles/mixins/border-radius';
@import 'styles/mixins/box-shadow';
@import 'styles/mixins/filter';
@import 'styles/mixins/opacity';
@import 'styles/mixins/transform';
@import 'styles/mixins/transition';
@import 'styles/mixins/mixins';
@import 'styles/variables';

@import 'font-awesome/scss/font-awesome';

@import 'styles/custom-utilities';
@import 'styles/global';
@import 'styles/btn';
@import 'styles/icon';
@import 'styles/elements';
@import 'styles/form';
@import 'styles/overlay';

/****** HELPERS *******/
@import 'styles/helpers/background';
@import 'styles/helpers/line-height';
@import 'styles/helpers/font';
@import 'styles/helpers/flexbox';
@import 'styles/helpers/text';
@import 'styles/helpers/color';
@import 'styles/helpers/shadow';

/****** COMPONENTS *******/
@import 'styles/components/svg';
@import 'styles/components/loader';
@import 'styles/components/alerts';
@import 'styles/components/breadcrumb';
@import 'styles/components/form';
@import 'styles/components/tooltip';
@import 'styles/components/header';
@import 'styles/components/header-entitled';
@import 'styles/components/modal';
@import 'styles/components/video';
@import 'styles/components/stepper';
@import 'styles/components/switch';
@import 'styles/components/bs-datepicker';
@import 'styles/components/range-slider';
@import 'styles/components/tree-view';
// @import 'angular-tree-component/dist/angular-tree-component.css';
@import 'styles/components/tree';
@import 'styles/components/toastr';

/****** PAGES *******/
@import 'styles/pages/home';
@import 'styles/pages/partners';
@import 'styles/pages/financial-health';
@import 'styles/pages/financial-health/financial-health';
@import 'styles/pages/debt-recovery/debt-recovery';

/****** V2 *******/
@import './app/v2/shared/scss/global';

