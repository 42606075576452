.header {
  &.container {
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &-strip {
    width: 100%;
    border: none;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    min-height: 85px;

    @media (max-width: 767px) {
      min-height: 55px;
    }

    &.strip {
      &-dark-blue {
        background-color: palette('experian', 'dark-blue');
        color: $color-white;
      }
    }
  }
}

.main-header {
  .container-mobile {
    position: relative;
  }

  .help-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  .burguer-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  ul.nav-links {
    color: palette('grey', 'dark');
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;


    li {
      float: left;

      a {
        display: block;
        // color: white;
        text-align: center;
        padding: 16px 10px;
        text-decoration: none;
        height: 100%;
        line-height:16px;

        @media (max-width: $screen-xs-max) {
          padding: 6px 16px;
        }

        &.one-line {
          padding-top: 32px;
          i {
            vertical-align: middle;
          }
          span {
            padding-top: 0;
          }
        }

        &.only-icon {
          padding: 6px 24px;
          border-color: palette('grey', 'dark');
        }

        span {
          text-align: left;
          display: inline-block;
          padding: 0 0 0 8px;
        }

        i {
          vertical-align: super;
        }
      }
    }

    .btn-home {
      padding-top: 0 !important;
      min-width: 0;
    }

  }

  .home-menu, .logged-menu {
    ul.nav-links {
      overflow: visible;
      transition-property: transform;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      font-size: 16px;

      @media (max-width: 991px) {
        position: absolute;
        top:100%;
        left: 0;
        z-index: 1;
        background-color: rgba(0,0,0, .7);
        height: calc(100vh - 60px);
        overflow-y: auto;
        width: 100vw;
        transform: translateX(100%);

        -webkit-box-shadow: inset 0px 4px 8px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: inset 0px 4px 8px 0px rgba(0,0,0,0.2);
        box-shadow: inset 0px 4px 8px 0px rgba(0,0,0,0.2);

        &.menu-visible {
          transform: translateX(0);
        }

      }

      li {
        position: relative;

        @media (max-width: 991px) {
          width: 100%;
          background-color: palette('white', 'base');
          border-bottom: 1px solid $color-light-gray;

          &:first-of-type {
            -webkit-box-shadow: inset 0px 2px 3px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: inset 0px 2px 3px 0px rgba(0,0,0,0.2);
            box-shadow: inset 0px 2px 3px 0px rgba(0,0,0,0.2);
          }

        }

        .opener {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      a {
        padding: 16px 22px;
        color: palette('experian', 'violet');
        font-weight: 500;
        line-height: normal;


        &:hover, &.active {
          opacity: .85;
        }

        span {
          padding: 0;
        }
      }
    }

    .sub-menu {
      position: absolute;
      left: 0;
      top: 100%;
      background-color: palette('white', 'base');
      margin: 0 6px;
      overflow: hidden;
      box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.2);

      -webkit-border-bottom-right-radius: 3px;
      -webkit-border-bottom-left-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -moz-border-radius-bottomleft: 3px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      @media (max-width: 991px) {
        margin: 0;
        border: none;
        border-radius: 0;
      }

      .menu-strip {
        border-color: palette('experian', 'violet');
        border-style: solid;
        border-width: 3px 0 0;
      }


      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 100%;
        font-weight: 400;
        font-size: 14px;

        @media (max-width: 991px) {
          width: 100vw;
        }

        li {
          width: 100%;

          &:first-of-type {
            padding-top: 8px;
          }
          &:last-of-type {
            padding-bottom: 8px;
          }

          @media (max-width: 991px) {
            background-color: palette('grey', 'medium');

            &:first-of-type {
              box-shadow: none;
              padding-top: 0px;
            }
            &:last-of-type {
              padding-bottom: 0px;
            }
          }


          a {
            text-align: left;
            padding: 5px 15px;
            color: palette('black', 'base');

            &:hover {
              color: palette('experian', 'violet');
              opacity: .85;
            }

            @media (max-width: 991px) {
              text-align: center;
              padding:15px;
            }
          }
        }
      }
    }
  }

  .logged-menu {
    ul.nav-links {
      font-size: 14px;


      @media (min-width: 992px) {
        height: 100%;
      }

      @media (max-width: 1202px) {
        font-size: 12px;
      }

      @media (max-width: 991px) {
        font-size: 16px
      }

      li {
        height: 100%;

        &:hover:not(.no-hover) {
          .sub-menu {
            max-height: 1000px;
          }
          .fa-chevron-up {
            display: block;
          }
          .fa-chevron-down {
            display: none;
          }
        }

        &:not(.no-hover) {
          .fa-chevron-up {
            display: none;
          }
        }

        @media (max-width: 991px) {
          height: auto;
          min-height: 50px;
          border-bottom: 1px solid #e1e1e1;

          &.user {
            min-height: 60px;
          }

          &:last-of-type {
            border-bottom-width: 0;
          }
        }

        &.item {
          &-1 {
            min-width: 80px;
          }
          &-2 {
            min-width: 138px;
          }
          &-3 {
            min-width: 165px;

            .sub-menu {
              min-width: 190px;
            }
          }
          &-4 {
            min-width: 152px;
          }
          &-5 {
            min-width: 90px;
          }

          @media (max-width: 1202px) {
            &-1 {
              min-width: 54px;
            }
            &-2 {
              min-width: 105px;
            }
            &-3 {
              min-width: 145px;
            }
            &-4 {
              min-width: 118px;
            }
            &-5 {
              min-width: 88px;
            }
          }
        }
      }

      a {
        color: palette('experian', 'dark-blue');
        font-weight: 400;
        padding: 0;
        transition: unset;
        -webkit-transition: unset;

        &:hover, &.active {
          font-weight: 700;
          opacity: 1;
          color: palette(experian,'light-blue');
        }

        @media (max-width: 991px) {
          height: 50px;
        }
      }
    }

    .sub-menu {
      margin: 0;

      @media (max-width: 991px) {
        position: static;
        border: none;
      }

      .menu-strip {
        border-color: palette('experian', 'dark-blue');
        border-width: 2px 0 0;
      }

      &.sub-conheca {
        left: 2px;
        min-width: 160px;
        @media (max-width: 1202px) {
          min-width: 134px;
        }
      }
      &.sub-beneficios {
        min-width: 143px;
        @media (max-width: 1202px) {
          min-width: 128px;
        }
      }

      &.sub-hamburguer {
        left: auto;
        right: 0;
        width: 174px;
        max-width: 174px;

        a {
          display: inline-block;
          width: 100%;
          color: palette('experian', 'dark-blue');
          padding: 3px 15px;

          &:hover {
            font-weight: bold;
          }
        }

        ul {
          li {
            &:last-of-type {
              padding-bottom: 3px;
              span {
                border-top: 1px solid #e1e1e1;
                padding-top: 5px;
              }
            }
          }
        }


      }

      ul {
        @media (max-width: 1202px) {
          font-size: 12px;
        }

        @media (max-width: 991px) {
          font-size: 14px;
        }

        li {
          @media (max-width: 991px) {
            background-color: #efefef;
            min-height: 40px;
            border-bottom: 1px solid #e1e1e1;
          }
          a {
            color: palette('grey', 'dark');
            font-weight: 400;

            &:hover, &.active {
              color: palette('experian', 'dark-blue');
              font-weight: 400;
              opacity: 1;
            }

            @media (max-width: 1202px) {
              padding: 5px 7px;
            }

            @media (max-width: 991px) {
              padding: 0;
              height: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }

    .btn-menu {
      padding: 21px 10px;

      @media (max-width: 767px) {
        padding: 10px 15px;
        // border-left: 1px solid #e5e5e5;
      }
    }

    .btn-help {
      padding: 0 6px 0 20px;

      @media (max-width: 991px) {
        padding: 0 20px;
        border-right: 1px solid #e5e5e5;
      }
    }
  }

  .user {
    border: solid #e5e5e5;
    border-width: 0 1px;
    height: 100%;
    width: 200px;
    min-width: 200px;
    z-index: 1;
    padding: 20px;

    @media (max-width: 1202px) {
      width: 127px;
      min-width: 127px;
      padding: 20px 10px;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      padding: 15px;
      min-height: 60px;
    }


    span {
      display: block;

      &.user-name {
        color: palette(experian, "dark-blue");
        font-size: 14px;
      }

      &.user-email {
        font-size: 11px;
        padding-top: 3px;
      }
    }
  }

  .hamburguer {
    &:hover {
      .sub-menu {
        max-height: 1000px;
      }
    }
  }
}

.internal-header {
  .header-container {
    width: 100%;
    padding: 0 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: $screen-md-min) {
      max-width: 960px;
    }
    @media (min-width: $screen-lg-min) {
      max-width: 1140px;
    }

    .btn-back {
      &:hover {
        color: $color-white;
      }
    }
  }
}
