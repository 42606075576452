.form-validate {
  color: palette('grey', 'dark');

  p {
    color: inherit;
  }

  .floating-label {
    min-height: 44px;

    input:not(:placeholder-shown) {
      padding-top: 12px !important;

      & + label {
        font-weight: 400;
        color: palette('experian', 'dark-blue') !important;
      }
    }
  }

  input {
    &[type='text'], &[type='password'], &[type='search'], &[type=number] {
      margin-bottom: 0;
      padding-top: 0;
    }
  }

  .field-error {
    input {
      @include rgba-background(#bf0000, .3);
      border: 2px solid #bf0000;

      @media (max-width: 767px) {
        border-width: 2px;
      }
    }
    textarea {
      @include rgba-background(#bf0000, .3);
      border: 1px solid #bf0000;

      @media (max-width: 767px) {
        border-width: 2px;
      }
    }
    select {
      border-color: #ba2f7d !important;
      background-color:#ffced5 !important;
      border: 1px solid #bf0000;

    }
    label {
      color: #ba2f7d !important;
      max-height: 2em !important;
    }

    /* active state */
    input:focus ~ label,
    input:valid ~ label,
    input:disabled ~ label {
      color: #ba2f7d !important;
    }
    input::placeholder {
      color: #ba2f7d !important;
    }

    p {
      color: #bf0000;
      }

    .error-text {
      color: #bf0000;
      position: absolute;
      right: 0;
      bottom: 44px;
      font-size: 11px;
      b {
        font-weight: 700;
      }

      @media (max-width:767px) {
        bottom: 43px;
      }

      &.error-bordered {
        padding: 2px 4px;
        border-radius: 5px;
        border: 1px solid #bf0000;

      }

      &.error-bottom-right {
        right: 0;
        bottom: auto;
      }
    }

    .check-box {
      label {
        &:before {
          border-color: #bf0000;
          background: none;
          background-color: rgba(#bf0000, .3);
        }
      }
    }
  }

  &.validation-v2 {
    .floating-label {
      position: relative;
      input:not(:placeholder-shown) + label,
      input:valid:required + label,
      input:disabled + label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-size: 16px;
        font-weight: 400;
        border-radius: 8px;
        &::placeholder {
          color: palette('grey', 'base');
        }

        &:not(:placeholder-shown) {
          padding-top: 16px !important;
        }

        &:disabled, &.disabled {
          background-color: #eff0f0;
          border-color: #e0e0e1;
          color: #c1c2c3
        }
      }

      &.hide-label {
        input {
          &:not(:placeholder-shown) {
            padding-top: 0 !important;
          }
        }

        input:not(:placeholder-shown) + label,
        input:valid:required + label,
        input:disabled + label {
          display: none;
        }
      }
    }

    .custom-checkbox {
      background-image: none;
      .checkbox-icon {
        width: 22px;
        max-width: 22px;
        height: 22px;
        min-height: 22px;
        margin-right: 5px;
      }

      label {
        position: relative;

        &:after, &:before {
          content: ' ';
          background-repeat: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
          transition: all .2s;
        }

        &:before {
          background-image: url('/assets/svg/checkbox/empty-active.svg');
        }

        &:after {
          opacity: 0;
          background-image: url('/assets/svg/checkbox/filled-active.svg');
        }
      }

      input[type="checkbox"]:checked + label {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &.field-error {
        input[type="checkbox"] + label {
          color: $color-monza !important;

          &:before {
            background-image: url('/assets/svg/checkbox/empty-error.svg');
          }

          a {
            color: $color-monza !important;
          }
        }
      }
    }

    .field-error {

      &.floating-label {
        &:not(.hide-validation-icon):before {
          content: '!';
          text-align: center;
          background-color: $color-monza;
          position: absolute;
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-weight: 500;
          font-size: 16px;
          right: 8px;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
          color: #fff;
        }

        input {
          background: transparent;
          position: relative;
          border-color: #e4002b;

          &:focus ~ label,
          &:valid ~ label,
          &:disabled ~ label,
          &::placeholder {
            color: $color-monza !important;
          }
        }
      }

      .hide-on-error {
        display: none;
      }
    }


    .btn {
      line-height: 40px;
      border-radius: 8px;

      &.btn-experian-dark-blue:disabled {
        background-color: #e0e0e1;
        color: #828588;
        border-color: #e0e0e1;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .form-validate {
    &.validation-v2 {
      .btn {
        line-height: 48px;
      }
    }
  }
}
