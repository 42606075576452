.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.underline {
  text-decoration: underline;
}

.decoration-none {
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}

.hover {
  &-underline {
    &:hover {
      text-decoration: underline;
    }
  }
  &-decoration-none {
    &:hover {
      text-decoration: none;
    }
  }
}
