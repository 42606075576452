$radius: 8px;

.partner-table {
  padding: 0 16px;

  .header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 20px;
    color: #ffffff;
    background-color: #632678;
  }

  .body {
    padding: 20px 20px;
    color: #555657;
    background-color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .line {
    display: flex;
    width: 100%;
  }

  .border-b {
    border-bottom: 1px solid #dfdfdf;
  }

  .border-r {
    border-right: 1px solid #dfdfdf;
  }

}

.partners {
  .header-text-big {
    max-width: 800px;

    @media (max-width: $screen-sm-max) {
      max-width: 100%;
      text-align: center;
    }
  }

  .top-bg {
    background-image: url($img-url + '/partners/bg-top.png');
    height: 164px;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-color: #501c65;

    // logBank
    &.logbank {
      background-image: url($img-url + '/partners/bg-logbank.png');
      background-color: #441558;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-logbank-m');
        background-size: cover;
      }

      &.reset-bg-2 {
        height: 268px;

        @media (max-width: $screen-sm-max) {
          height: auto;
          background-position-x: right;
        }
      }
    }

    // eGestor
    &.egestor {
      background-image: url($img-url + '/partners/bg-egestor.png');
      background-color: #441558;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-egestor-m');
        background-size: cover;
      }

      &.reset-bg-2 {
        height: 268px;

        @media (max-width: $screen-sm-max) {
          height: auto;
          background-position-x: right;
        }
      }
    }

    // Terra Empresas
    &.terra-empresas {
      background-image: url($img-url + '/partners/bg-terra-empresas.png');
      background-color: #441558;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-terra-empresas-m');
      }

      &.reset-bg-2 {
        height: 268px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }
    }


    // Contabilizei
    &.contabilizei {
      background-image: url($img-url + '/partners/bg-contabilizei.png');
      background-color: #441558;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-contabilizei-m');
      }
    }

    // ASAAS
    &.asaas {
      background-image: url($img-url + '/partners/bg-asaas.png');

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-asaas-m');
      }
    }

    // EDE
    &.ede {
      background-image: url($img-url + '/partners/bg-ede.png');
      min-height: 456px;
      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-ede-m');
        min-height: 0;
      }
    }

    // Serasa Certificado Digital
    &.certificado-digital {
      @include bgRetina($img-url + '/partners/bg-certificado-digital', '.jpg');

      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-certificado-digital-m-2', '.jpg');
        min-height: 0;
      }

      &.reset-bg-2 {
        height: 320px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }
    }

    // Meu Contador Online
    &.meu-contador-online {
      @include bgRetina($img-url + '/partners/bg-meu-contador-online', '.jpg');

      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-meu-contador-online-m-2', '.jpg');
        min-height: 0;
      }

      &.reset-bg-2 {
        height: 241px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }
    }

    // Serasa Fornecedor
    &.serasa-fornecedor {
      @include bgRetina($img-url + '/partners/bg-serasa-fornecedor', '.jpg');

      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-serasa-fornecedor-m-2', '.jpg');
        min-height: 0;
      }

      &.reset-bg-2 {
        height: 268px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }

      .card-intro {
        padding-left: .1rem;
        padding-right: .1rem;
      }
    }

    // Printi
    &.printi {
      @include bgRetina($img-url + '/partners/bg-printi', '.jpg');
      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-printi-m', '.jpg');
        min-height: 0;
      }

      &.reset-bg-2 {
        height: 268px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }
    }

    // Whitecom
    &.whitecom {
      @include bgRetina($img-url + '/partners/bg-whitecom', '.jpg');
      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-whitecom-m', '.jpg');
        min-height: 0;
      }

      &.reset-bg-2 {
        height: 266px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }
    }

    // Marketing Digital 360º
    &.marketing-digital-360 {
      @include bgRetina($img-url + '/partners/bg-marketing-digital-360', '.jpg');
      background-color: #441558;
      background-position-x: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        @include bgRetina($img-url + '/partners/bg-marketing-digital-360-m', '.jpg');
        min-height: 0;
      }

      &.reset-bg-2 {
        height: 266px;

        @media (max-width: $screen-sm-max) {
          height: auto;
        }
      }
    }


    @media (max-width: $screen-sm-max) {
      &.reset-bg {
        background-image: none;
        background-color: #f8f8f8;
        color: palette('experian', 'violet');
        height: auto;
      }
    }

    &.reset-bg-2 {
      height: 378px;

      @media (max-width: $screen-xs-max) {
        height: auto;
        background-position: center top;
        background-position-x: center;
        background-repeat: no-repeat;
      }

      p {
        max-width: 905px;
      }
    }
  }

  &-list {
    font-size: 18px;

    .list-item {
      height: 362px;
      width: 367px;
      position: relative;
      overflow: hidden;
      border-radius: $radius;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: $screen-sm-max) {
        height: 220px;
        width: 267px;
      }

      &:hover {
        .overlay {
          left: 0;
        }

        .hide-hover {
          display: none;
        }
      }

      // logBank
      &.logbank {
        @include bgRetina($img-url + '/partners/img-logbank');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-logbank-m');
        }

        .overlay {
          .logo {
            max-width: 193px;
            margin-top: 20px;

            @media (max-width: $screen-sm-max) {
              max-width: 126px;
              margin-top: 10px;
            }
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 155px;
          }
        }
      }


      // eGestor
      &.egestor {
        @include bgRetina($img-url + '/partners/img-egestor');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-egestor-m');
        }

        .overlay {
          .logo {
            max-width: 193px;
            margin-top: 20px;

            @media (max-width: $screen-sm-max) {
              max-width: 126px;
              margin-top: 10px;
            }
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 155px;
          }
        }
      }

      // Terra Empresas
      &.terra-empresas {
        @include bgRetina($img-url + '/partners/img-terra-empresas');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-terra-empresas-m');
        }

        .overlay {
          .logo {
            max-width: 193px;
            margin-top: 20px;

            @media (max-width: $screen-sm-max) {
              max-width: 126px;
              margin-top: 10px;
            }
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 155px;
          }
        }
      }

      // Contabilizei
      &.contabilizei {
        @include bgRetina($img-url + '/partners/img-contabilizei');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-contabilizei-m');
        }

        .overlay {
          .logo {
            max-width: 193px;
            margin-top: 20px;

            @media (max-width: $screen-sm-max) {
              max-width: 126px;
              margin-top: 10px;
            }
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 155px;
          }
        }
      }

      // ASAAS
      &.asaas {
        @include bgRetina($img-url + '/partners/img-asaas');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-asaas-m');
        }

        .overlay {
          .logo {
            max-width: 162px;

            @media (max-width: $screen-sm-max) {
              max-width: 95px;
            }
          }

          .text-1 {
            max-width: 275px;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 125px;
          }
        }
      }

      // EDE
      &.ede {
        @include bgRetina($img-url + '/partners/img-ede');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-ede-m');
        }

        .overlay {
          .logo {
            max-width: 109px;

            @media (max-width: $screen-sm-max) {
              max-width: 69px;
            }
          }

          .text-1 {
            max-width: 275px;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 102px;
          }
        }
      }

      // Serasa Certificado Digital
      &.certificado-digital {
        @include bgRetina($img-url + '/partners/img-certificado-digital');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-certificado-digital-m');
        }

        .overlay {
          .logo {
            max-width: 253px;

            @media (max-width: $screen-sm-max) {
              max-width: 194px;
            }
          }

          .text-1,
          .text-2 {
            max-width: 100%;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 194px;
          }
        }
      }

      // Meu Contador Online
      &.meu-contador-online {
        @include bgRetina($img-url + '/partners/img-meu-contador-online');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-meu-contador-online-m');
        }

        .overlay {
          .logo {
            max-width: 253px;

            @media (max-width: $screen-sm-max) {
              max-width: 194px;
            }
          }

          .text-1,
          .text-2 {
            max-width: 100%;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 194px;
          }
        }
      }

      // Serasa Fornecedor
      &.serasa-fornecedor {
        @include bgRetina($img-url + '/partners/img-serasa-fornecedor');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-serasa-fornecedor-m');
        }

        .overlay {
          .logo {
            max-width: 253px;

            @media (max-width: $screen-sm-max) {
              max-width: 194px;
            }
          }

          .text-1,
          .text-2 {
            max-width: 100%;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 194px;
          }
        }
      }

      // printi
      &.printi {
        @include bgRetina($img-url + '/partners/img-printi');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-printi-m');
        }

        .overlay {
          .logo {
            margin-top: 10px;
            max-width: 253px;

            @media (max-width: $screen-sm-max) {
              max-width: 100px;
            }
          }

          .text-1,
          .text-2 {
            max-width: 100%;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 194px;
          }
        }
      }

      // Whitecom
      &.whitecom {
        @include bgRetina($img-url + '/partners/img-whitecom');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-whitecom-m');
        }

        .overlay {
          .logo {
            margin-top: 10px;
            max-width: 253px;

            @media (max-width: $screen-sm-max) {
              max-width: 130px;
            }
          }

          .text-1,
          .text-2 {
            max-width: 100%;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 194px;
          }
        }
      }

      // Marketing Digital 360º
      &.marketing-digital-360 {
        @include bgRetina($img-url + '/partners/img-marketing-digital-360');

        @media (max-width: $screen-sm-max) {
          @include bgRetina($img-url + '/partners/img-marketing-digital-360-m');
        }

        .overlay {
          .logo {
            margin-top: 10px;
            max-width: 253px;

            @media (max-width: $screen-sm-max) {
              margin-top: 0;
              max-width: 165px;
            }
          }

          .text-1,
          .text-2 {
            max-width: 100%;
          }
        }

        .logo {
          @media (max-width: $screen-sm-max) {
            max-width: 194px;
          }
        }
      }

      .overlay {
        background-color: rgba(palette('experian', 'violet'), .8);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: -100%;
        padding-top: 20px;
        transition: left .5s ease-out;

        @media (max-width: $screen-sm-max) {
          padding-top: 12px;
        }

        .text {
          &-1 {
            max-width: 238px;
          }

          &-2 {
            max-width: 294px;
          }
        }

        .logo {
          margin-bottom: 10px;
        }
      }

      .intro {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
      }

      .img-strip {
        position: absolute;
        top: -1px;
        left: 0;

        @media (max-width: $screen-sm-max) {
          max-width: 102px;
        }
      }

      .more {
        letter-spacing: 1px;
      }
    }

    .empty-item {
      border: 1px solid palette('experian', 'violet');
      color: palette('experian', 'violet')
    }
  }

  .btn {
    height: 50px;

    @media (max-width: $screen-sm-max) {
      height: 40px;
      line-height: 37px;
    }
  }

  .sidebar,
  .main-content {
    margin-top: 42px;

    @media (max-width: $screen-xs-max) {
      margin-top: 30px;
    }
  }

  .sidebar {
    @media (min-width: $screen-md-min) {
      border-right: 1px solid $color-dusty-gray;
    }

    .circle {
      @include size(182, true);
      //border: 1px solid $color-mercury;

      img {
        margin-bottom: 15px;
      }

      @media (max-width: $screen-xs-max) {
        @include size(81, true);

        img {
          margin-bottom: 5px;
        }
      }
    }

    h4 {
      margin-top: 42px;

      @media (max-width: $screen-sm-max) {
        margin-top: 0;
      }
    }

    .description {
      @media (min-width: $screen-md-min) {
        max-width: 228px;
      }
    }

    .more {
      line-height: 19px;

      .video-play,
      span {
        vertical-align: middle;
      }

      .video-play {
        @include size(19);
      }
    }
  }

  .content {
    p {
      margin-bottom: 25px;
    }

    ul {
      padding-left: 20px;
    }

    // Printi
    &-printi {
      .sidebar {
        .circle {
          img {
            margin-bottom: 4px;
          }
        }
      }
    }

    // Whitecom
    &-whitecom {
      .sidebar {
        .circle {
          img {
            margin-bottom: 5px;
            max-width: 80%;
          }
        }
      }
    }

    // Marketing Digital 360º
    &-marketing-digital-360 {
      .sidebar {
        .circle {
          img {
            margin-bottom: 5px;
          }
        }
      }
    }

    // Marketing Digital 360º
    &-logbank {
      .sidebar {
        .circle {
          img {
            margin-bottom: 0px;
          }
        }
      }
    }

  }

  .video-list {
    .video-description {
      @media (min-width: $screen-md-min) {
        max-width: 300px;
      }
    }

    .media-video {
      width: 100%;
      max-width: 432px;
      height: 243px;


      @media (max-width: $screen-xs-max) {
        max-width: 250px;
        height: 142px;
      }

      @media (max-width: $screen-sm-max) {
        max-width: 320px;
        height: 183px;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
