$dark-blue: palette('experian', 'dark-blue');

.saude-financeira {

  .monitoring,
  .scheduler {
    min-height: 70vh;
    overflow: hidden;

    p.mw {
      max-width: 576px;
    }

    .text-soon {
      background-color: rgba($dark-blue, .1);
      padding: 12px 20px;
    }
  }

  .stepper {
    overflow-x: scroll;

    .step {
      text-align: center;
      min-width: 152px;
      width: 152px;
    }

    .step-arrow {
      width: 32px;
      min-width: 32px;
      height: 18px;
    }

    .arrow-separator {
      padding-top: 20px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .saude-financeira {
    .monitoring {
      .soon {
        padding: 12px 40px;
      }
    }

    .stepper {
      overflow-x: auto;

      .step {
        min-width: 250px;
        width: 250px;
      }

      .step-arrow {
        width: 36px;
        min-width: 36px;
        height: 25px;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .saude-financeira {
    &.financial-home {
      .financial-head {
        ul {
          .highlight {
            font-size: 36px;
          }

          .animation-item {
            line-height: 54px;
          }
        }
      }
    }

    .stepper {
      .step {
        width: auto;
        max-width: 285px;
      }
    }
  }
}

@media (max-width: 991px) {
  .saude-financeira {
    .header-entitled {
      padding: 10px 1rem;
      top: 60px;

      .header-entitled-title {
        font-size: 16px;
      }
    }
  }
}
