$color-tabbar-navitem-active: #632678;

.tab-menu {
  height: 87px;
  padding: 0;

  .financial-health-details-title {
    font-size: 16px;
    height: 24px;
    margin: 0;
    position: relative;
  }

  .fh-tabbar-submenu {
    display: none;
    position: absolute;

    &.show {
      display: flex;
    }
  }

  .tab-menu-submenu {
    &.is-active {
      display: flex;
    }
  }

  .nav-link {
    color: #9b9b9b;
    height: 100%;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    text-align: center;
    transition: color .2s;
    min-width: 110px;
    width: 100%;

    .link-icon {
      display: flex;
      height: 54px;
      position: relative;
    }

    .serasa-icon {
      display: flex;
      align-self: center;
      margin: 0 auto;
    }

    .i-inicio-branco,
    .i-informacoes-branco,
    .i-monitoramento-branco,
    .i-agenda-branco {
      display: none;
    }

    &.active:hover,
    &.active {
      color: $color-white;
    }
  }

  .nav-item:not(.active) .nav-link:not(.is-disabled):hover {
    border-bottom: 3px solid $color-tabbar-navitem-active;
    transition: border .2s;
  }

  .nav-link.is-disabled {
    opacity: .3;
  }

  .active {
    transition: background .5s;

    &,
    &:hover {
      color: $color-white;
      background: $color-tabbar-navitem-active;
    }

    &:hover {
      opacity: 1;
    }

    .i-inicio-branco,
    .i-informacoes-branco,
    .i-monitoramento-branco,
    .i-agenda-branco {
      display: flex;
    }

    .i-inicio-cinza,
    .i-informacoes-cinza,
    .i-monitoramento-cinza,
    .i-agenda-cinza {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: transparent;
    border: none;
    border-radius: 0;
    display: none;
    left: auto;
    margin-left: -2px;
    margin-top: 1px;
    padding: 0;
    top: auto;
    z-index: 795;

    &.show {
      display: flex;
    }
  }

  .dropdown-item {
    background: #ededed;
    color: #9b9b9b;
    display: inline-block;
    font-size: 12px;
    height: 30px;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
    text-align: center;
    width: 170px;

    &.active,
    &.active:hover {
      background: #a17dae;
      color: #ffffff;
      font-weight: bold;
    }
  }

  .is-fixed & {
    height: 36px;

    .financial-health-details-title {
      font-size: 16px;
    }

    .nav-link {
      font-size: 13px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .link-icon {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    height: 100%;
    width: 100vw;

    .tab-menu-submenu {
      &.is-active {
        display: none;
      }

      &.show {
        display: flex;
      }
    }

    .is-fixed & {
      height: 100%;
      // padding: 0;
      // max-width: 100vw;
      // width: 100vw;

      .link-icon {
        display: flex;
      }
    }

    .dropdown-menu {
      bottom: 70px;
      flex-direction: column;
      position: fixed;

      &:not(.is-collapsed) {
        &::before {
          background: $color-dusty-gray;
          content: '';
          display: block;
          opacity: .2;
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 60px;
        }
      }

      .dropdown-item {
        align-items: center;
        background: $color-white;
        box-sizing: content-box;
        display: inline-flex;
        height: 16px;
        padding: 11px 14px;
        margin: 3px 0;
        text-align: left;

        &.active {
          background: #a17dae;
        }
      }
    }

    .nav-item {
      display: inline-flex;
      flex: 1 1;
      overflow: hidden;

      .nav-link {
        color: inherit;
        display: flex;
        flex-direction: column;
        font-size: 10px;
        justify-content: space-around;
        min-width: auto;
        padding: 6px;

        &.active {
          color: $color-white;
        }

        .link-icon {
          height: 26px;

          .serasa-icon {
            background-size: contain;
          }
        }
      }

      &:not(.active) .nav-link:hover {
        border: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .dropdown-item {
      font-size: 13px;
      width: auto;
      padding: 4px 8px;
      line-height: 22px;
      min-width: 170px;
    }
  }
}
