.card-v2 {
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    
    &:not(.not-border) {
        border: 1px solid #e0e0e1;
    }

    > .header {
        .header-title {
            align-items: center;
            font-size: 16px;
            margin: 0;
            font-weight: 500;
            .product-icon {
                margin-right: 8px;
                height: auto;
                width: 24px;
                color: #1D4F91;
            }
        }
        .error-text {
            padding: 0 16px;
            margin-bottom: 0;
        }
    }

    > .content {
        margin: 18px 0;
    }

}