
@function palette($palette, $tone: 'base') {
	@return map-get(map-get($palettes, #{$palette}), $tone);
}

%flex-vertical-center{
  align-items: center;
  display: flex;
}

@mixin gradient($side, $toSide, $hexa1, $hexa2, $hexa3) {
  background: -moz-linear-gradient($side, $hexa1, $hexa2, $hexa3);
  background: -webkit-linear-gradient($side, $hexa1, $hexa2, $hexa3);
  background: linear-gradient(to $toSide, $hexa1, $hexa2, $hexa3);
}


@mixin set-class-colors($prop, $class, $text-color: false){
  .#{$class}{
    @each $item, $color in $palettes{
      &-#{$item} {
        @each $item1, $color2 in $color{

          @if $item1 == 'base' {
            #{$prop}: palette($item, $item1);
						@if $text-color == true{
							$test-color: palette($item, $item1);
							color: set-text-color($test-color) !important;

              &:hover{
                color: set-text-color($test-color);
              }

              &.bg-arrow-down{
                &:after{
                  border-top-color: palette($item, $item1);
                }
              }
              &.bg-arrow-up{
                &:after{
                  border-bottom-color: palette($item, $item1);
                }
              }
						}
          } @else{
            &-#{$item1}{
              #{$prop}: palette($item, $item1);
              @if $text-color == true{
                $test-color: palette($item, $item1);
                color: set-text-color($test-color);

                &:hover{
                  color: set-text-color($test-color);
                }

                &.bg-arrow-down{
                  &:after{
                    border-top-color: palette($item, $item1);
                  }
                }
                &.bg-arrow-up{
                  &:after{
                    border-bottom-color: palette($item, $item1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@mixin bg-arrow($class){
  top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #88b7d5;
	border-width: 30px;
	margin-left: -30px;
}

@function set-text-color($color){
	@if(lightness($color) > 50){
		@return #575755;
	} @else{
		@return #FFFFFF;
	}
}



@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &::-moz-placeholder          {@content}
  &::-ms-input-placeholder     {@content}
}

@keyframes scrollArrow {
  0% {
    margin-top: 8px;
  }
  100% {
    margin-top: -8px;
  }
}

@mixin size( $size, $vertical-centralize: false, $use-min: false ) {
	width: $size + px;
  height: $size + px;

  @if $use-min == true {
    min-width: $size + px;
    min-height: $size + px;
  }

  @if $vertical-centralize == true {
    line-height: $size + px;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content} /* Chrome/Opera/Safari */
  &:-moz-placeholder           {@content} /* Firefox 18- */
  &::-moz-placeholder          {@content} /* Firefox 19+ */
  &:-ms-input-placeholder      {@content} /* IE 10+ */
  &::placeholder               {@content} /* Modern browsers */
}

@mixin bgRetina($src, $ext: '.png', $fullset: true) {
  background-image: url($src + $ext);

  @if fullset == false {
    background-image: -webkit-image-set(
                        url($src + $ext) 1x,
                        url($src + '@2x' + $ext) 2x
                      );
    background-image: image-set(
                        url($src + $ext) 1x,
                        url($src + '@2x' + $ext) 2x
                      );
  } @else {
    background-image: -webkit-image-set(
                        url($src + $ext) 1x,
                        url($src + '@2x' + $ext) 2x,
                        url($src + '@3x' + $ext) 3x
                      );
    background-image: image-set(
                        url($src + $ext) 1x,
                        url($src + '@2x' + $ext) 2x,
                        url($src + '@3x' + $ext) 3x
                      );
  }
}

@mixin rgba-background($hexcolor, $opacity) {
  background-color: $hexcolor;
  background-color: rgba($hexcolor, $opacity); 
}