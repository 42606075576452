
.font {
  &-normal {
    font-weight: normal;
  }
  &-weight-100 {
    font-weight: 100;
  }
  &-light {
    font-weight: 300;
  }
  &-regular {
    font-weight: 400;
  }
  &-medium {
    font-weight: 500;
  }
  &-semi-bold {
    font-weight: 550;
  }
  &-bold {
    font-weight: 600;
  }
  &-9 {
    font-size: 9px;
  }
  &-10 {
    font-size: 10px;
  }
  &-11 {
    font-size: 11px;
  }
  &-12 {
    font-size: 12px;
  }
  &-13 {
    font-size: 13px;
  }
  &-14 {
    font-size: 14px;
  }
  &-15 {
    font-size: 15px;
  }
  &-16 {
    font-size: 16px;
  }
  &-17 {
    font-size: 17px;
  }
  &-18 {
    font-size: 18px;
  }
  &-19 {
    font-size: 19px;
  }
  &-20 {
    font-size: 20px;
  }
  &-21 {
    font-size: 21px;
  }
  &-22 {
    font-size: 22px;
  }
  &-23 {
    font-size: 23px;
  }
  &-24 {
    font-size: 24px;
  }
  &-25 {
    font-size: 25px;
  }
  &-26 {
    font-size: 26px;
  }
  &-27 {
    font-size: 27px;
  }
  &-28 {
    font-size: 28px;
  }
  &-30 {
    font-size: 30px;
  }
  &-32 {
    font-size: 32px;
  }
  &-33 {
    font-size: 33px;
  }
  &-34 {
    font-size: 34px;
  }
  &-35 {
    font-size: 35px;
  }
  &-36 {
    font-size: 36px;
  }
  &-37 {
    font-size: 37px;
  }
  &-38 {
    font-size: 38px;
  }
  &-40 {
    font-size: 40px;
  }
  &-42 {
    font-size: 42px;
  }
  &-43 {
    font-size: 43px;
  }
  &-44 {
    font-size: 44px;
  }
  &-47 {
    font-size: 47px;
  }
  &-48 {
    font-size: 48px
  }
  &-49 {
    font-size: 49px;
  }
  &-50 {
    font-size: 50px;
  }
  &-52 {
    font-size: 52px;
  }
  &-56 {
    font-size: 56px;
  }
  &-60 {
    font-size: 60px;
  }
  &-64 {
    font-size: 64px;
  }
  &-65 {
    font-size: 65px;
  }
  &-65-rem {
    font-size: 4.063rem;
  }
  &-70 {
    font-size: 75px;
  }
  &-75 {
    font-size: 75px;
  }
  &-80 {
    font-size: 80px;
  }
  &-82 {
    font-size: 82px;
  }
  &-103 {
    font-size: 103px;
  }
  &-128 {
    font-size: 128px;
  }
  &-170 {
    font-size: 170px;
  }
  &-180 {
    font-size: 180px;
  }
  &-195 {
    font-size: 195px;
  }

  &-sm {
    @media (max-width: 540px) {
      &-normal {
        font-weight: normal;
      }
      &-weight-100 {
        font-weight: 100;
      }
      &-light {
        font-weight: 300;
      }
      &-regular {
        font-weight: 400;
      }
      &-medium {
        font-weight: 500;
      }
      &-semi-bold {
        font-weight: 550;
      }
      &-bold {
        font-weight: 600;
      }
      &-8 {
        font-size: 8px;
      }
      &-9 {
        font-size: 9px;
      }
      &-10 {
        font-size: 10px;
      }
      &-11 {
        font-size: 11px;
      }
      &-12 {
        font-size: 12px;
      }
      &-13 {
        font-size: 13px;
      }
      &-14 {
        font-size: 14px;
      }
      &-15 {
        font-size: 15px;
      }
      &-16 {
        font-size: 16px;
      }
      &-17 {
        font-size: 17px;
      }
      &-18 {
        font-size: 18px;
      }
      &-19 {
        font-size: 19px;
      }
      &-20 {
        font-size: 20px;
      }
      &-21 {
        font-size: 21px;
      }
      &-22 {
        font-size: 22px;
      }
      &-23 {
        font-size: 23px;
      }
      &-24 {
        font-size: 24px;
      }
      &-25 {
        font-size: 25px;
      }
      &-26 {
        font-size: 26px;
      }
      &-27 {
        font-size: 27px;
      }
      &-28 {
        font-size: 28px;
      }
      &-30 {
        font-size: 30px;
      }
      &-32 {
        font-size: 32px;
      }
      &-33 {
        font-size: 33px;
      }
      &-35 {
        font-size: 35px;
      }
      &-34 {
        font-size: 34px;
      }
      &-36 {
        font-size: 36px;
      }
      &-37 {
        font-size: 37px;
      }
      &-38 {
        font-size: 38px;
      }
      &-40 {
        font-size: 40px;
      }
      &-42 {
        font-size: 42px;
      }
      &-43 {
        font-size: 43px;
      }
      &-44 {
        font-size: 44px;
      }
      &-47 {
        font-size: 47px;
      }
      &-48 {
        font-size: 48px;
      }
      &-49 {
        font-size: 49px;
      }
      &-50 {
        font-size: 50px;
      }
      &-52 {
        font-size: 52px;
      }
      &-56 {
        font-size: 56px;
      }
      &-60 {
        font-size: 60px;
      }
      &-64 {
        font-size: 64px;
      }
      &-65 {
        font-size: 65px;
      }
      &-65-rem {
        font-size: 4.063rem;
      }
      &-70 {
        font-size: 75px;
      }
      &-75 {
        font-size: 75px;
      }
      &-80 {
        font-size: 80px;
      }
      &-82 {
        font-size: 82px;
      }
      &-103 {
        font-size: 103px;
      }
      &-128 {
        font-size: 128px;
      }
      &-170 {
        font-size: 170px;
      }
      &-195 {
        font-size: 195px;
      }
    }
  }

  &-md {
    @media (min-width: 768px) {
      &-normal {
        font-weight: normal;
      }
      &-weight-100 {
        font-weight: 100;
      }
      &-light {
        font-weight: 300;
      }
      &-regular {
        font-weight: 400;
      }
      &-medium {
        font-weight: 500;
      }
      &-semi-bold {
        font-weight: 550;
      }
      &-bold {
        font-weight: 600;
      }
      &-9 {
        font-size: 9px;
      }
      &-10 {
        font-size: 10px;
      }
      &-11 {
        font-size: 11px;
      }
      &-12 {
        font-size: 12px;
      }
      &-13 {
        font-size: 13px;
      }
      &-14 {
        font-size: 14px;
      }
      &-15 {
        font-size: 15px;
      }
      &-16 {
        font-size: 16px;
      }
      &-17 {
        font-size: 17px;
      }
      &-18 {
        font-size: 18px;
      }
      &-19 {
        font-size: 19px;
      }
      &-20 {
        font-size: 20px;
      }
      &-21 {
        font-size: 21px;
      }
      &-22 {
        font-size: 22px;
      }
      &-23 {
        font-size: 23px;
      }
      &-24 {
        font-size: 24px;
      }
      &-25 {
        font-size: 25px;
      }
      &-26 {
        font-size: 26px;
      }
      &-27 {
        font-size: 27px;
      }
      &-28 {
        font-size: 28px;
      }
      &-30 {
        font-size: 30px;
      }
      &-32 {
        font-size: 32px;
      }
      &-33 {
        font-size: 33px;
      }
      &-34 {
        font-size: 34px;
      }
      &-35 {
        font-size: 35px;
      }
      &-36 {
        font-size: 36px;
      }
      &-37 {
        font-size: 37px;
      }
      &-38 {
        font-size: 38px;
      }
      &-40 {
        font-size: 40px;
      }
      &-42 {
        font-size: 42px;
      }
      &-43 {
        font-size: 43px;
      }
      &-44 {
        font-size: 44px;
      }
      &-47 {
        font-size: 47px;
      }
      &-48 {
        font-size: 48px;
      }
      &-49 {
        font-size: 49px;
      }
      &-50 {
        font-size: 50px;
      }
      &-52 {
        font-size: 52px;
      }
      &-56 {
        font-size: 56px;
      }
      &-60 {
        font-size: 60px;
      }
      &-64 {
        font-size: 64px;
      }
      &-65 {
        font-size: 65px;
      }
      &-65-rem {
        font-size: 4.063rem;
      }
      &-70 {
        font-size: 75px;
      }
      &-75 {
        font-size: 75px;
      }
      &-80 {
        font-size: 80px;
      }
      &-82 {
        font-size: 82px;
      }
      &-103 {
        font-size: 103px;
      }
      &-128 {
        font-size: 128px;
      }
      &-170 {
        font-size: 170px;
      }
      &-195 {
        font-size: 195px;
      }
    }
  }

  &-lg {
    @media (min-width: 992px) {
      &-normal {
        font-weight: normal;
      }
      &-weight-100 {
        font-weight: 100;
      }
      &-light {
        font-weight: 300;
      }
      &-regular {
        font-weight: 400;
      }
      &-medium {
        font-weight: 500;
      }
      &-semi-bold {
        font-weight: 550;
      }
      &-bold {
        font-weight: 600;
      }
      &-9 {
        font-size: 9px;
      }
      &-10 {
        font-size: 10px;
      }
      &-11 {
        font-size: 11px;
      }
      &-12 {
        font-size: 12px;
      }
      &-13 {
        font-size: 13px;
      }
      &-14 {
        font-size: 14px;
      }
      &-15 {
        font-size: 15px;
      }
      &-16 {
        font-size: 16px;
      }
      &-17 {
        font-size: 17px;
      }
      &-18 {
        font-size: 18px;
      }
      &-19 {
        font-size: 19px;
      }
      &-20 {
        font-size: 20px;
      }
      &-21 {
        font-size: 21px;
      }
      &-22 {
        font-size: 22px;
      }
      &-23 {
        font-size: 23px;
      }
      &-24 {
        font-size: 24px;
      }
      &-25 {
        font-size: 25px;
      }
      &-26 {
        font-size: 26px;
      }
      &-27 {
        font-size: 27px;
      }
      &-28 {
        font-size: 28px;
      }
      &-30 {
        font-size: 30px;
      }
      &-32 {
        font-size: 32px;
      }
      &-33 {
        font-size: 33px;
      }
      &-35 {
        font-size: 35px;
      }
      &-36 {
        font-size: 36px;
      }
      &-37 {
        font-size: 37px;
      }
      &-38 {
        font-size: 38px;
      }
      &-40 {
        font-size: 40px;
      }
      &-42 {
        font-size: 42px;
      }
      &-43 {
        font-size: 43px;
      }
      &-44 {
        font-size: 44px;
      }
      &-47 {
        font-size: 47px;
      }
      &-48 {
        font-size: 48px;
      }
      &-49 {
        font-size: 49px;
      }
      &-50 {
        font-size: 50px;
      }
      &-52 {
        font-size: 52px;
      }
      &-56 {
        font-size: 56px;
      }
      &-60 {
        font-size: 60px;
      }
      &-65 {
        font-size: 65px;
      }
      &-65-rem {
        font-size: 4.063rem;
      }
      &-70 {
        font-size: 75px;
      }
      &-75 {
        font-size: 75px;
      }
      &-80 {
        font-size: 80px;
      }
      &-82 {
        font-size: 82px;
      }
      &-64 {
        font-size: 64px;
      }
      &-103 {
        font-size: 103px;
      }
      &-128 {
        font-size: 128px;
      }
      &-170 {
        font-size: 170px;
      }
      &-195 {
        font-size: 195px;
      }
    }
  }

  &-xl {
    @media (min-width: 1200px) {
      &-normal {
        font-weight: normal;
      }
      &-weight-100 {
        font-weight: 100;
      }
      &-light {
        font-weight: 300;
      }
      &-regular {
        font-weight: 400;
      }
      &-medium {
        font-weight: 500;
      }
      &-semi-bold {
        font-weight: 550;
      }
      &-bold {
        font-weight: 600;
      }
      &-9 {
        font-size: 9px;
      }
      &-10 {
        font-size: 10px;
      }
      &-11 {
        font-size: 11px;
      }
      &-13 {
        font-size: 13px;
      }
      &-12 {
        font-size: 12px;
      }
      &-14 {
        font-size: 14px;
      }
      &-15 {
        font-size: 15px;
      }
      &-16 {
        font-size: 16px;
      }
      &-17 {
        font-size: 17px;
      }
      &-18 {
        font-size: 18px;
      }
      &-19 {
        font-size: 19px;
      }
      &-20 {
        font-size: 20px;
      }
      &-21 {
        font-size: 21px;
      }
      &-22 {
        font-size: 22px;
      }
      &-23 {
        font-size: 23px;
      }
      &-24 {
        font-size: 24px;
      }
      &-25 {
        font-size: 25px;
      }
      &-26 {
        font-size: 26px;
      }
      &-27 {
        font-size: 27px;
      }
      &-28 {
        font-size: 28px;
      }
      &-30 {
        font-size: 30px;
      }
      &-32 {
        font-size: 32px;
      }
      &-33 {
        font-size: 33px;
      }
      &-34 {
        font-size: 34px;
      }
      &-35 {
        font-size: 35px;
      }
      &-36 {
        font-size: 36px;
      }
      &-37 {
        font-size: 37px;
      }
      &-38 {
        font-size: 38px;
      }
      &-40 {
        font-size: 40px;
      }
      &-42 {
        font-size: 42px;
      }
      &-43 {
        font-size: 43px;
      }
      &-44 {
        font-size: 44px;
      }
      &-47 {
        font-size: 47px;
      }
      &-49 {
        font-size: 49px;
      }
      &-50 {
        font-size: 50px;
      }
      &-52 {
        font-size: 52px;
      }
      &-56 {
        font-size: 56px;
      }
      &-60 {
        font-size: 60px;
      }
      &-64 {
        font-size: 64px;
      }
      &-70 {
        font-size: 75px;
      }
      &-75 {
        font-size: 75px;
      }
      &-80 {
        font-size: 80px;
      }
      &-82 {
        font-size: 82px;
      }
      &-65 {
        font-size: 65px;
      }
      &-65-rem {
        font-size: 4.063rem;
      }
      &-103 {
        font-size: 103px;
      }
      &-128 {
        font-size: 128px;
      }
      &-170 {
        font-size: 170px;
      }
      &-195 {
        font-size: 195px;
      }
    }
  }
}
