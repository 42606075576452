
$circle-size-mobile: 22px;
$circle-size-tablet: 30px;
$circle-size-desk: 40px;
.stepper {
  padding-bottom: 10px;
  text-align: center;
  color: $color-white;
  ul {
    padding: 0;
    li {
      a {
        display: block;
        width: 100%;
        height: 100%;
        color: $color-white;

        &:hover {
          text-decoration: none;
          opacity: 1;
        }
      }
    }

    .stepper-item {
      background-color: $color-custom-grey;
      display: inline-block;
      width: $circle-size-mobile;
      height: $circle-size-mobile;
      border-radius: 50%;
      text-align: center;
      margin-right: 25px;
      position: relative;
      transition: all 0.5s ease;

      .step-number {
        line-height: 23px;
        vertical-align: middle;
      }

      &::before, &::after {
        content: "";
        width: 25px;
        height: 1px;
        position: absolute;
        top: 45%;
        left: $circle-size-mobile;
        transition: all 0.5s ease;
      }
      &::before {
        background-color: $color-custom-grey;
      }
      &::after {
        width: 0;
      }
      &:last-child {
        margin-right: 0;
        &::before,
        &::after {
          display: none;
        }
      }
      &.on-step {
        background-color: $color-violet;
        &::before {
          background-color: $color-violet;
        }
        .title {
          color: $color-violet;
        }
      }
      &.current-step {
        background-color: $color-violet;

        .title {
          color: $color-violet;
        }
      }
    }
  }

  .title {
    display: none;
  }
}

@media (min-width: $screen-sm-min) {
  .stepper {
    padding: 0 0 72px;
    ul {
      .stepper-item {
        width: $circle-size-tablet;
        height: $circle-size-tablet;
        min-width: $circle-size-tablet;
        font-size: 18px;
        margin-right: 88px;

        &::before, &::after {
          width: 88px;
          height: 2px;
          top: 45%;
          left: $circle-size-tablet;
        }

        .step-number {
          line-height: $circle-size-tablet;
        }
      }
    }
    .title {
      position: absolute;
      top: 55px;
      left: -36px;
      width: 100px;
      color: $color-custom-grey;
      font-weight: 400;
      font-size: 12px;
      display: block;
    }
  }
}

@media (min-width: $screen-md-min) {
  .stepper {
    ul {
      .stepper-item {
        width: $circle-size-desk;
        height: $circle-size-desk;
        min-width: $circle-size-desk;
        font-size: 20px;
        margin-right: 135px;

        &::before, &::after {
          width: 135px;
          height: 3px;
          top: 45%;
          left: $circle-size-desk;
        }

        .step-number {
          line-height: $circle-size-desk;
        }
      }
    }
    .title {
      left: -30px;
    }
  }
}



