.header-entitled {
  padding: 1rem;
  color: $color-white;
  display: flex;
  transition: height 1s linear;

  &-title {
    display: inline-flex;
    align-items: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &-content {
    flex-grow: 1;
  }

  &-list-item {
    align-items: center;
    display: inline-flex;
  }

  &-list-item,
  &-list-item a,
  &-list-item:hover,
  &-list-item a:hover {
    color: $color-white;
    font-size: 12px;
    transition: font-size .1s linear;

    .nav-link {
      display: flex;
      align-items: center;
      color: $color-white;
      opacity: 1;

      &.is-disabled {
        opacity: .2;
      }
    }
  }

  &-list-item-badge {
    line-height: .9rem;
    margin-left: 7px;
    font-size: 12px;
    vertical-align: middle;

    .header-entitled-badge-tooltip-description {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 768px) {

    &-list-item,
    &-list-item a {
      font-size: 14px;
    }

    &-list-item-badge {
      .header-entitled-badge-tooltip-description {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &-container {
      max-width: 100vw;
    }
  }

  @media screen and (min-width: 992px) {
    &-container {
      flex-direction: row;
    }
  }
}
