$breakpoint-mobile: 1024px;
$background-header: #fff;
$background-content: #f7f7f8;
$padding-responsive: 16px;

.wizard {
    &.card-v2 {
        padding: 0;
        > .card-header, .card-footer {
            background-color: $background-header;
        }
        > .card-content {
            padding: 16px 24px 24px;
            background-color: $background-content;
        }
        > .card-header {
            border-bottom: 0;
            padding: 32px 0;
        }
        > .card-footer {
            border-top: 0;
            padding: 24px;
            .buttons {
                display: flex;
                justify-content: space-between;
            }
        }
    }
    .steps {
        display: flex;
    }
    .step {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 25%;
        color: #c1c2c3;
        &.active {
            color: #333;
            .icon {
                width: 32px;
                height: 32px;
                box-shadow: 0 1px 4px 0 rgba(99, 102, 106, 0.34);
            }
            .label {
                font-weight: 500;
            }
            &:not(.done) {
                .icon .arrow {
                    display: flex;
                }
            }
        }
        &:not(.active) {
            .representation {
                margin-top: 4px;
            }
        }
        &.done {
            .icon {
                background-color: #1d4f91;
                .active {
                    display: none;
                }
                .check-white {
                    display: flex;
                }
            }
        }
        &:first-child {
            .representation::before {
                left: 50%;
            }
        }
        &:last-child {
            .representation::before {
                right: 50%;
            }
        }
        .identification {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .representation {
            position: relative;
            &::before {
                content: ' ';
                height: 1px;
                left: 0;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                background: #333;
                position: absolute;
            }
        }
        .icon {
            position: relative;
            margin: auto;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            border: solid 1px #cacbcc;
            background-color: #ffffff;
            i {
                height: 16px;
                width: 100%;
                display: none;
                svg {
                    height: inherit;
                    width: inherit;
                }
                &.arrow svg use{
                    fill: #1d4f91;
                }
            }
            img {
                display: none;
                margin: auto;
            }
        }
        .label {
            margin-top: 8px;
            color: inherit;
            text-align: center;
        }
    }
    .icon-center {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .steps-mobile {
        display: none;
        align-items: center;
        > .c100 {
            margin-right: 16px;
        }
        .step-title {
            .next {
                color: #828588;
            }
            > span {
                display: block;
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        &.card-v2 {
            .card-header, .card-content, .card-footer {
                padding: $padding-responsive;
            }
            .card-footer {
                padding-top: 0;
                background-color: $background-content;
            }
        }
        .steps-mobile {
            display: flex;
        }
        .steps {
            display: none;
        }

    }
}