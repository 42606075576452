// <------------- COLORS -------------->

@include set-class-colors("background-color", "bgc", $text-color: true);
@include set-class-colors("color", "color", $text-color: false);

.pme-tooltip{
  font-size: 14px !important;
}

.no-overflow {
  overflow: hidden;
  padding-right: 17px !important;

  .fixed-bar {
    right: 8.5px;
  }

  @media (max-width: 1023px) {
    overflow: unset;
    -webkit-overflow-scrolling : touch !important;
  }
}

.bgc-half-left-experian-violet {
  $color: palette(experian, "violet");
  background: linear-gradient(90deg, #fff 50%, $color 50%);
  color: set-text-color($color);
}

.bg-arrow-down {
  &::after {
    top: 100%;
  }
}

.bg-arrow-up {
  &::after {
    bottom: 100%;
  }
}

// - TODO: Trocar pela diretiva
.rating-star {
  &-rated {
    color: palette(experian, "yellow");
  }

  &-unrated {
    color: palette("grey", dark);
  }
}

.roboto-thin {
  font-weight: $global-weight-thin;
}

.font-xl {
  font-size: 3.125rem;
}

.bg-arrow-down,
.bg-arrow-up {
  padding: 60px 0;
  position: relative;

  @media (max-width: $screen-sm-max) {
    padding: 30px 0;
  }

  @media (min-width: $screen-md-min) {
    padding: 60px 0;
  }

  &::after {
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 15px;
    margin-left: -15px;
    z-index: 1;

    @media (min-width: $screen-md-min) {
      border-width: 25px;
      margin-left: -25px;
    }
  }
}

// <------------- GLOBAL -------------->

body {
  overflow-x: hidden;
  font-weight: $body-font-weight;

  @media (max-width: $screen-xs-max) {
    font-size: 14px;
  }

  &.modal-open {
    overflow: hidden;
  }
}

*:focus {
  outline: none;
}

.row>div[class*="col-"]>.row>div[class*="col-"] {
  padding: 0;
}

// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
}

h4,
.h4 {
  font-weight: $global-weight-light;
}

h5,
.h5 {
  font-weight: $global-weight-normal;
}

h6,
.h6 {
  font-weight: $global-weight-medium;
}

a {
  cursor: pointer;
  color: palette("grey", dark);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    opacity: 0.85;

    &:not(.btn) {
      color: palette("grey", dark);
    }
  }

  &.h-underline:hover {
    span {
      text-decoration: underline;
    }
  }
}

@media (max-width: $screen-xs-max) {

  h1,
  .h1 {
    font-size: 36px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 20px;
  }

  h4,
  .h4 {
    font-size: 18px;
  }

  h5,
  .h5 {
    font-size: 16px;
  }

  h6,
  .h6 {
    font-size: 14px;
  }
}

@media (min-width: 1440px) {

  h1,
  .h1 {
    font-size: 3.125rem;
  }

  h1.big,
  .h1.big {
    font-size: 4.375rem;
  }

  h2,
  .h2 {
    font-size: 2.375rem;
  }

  h3,
  .h3 {
    font-size: 2.125rem;
  }

  h4,
  .h4 {
    font-size: 1.375rem;
  }

  h5,
  .h5 {
    font-size: 0.875rem;
  }

  h6,
  .h6 {
    font-size: 0.75rem;
  }
}

.homeout {
  header {
    @media (min-width: 768px) {
      height: $height-top-bar + $height-top-fixed !important;

      .fixed-bar {
        padding-top: $height-top-bar !important;
      }

      &.fixed {
        .fixed-bar {
          padding-top: 0 !important;
        }

        .menu-global {
          transform: scale(0);
        }
      }
    }
  }
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: '';
}



// Begin Mobile

@media (max-width: 767px) {
  // Swiper slide

  .swiper-container {
    position: initial !important;
    z-index: 2 !important;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 70px !important;
  }

  .swiper-wrapper {
    z-index: 1 !important;
  }

  .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet-first,
  .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet-last {
    border: none !important;
  }

  .swiper-slide {
    text-align: center;
  }

  .caption::before {
    display: block;
  }

  .swiper-slide .title {
    width: 100%;
    position: absolute;
    bottom: 200px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.1px;
    color: #fff;
    z-index: 99 !important;
  }

  .swiper-slide .subtitle {
    width: 100%;
    position: absolute;
    bottom: 160px;
    font-size: 48px;
    font-family: $font-family-sans-serif;
    font-weight: lighter;
    z-index: 99 !important;
  }

  .swiper-slide .text {
    width: 100%;
    position: absolute;
    font-size: 16px;
    bottom: 100px;
    line-height: 1.3;
    padding-left: 55px;
    padding-right: 55px;
    color: rgba(255, 255, 255, 0.5);
    z-index: 99 !important;
  }

  .text-area {
    display: none;
  }
}

.caption {
  &::before {
    content: "";
    background: linear-gradient(to top, black, transparent);
    width: 100%;
    height: 60vh;
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }
}

@media (max-width: 320px) {
  .swiper-slide .text {
    padding-left: 36px;
    padding-right: 36px;
  }
}

div[id="step-2-validation"] {
  [id="modal-id-validation"] {
    padding: 0;

    @media (min-width: 1024px) {
      width: 780px;
      height: 578px;
    }

    &.ml-auto {
      margin-left: 0 !important;
    }

    &.pr-lg-5 {
      padding-right: 0 !important;
    }

    &.px-3 {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 720px) {
  .hide-mobile {
    display: none !important;
  }

  .navbar {
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media (min-width: 721px) {
  .hide-desktop {
    display: none !important;
  }
}

.disabled-icon {
  opacity: 0.5;
}

.sticky {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 2;

  @media (max-width: 767px) {
    top: 60px;
  }
}

@media (max-width: 425px) {
  .fix-header {
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1;
  }
}

//Não Remover
.pre-line {
  white-space: pre-line;
}

.link {
  text-decoration: underline;
  color: #00f;
}

// CSS para carda dinâmicos da home logada
#home-dynamic-cards {
  :nth-child(odd) .cards-home {
    background: #f7f7f7;
  }

  @media (min-width: 992px) {
    :nth-child(even) .cards-home {
      &-description {
        order: 2;
      }
    }
  }
}

.circle {
  @include size(50);
  @include border-radius(50%);
}

.centered-full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.accordion-child-text a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.prices .prices-card {
  &.single {
    svg {
      width: 16px;
      // color: #632678;
    }

    .card-list .list-disabled {
      color: #d2d1d2;

      svg {
        color: #d2d1d2;
        width: 12px;
        margin: 0 2px;
      }
    }
  }

  &.subscriber {
    svg {
      width: 16px;
      // color: #e63888;
    }
  }
}

.why,
.comments {
  .swiper-wrapper {
    z-index: 0 !important;
  }

  .swiper-pagination {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    bottom: -32px !important;
    z-index: 1 !important;

    @media (min-width: 768px) {
      bottom: -37px !important;
    }

    .swiper-pagination-bullet {
      background: #ddd !important;
      border-radius: 15px;
      display: block;
      height: 15px;
      width: 15px;
      margin: 0 7px;

      &-active {
        background: #406eb3 !important;
      }
    }
  }
}

.partners {
  .swiper-wrapper {
    z-index: 0 !important;
  }

  .swiper-pagination {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    bottom: 115px !important;
    z-index: 0 !important;

    @media (min-width: 768px) {
      bottom: 230px !important;
    }

    .swiper-pagination-bullet {
      background: #ddd;
      border-radius: 15px;
      display: block;
      height: 15px;
      width: 15px;
      margin: 0 7px;

      &-active {
        background: #406eb3 !important;
      }
    }
  }
}

.why {
  .swiper-pagination {
    bottom: 30px !important;

    .swiper-pagination-bullet {
      height: 10px;
      width: 10px;
    }
  }
}

.reports,
.report-types {
  .swiper-wrapper {
    z-index: 0 !important;
  }

  .swiper-pagination {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    bottom: 115px !important;
    z-index: 0 !important;
    padding-top: 20px;

    @media (min-width: 768px) {
      bottom: 230px !important;
    }

    .swiper-pagination-bullet {
      background: #ddd !important;
      border-radius: 50%;
      display: block;
      height: 10px;
      width: 10px;
      margin: 0 7px;

      &-active {
        background: #406eb3 !important;
      }
    }
  }
}

.section-content {
  .swiper-pagination {
    text-align: center;
    margin-top: 16px;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      background-color: #d9dadb !important;
      border-radius: 50%;
      margin: 0 4px;

      &-active {
        background-color: #1d4f91 !important;
      }
    }
  }
}

.swiper-pagination-bullet:only-child {
  display: inline-block !important;
}
