.video {
  @include border-radius(8px);
  position: relative;
  overflow: hidden;

  

  &-large {
    //width: 100%;
    max-width: 1020px;
    //height: 534px;

    .video-play {
      @include size(159);
    }
  }

  .video-play {
    width: 159px;
    height: 159px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    
    @include transition-property(all);
    @include transition-duration(0.3s);
    @include transition-timing-function(ease-out);
  }

  .video-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;    
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;

    &:hover {
      .video-play {
        // opacity: 0.50;
        @include scale(1.3);
      }
    }
  }
}