.modal-basic {
    &.modal-dialog {
        height: 100%;
        margin-top: 0;
        display: flex;
        align-items: center;
    }
    .modal-content {
        border-radius: 8px;
        padding: 42px 53px;
        text-align: center;
    }
    .title, .message {
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 16px;
    }
    .title {
        color: #585959;
    }
    .message {
        color: #828588;
    }
    .buttons {
        display: flex;
        justify-content: space-around;
    }
    .btn-v2 {
        width: 94px;
        height: 32px;
        padding: 0 4px;
        font-size: 14px;
        font-weight: 500;
        line-height: initial;
    }
    @media (max-width: 576px) {
        &.modal-dialog {
            max-width: 320px;
        }
    }
}

.modal-video-yt {
    width: 90% !important;
}

.modal-contract {
  width: 100% !important;
}
