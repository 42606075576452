@import 'styles/components';

.tooltip-v2 {
  position: fixed;
  background-color: rgba(51, 51, 51, .95);
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 1.43;
  font-size: 14px;
  color: #fff;
  max-width: 280px;
  font-weight: 400;
  white-space: normal;
  z-index: 500;
  top: 0;
  left: 0;
  word-break: break-word;

  a {
    text-decoration: underline !important;
  }

  > div {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #3d3d3d;
    right: -5px;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
  }

  &.left {
    > div {
      right: 0;
      top: 50%;
      margin-right: -5px;
      transform: rotate(45deg);
    }
  }

  &.right {
    > div {
      right: 100%;
      top: 50%;
      margin-right: -5px;
      transform: rotate(45deg);
    }
  }

  &.bottom {
    > div {
      right: 50%;
      top: 0;
      transform: rotate(45deg);
    }
  }

  @media (max-width: 767px) {
    top: unset !important;
    left: 0 !important;
    right: 0 !important;
    bottom: -10px !important;
    max-width: unset !important;
    background: #fff !important;
    color: #333;
    border-radius: 8px 8px 0 0;
    z-index: 850;
    display: block !important;
    padding: 8px 16px 32px;

    &:before {
      content: '';
      background-color: #e0e0e1;
      height: 4px;
      width: 40px;
      display: block;
      border-radius: 4px;
      margin: 8px auto 16px;
    }

    &.show {
      bottom: 0 !important;
      opacity: 1;
      visibility: visible;
      transition: visibility 0s, bottom .2s ease-out, opacity .1s linear;
    }

    &.hide {
      bottom: -10px !important;
      opacity: 0;
      visibility: hidden;
      transition: visibility .2s, bottom .1s ease-out, opacity .15s linear;
    }

    > div {
      display: none;
    }
  }
}

.tooltip-sdsn {
  position: fixed;
  background-color: rgba(51, 51, 51, .95);
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 1.43;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #fff;
  max-width: 190px;
  font-weight: normal;
  white-space: normal;
  z-index: 500;
  top: 0;
  left: 0;

  @media screen and (min-width: $screen-sm-min) {
    max-width: 230px;
  }

  a {
    text-decoration: underline !important;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #3d3d3d;
    right: -5px;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
  }

  &.right {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      background-color: #3d3d3d;
      right: 100%;
      top: 50%;
      margin-top: -5px;
      margin-right: -5px;
      transform: rotate(45deg);
    }
  }

  &.bottom {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      background-color: #3d3d3d;
      right: 50%;
      top: 0;
      margin-top: -5px;
      transform: rotate(45deg);
    }
  }
}

.tooltip-v2,
.tooltip-sdsn {
  display: none;
  pointer-events: none;

  &.show {
    display: block;
    pointer-events: initial;
  }
}

@media (max-width: 767px) {
  #el-tooltip-backdrop {
    background-color: rgba(#333, .8);
    position: fixed;
    z-index: 800;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    &.show {
      opacity: 1;
      visibility: visible;
      transition: visibility 0s, opacity .1s linear;
    }

    &.hide {
      opacity: 0;
      visibility: hidden;
      transition: visibility .2s, opacity .15s linear;
    }
  }

  .tooltip-v2,
  .tooltip-sdsn {
    top: unset !important;
    left: 0 !important;
    right: 0 !important;
    bottom: -10px !important;
    max-width: unset !important;
    background: #fff;
    color: #333;
    border-radius: 8px 8px 0 0;
    z-index: 850;
    display: block !important;
    padding: 8px 16px 32px;

    &:before {
      content: '';
      background-color: #e0e0e1;
      height: 4px;
      width: 40px;
      display: block;
      border-radius: 4px;
      margin: 8px auto 16px;
    }

    &:after {
      content: unset;
    }

    &.show {
      bottom: 0 !important;
      opacity: 1;
      visibility: visible;
      transition: visibility 0s, bottom .2s ease-out, opacity .1s linear;
      z-index: 801;
    }

    &.hide {
      bottom: -10px !important;
      opacity: 0;
      visibility: hidden;
      transition: visibility .2s, bottom .1s ease-out, opacity .15s linear;
    }

    > div {
      display: none;
    }
  }
}

.tooltip-thinner {
  font-size: 12px;
  padding: 16px
}
