.saude-financeira {
  .financial-health-header {
    top: 60px;

    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100vw;

    .fh-header-title-tooltip {
      display: none;
    }

    .fh-header-tooltip-content {
      color: white;
      font-size: 16px;
      font-weight: normal;
      text-align: left;
    }

    .header-entitled-title {
      margin: 0;
      font-size: 16px;
      height: 38px;
      transition: font-size .1s linear,
        height .1s linear;
    }

    .financial-health-details {
      color: #632678;
    }

    // Header fixed
    &.is-fixed {
      position: fixed;
      z-index: 2;
      top: 60px;

      .financial-health-details-description {
        display: none;
      }

      .header-entitled {
        height: 40px;
        padding: 3px 1rem;
        box-sizing: border-box;

        .header-entitled-title {
          height: auto;
        }
      }
    }
  }

  &-title {
    color: #632678;
    position: relative;
    margin-bottom: 0;
  }

  &-description {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 400;
  }

  &-premium {
    background: #982881;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border-radius: 10px;
    padding: 2px 12px;
    position: absolute;
    top: 8px;
    margin-left: 8px;
  }

  .wrap-nav {
    background-color: $color-white;
    border-width: 0;
    border-style: solid;
    border-color: #632678;
    border-bottom-width: 1px;
    width: 100vw;
  }

  @media screen and (max-width: 991px) {
    &-description {
      font-size: 12px;
      margin: 2px 0 !important;
    }

    .financial-health-header {

      .financial-health-title-tooltip {
        display: block;
        color: #825193;
        background: white;
        border-radius: 50%;
        font-size: 10px;
        font-weight: 700;
        height: 16px;
        line-height: 1rem;
        width: 16px;
      }

      .header-entitled-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 67px;
      }

      .fh-header-title-tooltip {
        display: flex;
      }

      // Same style both static or fixed
      .header-entitled,
      &.is-fixed .header-entitled {
        padding: 0;
        height: auto;

        >.container {
          padding: 0;
          margin: 0;
          flex-direction: column;
        }

        .header-entitled-title {
          height: 38px;
          padding: 8px 13px 5px;
          width: 100vw;
        }

        .header-entitled-content {
          background: #825193;
          width: 100%;

          .nav {
            display: flex;
            width: 100%;
          }

          .header-entitled-list-item,
          .header-entitled-list-item a {
            display: inline-flex;
            flex: 1;
          }
        }
      }
    }

    .wrap-nav {
      bottom: 0;
      border-bottom: none;
      border-top-width: 2px;
      box-sizing: content-box;
      height: 60px;
      position: fixed;
      z-index: 2;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .financial-health-header.is-fixed {
      .header-entitled-title {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .financial-health-header {
      top: 80px;

      .header-entitled-title {
        font-size: 22px;
      }

      .header-entitled-list-item,
      .header-entitled-list-item a {
        font-size: 14px;
      }

      &.is-fixed {
        top: 80px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .financial-health-header {
      .header-entitled-title {
        font-size: 30px;
      }

      &.is-fixed {
        .header-entitled-title {
          font-size: 16px;
        }

        .header-entitled-list-item,
        .header-entitled-list-item a {
          font-size: 12px;
        }
      }
    }
  }
}
