.serasa-tooltip {
  display: none;
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba($color-custom-grey, .5);
  padding: 0;
  z-index: 1000;
  line-height: normal;
  min-width: 50px;
  min-height: 50px;

  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &.tooltip-right {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;

    &:after,
    &:before {
      right: 100%;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #ffffff;
      border-width: 8px;
      // margin-top: -8px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:before {
      border-color: rgba(87, 87, 86, 0);
      border-right-color: $color-custom-grey;
      border-width: 9px;
      // margin-top: -9px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &.tooltip-infobt {
      left: 20px;
    }
  }

  &.tooltip-top {
    top: auto;
    bottom: calc(100% + 15px);
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;

    &:after,
    &:before {
      //right: 100%;
      bottom: 0;
      margin: auto;
      right: 0;
      left: 0;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 9px;
      top: calc(100% + 17px);
    }

    &:before {
      border-color: rgba(87, 87, 86, 0);
      border-top-color: $color-custom-grey;
      border-width: 9px;
      top: calc(100% + 19px);
    }

    &.tooltip-infobt {
      left: 20px;
    }
  }

  &.tooltip-bottom {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;

    &:after,
    &:before {
      bottom: 100%;
      right: 0;
      left: 0;
      margin: auto;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 8px;
    }

    &:before {
      border-color: rgba(87, 87, 86, 0);
      border-bottom-color: $color-custom-grey;
      border-width: 9px;
    }

    &.tooltip-infobt {
      top: 20px;
    }
  }

  &.tooltip-purple {
    background-color: rgba(palette('experian', 'purple'), .9);
    border: none;


    &.tooltip-bottom {

      &:before,
      &:after {
        border-bottom-color: rgba(palette('experian', 'purple'), .9);
      }

      .tooltip-text {
        color: #fff;
      }
    }

  }

  &.tooltip-dark-blue {
    background-color: rgba(palette('experian', 'purple'), .9);
    border: none;


    &.tooltip-bottom {

      &:before,
      &:after {
        border-bottom-color: rgba(palette('experian', 'purple'), .9);
      }

      .tooltip-text {
        color: #fff;
      }
    }

  }


  .tooltip-text {
    padding: 0 15px;
    font-size: 11px;
    line-height: 13px;
    color: palette('grey', 'dark');
    display: inline-block;

  }
}

.has-tooltip {
  cursor: pointer;

  &:hover,
  &.tooltip-visible {
    .serasa-tooltip {
      display: block;
    }
  }
}

.v2 {
  .serasa-tooltip {
    background-color: palette('grey', 'dark');
    border-radius: 8px;
    min-width: 0;
    min-height: 0;
    width: 320px;
    border-color: transparent;
    transition: all .2s;

    &.tooltip-right {
      bottom: auto;
      top: -23px;

      &:before, &:after {
        border-right-color: palette('grey', 'dark');
      }

      &:before {
        bottom: auto;
        top: 26px;
      }

      &:after {
        bottom: auto;
        top: 27px;
      }
    }

    .tooltip-text {
      color: #fff;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      padding: 24px;
      margin-bottom: 0;
    }
  }

  .has-tooltip {
    .tooltip-right {
      left: calc(100% + 12px);
    }
  }

  .tooltip-info-button {
    width: 22px;
    height: 22px;
  }
}

@media (min-width: $screen-md-min) {
  .serasa-tooltip {
    &.tooltip-md-right {
      top: 0;
      bottom: 0;
      left: auto;
      right: auto;
      margin: auto;
      position: absolute;

      &:after,
      &:before {
        right: 100%;
        top: 0;
        bottom: 0;
        left: auto;
        margin: auto;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 8px;

      }

      &:before {
        border-color: rgba(87, 87, 86, 0);
        border-right-color: $color-custom-grey;
        border-width: 9px;
      }

      &.tooltip-infobt {
        left: 20px;
      }
    }
  }
}

@media (max-width: 420px) {
  .v2 {
    .serasa-tooltip {
      background-color: palette('grey', 'dark');
      border-radius: 8px;
      min-width: 0;
      min-height: 0;
      width: 180px;
      border-color: transparent;
      transition: all .2s;
  
      &.tooltip-sm-right {
        width: 160px;
        bottom: auto;
        top: -23px;
        z-index: 790;
  
        &:before, &:after {
          border-right-color: palette('grey', 'dark');
        }
  
        &:before {
          bottom: auto;
          top: 26px;
        }
  
        &:after {
          bottom: auto;
          top: 27px;
        }
      }

      &.tooltip-sm-bottom {
        top:34px;
        left: calc(100% - 101px) !important;
        right: 0;
        margin: auto;
        position: absolute;
        z-index: 790;
    
        &:after,
        &:before {
          bottom: 100%;
          right: 0;
          left: 0;
          margin: auto;
        }
    
        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color:palette('grey', 'dark');
          border-width: 8px;
          bottom: auto;
          top: -17px;
        }
    
        &:before {
          border-color: rgba(87, 87, 86, 0);
          border-bottom-color: $color-custom-grey;
          border-width: 9px;
          bottom: auto;
          top: -19px;
        }
    
        &.tooltip-infobt {
          top: 20px;
        }
      }
  
      .tooltip-text {
        color: #fff;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        padding: 24px;
        margin-bottom: 0;
      }
    }
  
    .has-tooltip {
      .tooltip-right {
        left: calc(100% + 12px);
      }
    }
  
    .tooltip-info-button {
      width: 22px;
      height: 22px;
    }
  }
}

bs-tooltip-container {
  &.credit-tooltip {
    .tooltip-inner {
      color: $color-white;
      background-color: $color-san-marino;
    }

    .tooltip-arrow {
      &::before {
        border-right-color: $color-san-marino;
      }
    }

    &.tooltip-right {
      .tooltip-inner {
        max-width: 180px;
        padding: 20px 25px;
      }

      .tooltip-arrow {
        top: 45% !important;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    &.credit-tooltip {
      left: 23px !important;

      .tooltip-inner {
        min-width: 240px;
        width: 240px;
      }

      .tooltip-arrow {
        left: 215px !important;

        &::before {
          border-right-color: transparent;
          border-top-color: $color-san-marino;
        }
      }
    }
  }
}
