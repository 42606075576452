@import 'components/header';
@import 'components/tabmenu';
@import 'components/footer';

.financial-health-content {
  .financial-health-content-panel {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 991px) {
    .financial-health-content-panel {
      padding-top: 20px;
    }
  }
}
