%creditcards {
  width: 32px;
  min-width: 32px;
  height: 21px;
}

.serasa-icon {
  @include size(24);
  background-repeat: no-repeat;
  display: inline-block;

  &.badge {
    &::after {
      content: '';
      position: absolute;
      top: 15px;
      right: 23px;
      background-color: #ba2f7d;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      border: 1px solid #fff;
      text-align: center;
      color: #fff;
      font-size: 14px;
      vertical-align: middle;
      font-weight: bold;
    }
  }

  &.bg-cover {
    background-size: cover;
  }

  &.i-mini {
    @include size(16);
  }

  &.i-medium {
    @include size(26);
  }

  &.i-large {
    @include size(30);
  }

  &.i-garantia {
    &-veiculo {
      @include bgRetina($img-url + '/icon/ico-garantia-veiculo');
      width: 26px;
      min-width: 26px;
      height: 24px;

      &-white {
        @include bgRetina($img-url + '/icon/ico-garantia-veiculo-branco');
        width: 26px;
        min-width: 26px;
        height: 24px;
      }
    }

    &-imovel {
      @include bgRetina($img-url + '/icon/ico-garantia-imovel');
      width: 26px;
      min-width: 26px;
      height: 24px;

      &-white {
        @include bgRetina($img-url + '/icon/ico-garantia-imovel-branco');
        width: 26px;
        min-width: 26px;
        height: 24px;
      }
    }

    &-recebiveis {
      @include bgRetina($img-url + '/icon/ico-garantia-recebiveis');
      width: 26px;
      min-width: 26px;
      height: 24px;

      &-white {
        @include bgRetina($img-url + '/icon/ico-garantia-recebiveis-branco');
        width: 26px;
        min-width: 26px;
        height: 24px;
      }
    }

    &-maquina_equipamento {
      @include bgRetina($img-url + '/icon/ico-garantia-maquinas');
      width: 26px;
      min-width: 26px;
      height: 24px;

      &-white {
        @include bgRetina($img-url + '/icon/ico-garantia-maquinas-branco');
        width: 26px;
        min-width: 26px;
        height: 24px;
      }
    }
  }

  &.i-step-1 {
    &-0 {
      width: 52px;
      min-width: 52px;
      height: 40px;
      @include bgRetina($img-url + '/icon/ico-fluxo-de-caixa');

      &-white {
        width: 52px;
        min-width: 52px;
        height: 40px;
        @include bgRetina($img-url + '/icon/ico-fluxo-de-caixa-branco');
      }
    }

    &-1 {
      width: 44px;
      min-width: 44px;
      height: 41px;
      @include bgRetina($img-url + '/icon/ico-compre-equipamentos');

      &-white {
        width: 44px;
        min-width: 44px;
        height: 41px;
        @include bgRetina($img-url + '/icon/ico-compre-equipamentos-branco');
      }
    }

    &-2 {
      width: 40px;
      min-width: 40px;
      height: 40px;
      @include bgRetina($img-url + '/icon/ico-expanda');

      &-white {
        width: 40px;
        min-width: 40px;
        height: 40px;
        @include bgRetina($img-url + '/icon/ico-expanda-branco');
      }
    }

    &-3 {
      width: 40px;
      min-width: 40px;
      height: 40px;
      @include bgRetina($img-url + '/icon/ico-estoque');

      &-white {
        width: 40px;
        min-width: 40px;
        height: 40px;
        @include bgRetina($img-url + '/icon/ico-estoque-branco');
      }
    }

    &-4 {
      width: 50px;
      min-width: 50px;
      height: 40px;
      @include bgRetina($img-url + '/icon/ico-quitar-dividas');

      &-white {
        width: 50px;
        min-width: 50px;
        height: 40px;
        @include bgRetina($img-url + '/icon/ico-quitar-dividas-branco');
      }
    }

    &-5 {
      width: 40px;
      min-width: 40px;
      height: 41px;
      @include bgRetina($img-url + '/icon/ico-construcao');

      &-white {
        width: 40px;
        min-width: 40px;
        height: 41px;
        @include bgRetina($img-url + '/icon/ico-construcao-branco');
      }
    }

  }

  &.i-home {
    background-image: url($img-url + '/icon/home-sprite.png');
    width: 16px;
    min-width: 16px;
    height: 14px;
    background-position: 0 -14px;
  }


  &.i-credit-card {
    background-image: url($img-url + '/icon/credit-card.png');
    width: 33px;
    min-width: 33px;
    height: 22px;

    &-blue {
      @include bgRetina("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/icon-credit-card-blue");
      width: 33px;
      min-width: 33px;
      height: 22px;
    }
  }

  &.i-ticket {
    // BOLETO(cód de barras)
    background-image: url($img-url + '/icon/boleto.png');
    width: 33px;
    min-width: 33px;
    height: 22px;

    &-blue {
      @include bgRetina("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/icon-ticket-blue");
      width: 33px;
      min-width: 33px;
      height: 22px;
    }
  }

  &.i-cvv {
    background-image: url($img-url + '/icon/cvv.png');
    width: 28px;
    height: 18px;
  }

  &.i-print {
    // IMPRESSORA
    @include size(26, false, true);
    background-image: url($img-url + '/icon/print.png');
  }

  &.i-laptop-barcode {
    background-image: url($img-url + '/icon/laptop-barcode.png');
    width: 30px;
    min-width: 30px;
    height: 25px;
  }

  &.i-laptop-checked {
    background-image: url($img-url + '/icon/laptop-checked.png');
    width: 31px;
    min-width: 31px;
    height: 26px;
  }

  &.i-calendar {
    background-image: url($img-url + '/icon/calendar.png');
    width: 28px;
    min-width: 28px;
    height: 25px;
  }

  &.i-trash {
    // LIXEIRA
    background-image: url($img-url + '/icon/trash.png');
    width: 14px;
    min-width: 14px;
    height: 19px;
  }

  &.i-card-checked {
    @include bgRetina($img-url + '/icon/icone-sucesso-cartao');
    width: 116px;
    min-width: 116px;
    height: 86px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 64px;
          min-width: 64px;
          height: 47px;
        }
      }
    }
  }

  &.i-card-error {
    @include bgRetina($img-url + '/icon/card-error');
    width: 116px;
    min-width: 116px;
    height: 87px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 64px;
          min-width: 64px;
          height: 47px;
        }
      }
    }
  }

  &.i-erro-boleto {
    @include bgRetina($img-url + '/icon/icone-erro-boleto');
    width: 116px;
    min-width: 116px;
    height: 87px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 64px;
          min-width: 64px;
          height: 47px;
        }
      }
    }
  }

  &.i-erro-pag {
    @include bgRetina($img-url + '/icon/icone-erro-pag');
    width: 116px;
    min-width: 116px;
    height: 100px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 64px;
          min-width: 64px;
          height: 47px;
        }
      }
    }
  }

  &.i-warn {
    @include bgRetina($img-url + '/icon/warn');
    width: 79px;
    min-width: 79px;
    height: 70px;

    &-blue {
      &.i-large {
        @include bgRetina($img-url + '/icon/warn-blue-large');
        width: 100px;
        min-width: 100px;
        height: 89px;
      }
    }
  }

  &.i-round-checked {
    @include bgRetina($img-url + '/icon/round-checked');
    @include size(48, false, true);
  }

  &.i-painel-compras {
    @include bgRetina($img-url + '/icon/painel-compras');
    width: 17px;
    min-width: 17px;
    height: 16px;
  }

  &.i-icon-tip {
    @include bgRetina("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/icon-tip");
    width: 14px;
    min-width: 14px;
    height: 14px;
  }

  &.i-validate-security {
    @include bgRetina($img-url + '/icon/validate-security');
    width: 63px;
    min-width: 63px;
    height: 76px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 35px;
          min-width: 35px;
          height: 42px;
        }
      }
    }
  }

  &.i-perfil {
    @include bgRetina($img-url + '/home/img-perfil');
    width: 525px;
    min-width: 525px;
    height: 285px;
  }

  &.i-ico-recomenda {
    @include bgRetina($img-url + '/icon/ico-recomenda');
    width: 51px;
    min-width: 51px;
    height: 46px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 36px;
          min-width: 36px;
          height: 32px;
        }
      }
    }
  }

  &.i-contabilizei {
    @include bgRetina($img-url + '/icon/logo-contabilizei-azul');
    width: 99px;
    min-width: 99px;
    height: 15px;
  }

  &.i-educa {
    @include bgRetina($img-url + '/icon/ico-educa');
    width: 51px;
    min-width: 51px;
    height: 44px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 36px;
          min-width: 36px;
          height: 31px;
        }
      }
    }
  }

  &.i-beneficios {
    @include bgRetina($img-url + '/icon/ico-beneficios');
    width: 51px;
    min-width: 51px;
    height: 39px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 45px;
          min-width: 45px;
          height: 34px;
        }
      }
    }
  }

  &.i-arrow-back {
    @include bgRetina($img-url + '/icon/seta-2');
    width: 16px;
    min-width: 16px;
    height: 12px;
  }

  &.i-play-mini-gray {
    @include bgRetina($img-url + '/partners/botao-play-mini');
    @include size(19, false, true);
  }

  &.i-play-white {
    @include bgRetina($img-url + '/partners/botao-play');
    @include size(60, false, true);
  }

  &.i-fill {
    @include bgRetina($img-url + '/icon/fill');
    width: 13px;
    min-width: 13px;
    height: 11px;
  }

  &.i-arrow-left {
    @include bgRetina("https://empresas-uat.serasaexperian.com.br/prints/periscope/novo-login/desktop_login_arrow_back.png");
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  &.i-credito {
    @include bgRetina($img-url + '/icon/ico-credito');
    width: 50px;
    min-width: 50px;
    height: 42px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 36px;
          min-width: 36px;
          height: 31px;
        }
      }
    }
  }

  &.i-credito-mockup {
    @include bgRetina($img-url + '/icon/credito-mockup');
    width: 411px;
    min-width: 411px;
    height: 283px;
  }

  &.i-consults-mockup {
    @include bgRetina($img-url + '/icon/consulta-mockup');
    width: 411px;
    min-width: 411px;
    height: 283px;
  }

  &.i-inicio-branco {
    @include bgRetina($img-url + '/icon/icone-inicio-branco');
    width: 29px;
    min-width: 29px;
    height: 35px;
  }

  &.i-inicio-cinza {
    @include bgRetina($img-url + '/icon/icone-inicio-cinza');
    width: 29px;
    min-width: 29px;
    height: 35px;
  }

  &.i-informacoes-branco {
    @include bgRetina($img-url + '/icon/icone-informacoes-branco');
    width: 47px;
    min-width: 47px;
    height: 29px;
  }

  &.i-informacoes-cinza {
    @include bgRetina($img-url + '/icon/icone-informacoes-cinza');
    width: 47px;
    min-width: 47px;
    height: 29px;
  }

  &.i-monitoramento-branco {
    @include bgRetina($img-url + '/icon/icone-monitoramento-branco');
    width: 35px;
    min-width: 35px;
    height: 23px;
  }

  &.i-monitoramento-cinza {
    @include bgRetina($img-url + '/icon/icone-monitoramento-cinza');
    width: 35px;
    min-width: 35px;
    height: 23px;
  }

  &.i-agenda-branco {
    @include bgRetina($img-url + '/icon/icone-agenda-branco');
    width: 32px;
    min-width: 32px;
    height: 30px;
  }

  &.i-agenda-cinza {
    @include bgRetina($img-url + '/icon/icone-agenda-cinza');
    width: 32px;
    min-width: 32px;
    height: 30px;
  }

  &.i-email {
    @include bgRetina($img-url + '/icon/icone-email');
    width: 115px;
    min-width: 115px;
    height: 52px;
  }

  &.i-saude {
    @include bgRetina($img-url + '/icon/icone-saude');
    width: 64px;
    min-width: 64px;
    height: 77px;
  }

  &.i-historico {
    @include bgRetina($img-url + '/icon/icone-historico');
    width: 77px;
    min-width: 77px;
    height: 52px;
  }

  &.i-calendario {
    @include bgRetina($img-url + '/icon/icone-calendario');
    width: 65px;
    min-width: 65px;
    height: 61px;
  }

  &.i-organizador {
    @include bgRetina($img-url + '/icon/icone-organizador');
    width: 65px;
    min-width: 65px;
    height: 61px;
  }

  &.i-seta {
    @include bgRetina($img-url + '/icon/icone-seta');
    width: 37px;
    min-width: 37px;
    height: 25px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 30px;
          min-width: 30px;
          height: 20px;
        }
      }
    }
  }

  &.i-validate-security-success {
    @include bgRetina($img-url + '/icon/validate-security-success');
    width: 63px;
    min-width: 63px;
    height: 76px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 35px;
          min-width: 35px;
          height: 42px;
        }
      }
    }
  }

  &.i-validate-security-error {
    @include bgRetina($img-url + '/icon/validate-security-error');
    width: 63px;
    min-width: 63px;
    height: 76px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 35px;
          min-width: 35px;
          height: 42px;
        }
      }
    }
  }

  &.i-nao-valicdacao {
    @include bgRetina($img-url + '/icon/icone-nao-valicdacao');
    width: 135px;
    min-width: 135px;
    height: 135px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 35px;
          min-width: 35px;
          height: 42px;
        }
      }
    }

    &-dispute {
      @include bgRetina($img-url + '/icon/icone-nao-valicdacao');
      width: 56px;
      min-width: 56px;
      height: 50px;
    }
  }

  &.i-sucesso {
    @include bgRetina($img-url + '/icon/icone-upload-ok');
    width: 135px;
    min-width: 135px;
    height: 135px;

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          width: 35px;
          min-width: 35px;
          height: 42px;
        }
      }
    }

    &-dispute {
      @include bgRetina($img-url + '/icon/icone-upload-ok');
      width: 56px;
      min-width: 56px;
      height: 50px;
    }
  }

  &.i-identidade,
  &.i-contrato-social {
    @include bgRetina($img-url + '/icon/identidade');
    @include size(113, false, true);

    &.i-mini {
      @media (max-width: 767px) {
        &-md {
          @include size(84, false, true);
        }
      }
    }
  }

  &.i-contrato-social {
    @include bgRetina($img-url + '/icon/contrato-social');
  }

  &.i-sair {
    @include bgRetina($img-url + '/icon/sair');
  }

  &.i-central-usuario {
    background-image: url($img-url + '/icon/ic-menu-central.png');
  }

  &.i-historico-compras {
    @include bgRetina($img-url + '/icon/historico-compras');
    width: 36px;
    min-width: 36px;
    height: 37px;

    &-purple {
      @include bgRetina($img-url + '/icon/historico-compras-purple');
      width: 26px;
      min-width: 26px;
      height: 27px;
    }
  }

  &.i-historico-recomendacao {
    &-purple {
      @include bgRetina($img-url + '/icon/historico-recomendacao');
      width: 56px;
      min-width: 56px;
      height: 51px;

      &.i-mini {
        &-lg {
          @media (max-width: 991px) {
            width: 24px;
            min-width: 24px;
            height: 22px;
          }
        }
      }
    }
  }

  &.i-suitcase {
    &-dark-blue {
      @include bgRetina($img-url + '/icon/suitcase-darkblue', '.png', false);
      width: 157px;
      min-width: 157px;
      height: 130px;
    }
  }

  &.i-coin {
    &-purple {
      @include bgRetina($img-url + '/icon/coin-purple', '.png', false);
      width: 52px;
      min-width: 52px;
      height: 40px;

      &.i-mini {
        &-md {
          @media (max-width: 767px) {
            width: 30px;
            min-width: 30px;
            height: 23px;
          }
        }
      }
    }
  }

  &.i-hand-money {
    &-purple {
      @include bgRetina($img-url + '/icon/hand-money-purple', '.png', false);
      width: 46px;
      min-width: 46px;
      height: 35px;

      &.i-mini {
        &-md {
          @media (max-width: 767px) {
            width: 30px;
            min-width: 30px;
            height: 23px;
          }
        }
      }
    }
  }

  &.i-revenue {
    &-purple {
      @include bgRetina($img-url + '/icon/revenue-purple', '.png', false);
      @include size(41, true, true);

      &.i-mini {
        &-md {
          @media (max-width: 767px) {
            @include size(30, true, true);
          }
        }
      }
    }
  }

  &.i-pj {
    &-purple {
      @include bgRetina($img-url + '/icon/pj-purple');
      width: 60px;
      min-width: 60px;
      height: 70px;
    }
  }

  &.i-pf {
    &-purple {
      @include bgRetina($img-url + '/icon/pf-purple');
      width: 75px;
      min-width: 75px;
      height: 56px;
    }
  }

  &.i-btn-remover {
    background-image: url($img-url + '/score/icone-remover.png');
    width: 14px;
    min-width: 14px;
    height: 14px;
  }

  &.i-info {
    @include size(11, true, true);
    border-radius: 50%;
    border: 1px solid #575755;
    color: #575755;
    font-style: normal;
    text-align: center;
    position: relative;

    &::after {
      content: 'i';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      letter-spacing: 0px;
      font-size: 8px;
      line-height: 8px;
      color: #575755;
    }

    &.dusty-grey {
      color: #9b9b9b;
      border: 1px solid #9b9b9b;

      &::after {
        color: #9b9b9b;
      }
    }
  }

  &.i-mail {
    &-blue {
      @include bgRetina($img-url + '/icon/mail-blue');
      width: 20px;
      min-width: 20px;
      height: 13px;
    }
  }

  &.i-sms {
    &-blue {
      @include bgRetina($img-url + '/icon/sms-blue');
      width: 20px;
      min-width: 20px;
      height: 26px;
    }
  }

  &.i-thumbs-up {
    &-white {
      @include bgRetina($img-url + '/icon/thumbs-up', '.png', false);
      width: 17px;
      min-width: 17px;
      height: 20px;
    }
  }

  &.i-close-modal {
    &-white {
      @include bgRetina($img-url + '/icon/close');
      @include size(18, false, true);
    }
  }


  &.i-question-circle {
    @include bgRetina($img-url + '/icon/ico-question-branco');
    width: 10px;
    min-width: 10px;
    height: 10px;
  }

  /*
  * PRODUCTS
  */
  &.i-acesso-credito {
    &.i-mini {
      @include bgRetina($img-url + '/icon/products/acesso-credito');
      width: 17px;
      min-width: 17px;
      height: 15px;
      background-position: 0 -15px;
    }
  }

  &.i-recomenda {
    &.i-mini {
      @include bgRetina($img-url + '/icon/products/recomenda');
      width: 18px;
      min-width: 18px;
      height: 16px;
      background-position: 0 -16px;

      &-md {
        @media (max-width: 767px) {
          @include bgRetina($img-url + '/icon/products/recomenda-big-purple'
          );
          width: 22px;
          min-width: 22px;
          height: 20px;
        }
      }

      &-lg {
        @media (max-width: 991px) {
          @include bgRetina($img-url + '/icon/products/recomenda-big-purple'
          );
          width: 22px;
          min-width: 22px;
          height: 20px;
        }
      }
    }

    &.i-large {
      @include bgRetina($img-url + '/icon/products/recomenda-big-purple');
      width: 62px;
      min-width: 62px;
      height: 56px;
    }

    &.i-medium-white {
      @include bgRetina($img-url + '/icon/products/recomenda-medium-white');
      width: 43px;
      min-width: 43px;
      height: 38px;

      @media (max-width: 767px) {
        &.i-small {
          width: 28px;
          min-width: 28px;
          height: 23px;
          background-position: center;
        }
      }
    }

    &.i-large-white {
      @include bgRetina($img-url + '/icon/products/recomenda-large-white');
      width: 82px;
      min-width: 82px;
      height: 74px;
    }
  }

  &.i-saude-financeira {
    &.i-mini {
      @include bgRetina($img-url + '/icon/products/saude-financeira-mini');
      //background-image: url($img-url + '/icon/products/saude-financeira.png');
      width: 18px;
      min-width: 18px;
      height: 22px;
      background-position: 0 -22px;

      &-md {
        @media (max-width: 767px) {
          @include bgRetina($img-url + '/icon/products/saude-financeira-big-purple'
          );
          width: 26px;
          min-width: 26px;
          height: 32px;
        }
      }

      &-lg {
        @media (max-width: 991px) {
          @include bgRetina($img-url + '/icon/products/saude-financeira-big-purple'
          );
          width: 26px;
          min-width: 26px;
          height: 32px;
        }
      }
    }

    &.i-large {
      //@include bgRetina($img-url + '/icon/products/saude-financeira-big-purple');
      background-image: url($img-url + '/icon/products/saude-financeira-big-purple.png');
      width: 59px;
      min-width: 59px;
      height: 71px;
    }
  }

  /*
  * CREDIT CARDS
  */
  &.i-amex {
    @extend %creditcards;
    background-image: url($img-url + '/icon/creditcards/amex.png');
  }

  &.i-elo {
    @extend %creditcards;
    background-image: url($img-url + '/icon/creditcards/cielo.png');
  }

  &.i-diners {
    @extend %creditcards;
    background-image: url($img-url + '/icon/creditcards/diners.png');
  }

  &.i-hipercard {
    @extend %creditcards;
    background-image: url($img-url + '/icon/creditcards/hypercard.png');
  }

  &.i-master {
    @extend %creditcards;
    background-image: url($img-url + '/icon/creditcards/mastercard.png');
  }

  &.i-visa {
    @extend %creditcards;
    background-image: url($img-url + '/icon/creditcards/visa.png');
  }
}

.opacity-icon {
  opacity: 0.3;
}

/*
* LINKS HOVER HELPER
*/

.icon-hover {

  &:hover,
  &.hover-state,
  &.active {

    .i-acesso-credito,
    .i-recomenda,
    .i-saude-financeira,
    .i-home {
      &.i-mini {
        background-position: 0 0;
      }
    }
  }
}
