.shadow {
  &-1 {
    @include box-shadow(0 0 2px 0 #d9d9d9);
  }

  &-2 {
    @include box-shadow(0 2px 16px 0 rgba(0, 0, 0, 0.08));
  }

  &-3 {
    @include box-shadow(0 0 21px 0 rgba(99, 38, 120, 0.1));
  }
  &-4 {
    @include box-shadow(0 0 20px 0 rgba(0, 0, 0, 0.05));
  }
}
