$btn-action-width: 190;
%btn {
  border-radius: $btn-radius;
  display: inline-block;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: $btn-height;
  padding: 0 $btn-padding;
  text-align: center;
  transition: all 0.2s ease-in;

  @media (max-width: $screen-sm-max) {
    padding: 0 20px;
  }

  span {
    vertical-align: middle;
  }

  &.disabled, &:disabled, &[disabled] {
    opacity: 0.65;
    cursor: default;
    &:hover {
      opacity: 0.65;
    }
  }
}

a {
  transition: all 0.2s ease-in;
}

%submit {
  border-radius: $submit-radius;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.btn {
  @extend %btn;

  &-hero {
    .fa {
      margin-left: 20px;
      font-size: 1.5em;
      vertical-align: sub;
    }
  }

  &.btn-ok {
    padding: 0 20px;
  }

  &-thiner:not(.close) {
    line-height: 36px;
    padding: 0 21px;
  }
  &-thiner-md:not(.close) {
    @media (max-width: $screen-xs-max) {
      line-height: 36px;
      padding: 0 21px;
    }
  }

  &-big {
    padding-bottom: 8px;
    padding-top: 8px;

    &-2 {
      min-width: 270px;
      padding: 8px 26px;

      &.bg-transparent {
        &:hover {
          background-color: rgba(255, 255, 255, 0.2) !important;
        }
        &.btn-white-bordered {
          &:hover {
            border-color: #fff;
          }
        }
      }

      &.font-32 {
        font-size: 32px;
      }
      &.font-28 {
        font-size: 28px;
      }
    }
  }

  &-dark-grey {
    background-color: palette("grey", "dark");
  }

  &-white-pink {
    background-color: #fff;
    color: palette(experian, "pink");
    margin: 16px 10px 0;
    font-weight: 400;
    font-size: 22px;
  }

  &-dark-blue {
    background-color: rgba(palette(experian, "dark-blue"), 0.9);
    color: #fff;
    &:not(.close):not(.btn-big) {
      padding: 0 20px;
    }

    &.color-white {
      &:hover {
        color: #fff;
      }
    }
  }

  &-light-blue {
    background-color: palette(experian, "light-blue");
    color: #fff;
  }

  &-light-blue-bordered {
    background-color: #fff;
    border: 1px solid palette(experian, "light-blue")!important;
    color: palette(experian, "light-blue");
  }
  &-dark-blue-bordered {
    background-color: #fff;
    border: 1px solid palette(experian, "dark-blue")!important;
    color: palette(experian, "dark-blue");
  }

  &-white-bordered:not(.close) {
    background-color: #fff;
    border: 1px solid #959595;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-color: transparent;
    }
  }
}

.btn-ok {
  padding: 0 20px !important;
}

input[type="submit"],
button {
  &:not(.close) {
    @extend %btn;
    @extend %submit;
  }
}

.simple-link {
  display: block;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
}

.border-btn:not(.close) {
  @extend %btn;
  border: 1px solid palette(experian, "dark-blue");
  color: palette(experian, "dark-blue");
}

.btn-experian-pink {
  color: #ffffff !important;
}

.btn-border-dark-blue:not(.close) {
  @extend %btn;
  background: transparent;
  border: 1px solid palette(experian, "dark-blue");
  color: palette(experian, "dark-blue");
  font-size: 14px;

  i.icons {
    margin-left: 15px;
    margin-right: 0;
  }
}

.btn-border-purple:not(.close) {
  @extend %btn;
  background: transparent;
  border: 1px solid #ba2f7d;
  color: #ba2f7d;
  font-size: 14px;

  i.icons {
    margin-left: 15px;
    margin-right: 0;
  }
}

.btn-back-top {
  width: 44px;
  height: 44px;
  display: block;
  background: palette(experian, "purple");
  border-radius: 50%;
  text-align: center;
  line-height: 44px;
  cursor: pointer;

  i {
    color: white;
    font-size: 35px;
  }
}

.icon-library {
  background: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/library.png");
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  width: 25px;
}

.btn-float-register {
  background: url("https://empresas-uat.serasaexperian.com.br/prints/periscope/bg/btn-float-register-yellow.png")
    no-repeat;
  display: none;
  color: #fff;
  font-size: 14px;
  height: 156px;
  padding: 17px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 40px;
  z-index: 1000;
  top: 50%;
  margin-top: -78px;

  &.show {
    display: block;
  }

  &.opacity {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    color: #fff;
  }

  span {
    bottom: 0;
    display: block;
    left: 0;
    padding: 13px;
    position: absolute;
    right: 0;
    top: 0;
    width: 156px;
    transform: rotate(-90deg);
    color: palette(experian, "purple");
  }
}

@include set-class-colors("background-color", "btn", $text-color: true);

.btn-float-action {
  background-color: rgba(palette(experian, "dark-blue"), 0.9);
  display: block;
  margin-top: -50px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 1;
  text-align: left;
  width: $btn-action-width + px;
  height: 113px;
  border-radius: $btn-radius 0 0 $btn-radius;

  position: fixed;
  right: ($btn-action-width * (-1)) + px; //TODO: inverter
  //right: 0;
  top: 50%;
  bottom: 50px;
  z-index: 1000;

  transition-property: right;
  transition-duration: 0.3s;
  //transition-delay: .1s;
  transition-timing-function: ease-in-out;

  :not([href]):not([tabindex]) {
    color: #fff;
  }

  &.show {
    right: 0; //TODO: inverter
    //right: ($btn-action-width * (-1)) + px;
  }
}

// Begin Mobile

@media (max-width: 767px) {
  .btn-float-register {
    background: none;
    background-image: linear-gradient(
      to bottom,
      rgba(59, 68, 136, 0.9),
      rgba(96, 48, 122, 0.9)
    );
    display: block;
    color: #fff;
    height: 60px;
    padding: 8px 10px 10px 10px;
    position: fixed;
    bottom: 0;
    margin: 0;
    text-align: center;
    width: 100%;
    z-index: 1030;
    top: unset;
    margin-top: unset;

    &.show {
      display: block;
    }

    &.opacity {
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      color: #fff;
    }

    span {
      bottom: unset;
      display: block;
      margin: 0 auto;
      left: unset;
      padding: 11px;
      position: unset;
      right: unset;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 300;
      top: unset;
      width: 70%;
      height: 44px;
      transform: none;
      background-color: #fcd731;
      color: #575755;
    }
  }

  .btn.stretch-md {
    min-width: 100%;
  }
}

@media (max-width: 575px) {
  .btn.stretch-sm {
    min-width: 100%;
  }
}
