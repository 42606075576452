/*color*/

.color-yellow {
  color: palette(experian, 'yellow');
}

.color-white {
  color: white;

  &:hover {
    color: white;
  }
}
.color-oslo-gray {
  color: $color-oslo-gray;
}
.color-purple {
  color: palette(experian, 'purple');
}

.color-pink {
  color: palette(experian, 'pink');
}

.color-light-blue {
  color: palette(experian, 'light-blue');
}

.color-dark-blue {
  color: palette(experian, 'dark-blue');
}

.color-polo-blue {
  color: $color-polo-blue;
}

.color-blue-marine {
  color: $color-san-marino;
}

.color-dark-grey {
  color: palette('grey', 'dark');
}

.color-grey {
  color: palette('grey', 'base');
}

.color-light-grey {
  color: $color-custom-grey-light;
}

.color-custom-grey {
  color: $color-custom-grey;
}

.color-custom-grey-image {
  color: $color-custom-grey-images;
}

.color-custom-grey-2 {
  color: $color-custom-grey-tooltip-1;
}

.color-silver-sand {
  color: #cacbcc;
}

.color-violet {
  color: palette(experian, 'violet');
}

.color-light-green {
  color: palette(experian, 'light-green');
}

.color-green-haze {
  color: $color-green-haze;
}

.color-red {
  color: palette(experian, 'red');
}

.color-black {
  color: palette('black', 'base');
}

.color-pale-slate {
  color: $color-pale-slate;
}

.color-tundora {
  color: $color-tundora;
}

.color-buttercop {
  color: $color-buttercop;
}

.color-dusty-gray {
  color: $color-dusty-gray;
}

.color-chicago {
  color: $color-chicago;
}

.color-purple-lightest {
  color: $color-purple-lightest;
}

.color-cerise {
  color: $color-cerise;
}

.color-matisse {
  color: $color-matisse;
}

.color-friar-gray {
  color: $color-friar-gray;
}

.color-danube {
  color: $color-danube;
}

.color-orchid {
  color: $color-orchid;
}

.color-malibu {
  color: $color-malibu;
}

.color-shuttle-gray {
  color: $color-shuttle-gray;
}

.color-danger, .color-monza {
  color: $color-monza;
}

.color-honey-flower, .color-health {
  color: $color-honey-flower;
}
