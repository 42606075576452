.bs-datepicker {
  border-radius: 8px;
  margin: 8px 0 0 0;

  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    background-color: transparent;

    button {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #333;
      padding: 0;
      line-height: 0;

      &.current  {
        padding: 0 20px;
      }

      &[disabled] {
        pointer-events: none;
      }
    }
  }

  .bs-datepicker-body table td {
    color: #828588;
  }

  .bs-datepicker-body table th {
    color: #333;
  }
}

.theme-green .bs-datepicker-body table td.week span {
  color: darken(#333, 10%);
}

.bs-datepicker-body table td span {
  font-size: 14px;
  color: #333;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background: #1d4f91;
  border-radius: 0;
}

.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  border-radius: 0;
  background: #f7f7f8
}
