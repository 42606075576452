.form-v2 {
  .form-group {
    input {
      &[type='text'], &[type='tel'], &[type='password'], &[type='search'], &[type=number] {
        text-align: left !important;
        width: 100%;
        height: 48px;
        font-size: 16px;
        border: solid 1px $silver-color;
        border-radius: 8px;
        color: $metal-black-color;
        padding: 0 16px;
      }

      ::placeholder {
        color: $dark-gray-color;
      }

      &:focus, &.ng-dirty {
        border: 2px solid $royal-blue-color;
        padding: 0 15px;
      }
    }

    .input-button-contained {
      position: relative;
      display: inline-block;
      -webkit-transition: .6s;
      transition: .6s;
      button.contained-button {
        padding: 0;
        position: absolute;
        top: 9px;
        right: 8%;
        height: 30px;
        background-color: transparent;
        color: $silver-sand-color;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        opacity: 0;

        &.active {
          color: $royal-blue-color;
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }
}
