.ngx-slider {
  margin:16px 0!important;
  .ngx-slider-bar-wrapper{
    margin-top: -21px!important;
  }
  .ngx-slider-bar {
    height: 8px !important;
  }
  .ngx-slider-pointer {
    box-shadow: 0 1px 4px 0 rgba(51, 51, 51, 0.32);
    width: 24px!important;
    height: 24px!important;
  }
  .ngx-slider-active:after {
    background: #ffffff!important;
  }
  .ngx-slider-bubble.ngx-slider-ceil,
  .ngx-slider-bubble.ngx-slider-floor {
    bottom:-29px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #c1c2c3;
    opacity: 1!important;
  }

  &.skin-blue {
    .ngx-slider-span {
      &.ngx-slider-bubble {
        &.ngx-slider-model-value {
          color: $color-matisse;
          bottom: 22px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}
