/*
*   @sample:
*
*   <label class="switch align-self-center">
*      <input spellcheck="false"  type="checkbox" />
*      <div class="slider round"></div>
*   </label>
*/

.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
  margin-bottom: 0;

  input {
    display:none;

    &:checked {
      + .slider {
        background-color: #26478d;
      }
      + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #26478d;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

     /* Rounded sliders */
    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  &-giant {
    height: 60px;
    width: 150px;

    input {
      &:checked {
        + .slider:before {
          -webkit-transform: translateX(89px);
          -ms-transform: translateX(89px);
          transform: translateX(89px);
        }
      }
    }

    .slider {
      &:before {
        width: 54px;
        height: 54px;
      }
    }
  }
}

