.toast-v2 {
  position: fixed;
  box-shadow: 0 .25rem 0.75rem rgba(0, 0, 0, .1);
  border-radius: .25rem;
  bottom: -200px;
  right: 0;
  z-index: 1500;
  font-size: .95rem;
  padding: 16px 24px;
  font-weight: 400;
  margin: 24px;
  border: 1px solid;

  &.show {
    bottom: 0;
    transition: bottom .5s ease-out;
  }

  &.hide {
    bottom: -200px;
    transition: bottom .5s ease-in;
  }

  &.toast-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.toast-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.toast-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.toast-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
}