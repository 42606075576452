.modal {
  background: rgba(0, 0, 0, 0.3) !important;
  z-index: 800 !important;

  &.show {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }
}

.modal-backdrop {
  z-index: 700 !important;
}

.modal-xl {
  @media (min-width: 576px) {
    max-width: 845px;

    &.modal-no-header {
      max-width: 900px;
    }

    &:not(.modal-v2) .modal-content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.modal-v2 {
  @media (max-width: 500px) {
    display: flex;
  }

  .modal-header {
    @media (max-width: 767px) {
      padding: 0.5rem 1rem;
    }

    .icon-close {
      width: 12px;
      height: 12px;
    }

    &.thin-header {
      padding: 0.5rem 1rem;

      .modal-title {
        line-height: 27px;
      }
    }
  }

  .modal-content {
    border-radius: 1rem;
    overflow: hidden;
    background: transparent;
    z-index: 1001;
  }

  button.close:not(:disabled) {
    padding: 15px 16px;
    opacity: 1;

    &:hover {
      color: #fff;
      opacity: 0.5;
    }

    @media (max-width: 767px) {
      padding: 13px 16px;
    }
  }

  .btn-area.btn-width {

    button,
    a {
      min-width: 190px;

      span {
        vertical-align: initial;
      }
    }
  }

  &.theme {
    &-consult {
      .modal-header {
        background-color: $theme-consult;
      }
    }

    &-health {
      .modal-header {
        background-color: $theme-health;
      }
    }

    &-credit {
      .modal-header {
        background-color: $theme-credit;
      }
    }

    &-monitoring {
      max-width: 80%;

      .modal-header {
        background-color: #fff;
        border: none;

        h5,
        .close,
        .close:hover {
          color: #333;
          font-weight: 500;
        }
      }
    }
  }
}

.modal-no-header {
  .modal-header {
    position: relative;

    .modal-title {
      width: 100%;
    }
  }

  button.close {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    font-weight: 400;
    font-size: 1.9rem;
    padding: 15px 25px;

    @media (max-width: 767px) {
      font-weight: 300;
      font-size: 2.1rem;
      padding: 0px 14px;
    }
  }
}

.modal-senha {
  max-width: 500px;
}

.modal-credito {
  &.modal-sm {
    max-width: 400px;

    @media (min-width: 430px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.modal-recomenda {
  @media (min-width: 768px) {
    max-width: 580px;
  }

  &.modal-saude {
    @media (min-width: 768px) {
      max-width: 640px;
    }
  }

  &.modal-saude-walk {
    @media (min-width: 768px) {
      max-width: 704px;
    }

    @media (max-width: 767px) {
      position: absolute;
      top: 0;
      bottom: 0;
      left:-2px;
      max-width: -webkit-fill-available;
    }
  }

  &.modal-unavaliable-product {
    @media (max-width: 768px) {
      margin: 0 !important;

      .modal-content {
        border-radius: 0;
      }
    }
  }

  .modal-content {
    border-radius: 1rem;
    overflow: hidden;
    background: transparent;
  }

  button.close {
    padding: 15px 16px;
    opacity: 1;

    &:hover {
      color: #fff;
      opacity: 0.5;
    }
  }

  .btn-area {

    button,
    a {
      min-width: 190px;

      span {
        vertical-align: initial;
      }

      &.btn-130 {
        min-width: 130px;
      }

      &.btn-84 {
        @media (max-width: $screen-xs-max) {
          &-md {
            min-width: 84px;
          }
        }
      }
    }

    .btn-continue {
      background-color: #959595;
    }
  }
}

.modal-finacial-subscription {
  @media (min-width: 768px) {
    max-width: 750px;
  }

  .modal-content {
    border-radius: 1rem;
    overflow: hidden;
    background: transparent;
  }

  button.close {
    padding: 15px 16px;
    opacity: 1;

    &:hover {
      color: #fff;
      opacity: 0.5;
    }
  }

  .btn-area {

    button,
    a {
      min-width: 190px;

      span {
        vertical-align: initial;
      }

      &.btn-130 {
        min-width: 130px;
      }

      &.btn-84 {
        @media (max-width: $screen-xs-max) {
          &-md {
            min-width: 84px;
          }
        }
      }
    }

    .btn-continue {
      background-color: #959595;
    }
  }
}

.modal-dispute {
  @media (min-width: 768px) {
    max-width: 617px;

    &.modal-lg {
      max-width: 737px;
    }
  }

  @media (max-width: 767px) {
    .btn-area {
      button:not(.close) {
        height: 33px;
        line-height: 33px;
      }
    }
  }

  .has-error {

    input[type="text"],
    input[type="password"],
    input[type="search"],
    .field-email,
    textarea {
      border-color: #e30b5d;
    }
  }
}

.modal-transparent {
  .modal-content {
    background-color: transparent;
    border: none;
  }

  button.close {
    @include opacity(1);
    padding: 1rem 0 0.5rem 2rem;

    &:hover {
      @include opacity(0.7);
    }
  }

  .modal-header {
    border: none;
  }

  .modal-body {
    @include border-radius(8px);
    overflow: hidden;
  }
}

.modal-fh-health-subscription-cancellation {
  border-radius: 12px;
  max-width: 800px;
  overflow: hidden;

  .button-spacing {
    @media screen and (min-width: $screen-sm) {
      margin-right: 3rem;
    }
  }
}

.modal-upsell {
  max-width: 350px;
  margin: 24px auto 0;
}

.modal-certificate {
  max-width: 590px;

  @media screen and (min-width: 660px) {
    left: -8px;
  }
}

.new-modal,
.theme-monitoring {
  max-width: 704px;

  &.new-modal-small {
    max-width: 352px;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 8px;
  }

  @media screen and (max-width: 1024px) {
    max-width: unset !important;
    margin: 0 -1px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    .modal-content {
      padding-top: 24px;
      border-radius: 8px 8px 0 0;
    }
  }

  @media screen and (max-width: 368px) {
    bottom: unset;
    top: calc(50% + 40px);
  }
}

.modal-full-screen {
  min-width: fit-content;

  &.new-modal-small {
    max-width: 352px;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 8px;
  }

  @media screen and (max-width: 1024px) {
    max-width: unset !important;
    margin: 0 -1px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    .modal-content {
      border-radius: 8px 8px 0 0;
      min-height: 92vh;
    }
  }
}

.modal-extra-large {
  min-width: 90vw;

  &.new-modal-small {
    max-width: 352px;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 8px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 101vw;
    margin: 0 -1px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    .modal-content {
      border-radius: 8px 8px 0 0;
      min-height: 90vh;
    }
  }
}

.theme-prospecting {
  max-width: 704px;

  &.new-modal-small {
    max-width: 352px;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 8px;
  }

  @media screen and (max-width: 1024px) {
    max-width: unset !important;
    margin: 0 -1px;
    position: fixed;
    bottom: -1vh;
    right: 0;
    left: 0;

    .modal-content {
      padding-top: 24px;
      border-radius: 8px 8px 0 0;
    }
  }
}

.modal-lg.modal-privacy {
  margin: 30px;

  @media (max-width: 375px) {
    width: 350px;
  }

  @media (max-width: 320px) {
    width: 300px;
  }

  @media (min-width: 414px) {
    width: 800px;
  }

  .modal-content {
    border: none;
  }
}

.modal-quick-access{
  .modal-content{
    background-color: #F6F7F9;
  }
}

@media (max-width: 768px) {
  ::ng-deep {
    .modal:not(.modal-v2) {
      .open {
        overflow-y: hidden !important;
      }
    }
  }
}