$default: #828588;
$primary: #1d4f91;

button.btn-v2 {
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    &.btn-v2-primary {
        background-color: $primary;
        color: #fff;
        &:hover {
            background-color: #072245;
        }
    }

    &.btn-v2-outline {
        border: solid 1px $primary;
        background-color: #fff;
        color: $primary;

        &:hover {
            background-color: $primary;
            color: #fff;
        }
    }

    &.btn-v2-default {
        background-color: #fff;
        border: 1px solid $default;
        color: $default;
        &:hover {
            background-color: $default;
            color: #fff;
        }
    }

}