$color-error: #ff483c;
$disabled-border: #a1a3a6;
$disabled-background: #e0e0e1;

.error-text {
    color: $color-error;
    font-size: 10px;
    font-weight: normal;
}

.form-group-v2 {
    input[disabled] {
        border-color: $disabled-border;
        background-color: $disabled-background;
        ~ i {
            border-color: $disabled-border;
            background-color: $disabled-background;
        }
    }

    .ng-invalid {
        &.ng-touched {
            border-color: $color-error;
            
            &::placeholder{
                color: $color-error;
            }
            
            &:not(:placeholder-shown) + label  {
                color: $color-error !important; // !important is necessary
            }

            &.select-selected {
                color: $color-error;
                font-weight: normal;
            }
        }
    }

  
    .input-group-icon {
        display: flex;

        i, .icon {
            display: flex;
            align-items: center;
            border-style: solid;
            border-color: #dddddd;
            min-width: 37px;
        }

        .ng-touched {
            &.ng-invalid, &.ng-invalid:not(:placeholder-shown) {
                ~ i, ~ .icon {
                    border-color: $color-error;
                    path {
                        fill: $color-error;
                    }
                }
            }
        }
        
    }
    .icon-prepend {
        i {
            order: -1;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-width: 1px 0 1px 1px;
            padding-left: 10px;
        }
        input {
            border-left-width: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-left: 4px;
        }
    }

    .icon-append {
        .icon {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-width: 1px 1px 1px 0;
            padding-right: 10px;
        }
        input {
            border-right-width: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 4px;
        }
    }

    .floating-label {
        &.icon-prepend {
            label {
                left: 40px;
            }
            input:not(:placeholder-shown) ~ i path {
                fill: #26478d;
            }
        }
    }
}