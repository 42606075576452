.overlay {
  font-family: Roboto, sans-serif;
  display: block;
  width: 520px;

  > .header {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
    background-color: #fff;
    position: sticky;
    padding: 14px 0;
    z-index: 20;
    top: 0;

    .title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #63666a;
    }

    a {
      position: absolute;
      top: 15px;
      font-size: 20px;
      color: #63666a;

      i {
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
      }

      &.back {
        left: 16px;

        i {
          background-image: url(/assets/svg/arrow-back.svg);
        }
      }

      &.close {
        right: 16px;

        i {
          background-image: url(/assets/svg/icon-close.svg);
        }
      }
    }
  }

  > .content {
    position: relative;
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 58px);

    .final-step {
      display: flex;
      flex-direction: column;
      align-items: center;

      .balloon {
        width: 80px;
        height: 80px;
        border: 16px solid;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        &.success {
          background: #52cc6c url(/assets/svg/icon-check-white.svg) center no-repeat;
          border-color: #e3fcdd;
        }

        &.warning {
          background: #d9a509 url(/assets/svg/icon-process-white.svg) center no-repeat;
          border-color: #fff6d0;
        }
      }

      > span {
        margin: 16px 0 0;
        font-size: 20px;
        font-weight: 500;
        color: #63666a;
        text-align: center;
      }

      > small {
        font-size: 16px;
        color: #8c8d8f;
        padding: 0 12px;
        text-align: center;

        strong {
          font-weight: 500;
        }
      }
    }

    .block {
      padding: 12px 16px 16px;
      border-radius: 8px;
      background-color: #eff0f0;
      margin-bottom: 16px;
      width: 100%;

      .header {
        display: flex;
        color: #333;
        font-weight: 500;
        line-height: 1.5;
        align-items: center;
        border-bottom: 1px solid #e0e0e1;
        padding-bottom: 4px;

        &.no-border {
          border-bottom: none;
        }

        .title {
          font-size: 14px;
          text-transform: uppercase;
        }

        .value {
          font-size: 16px;
          flex: 1;
          text-align: right;
        }
      }

      .content {
        position: relative;

        .info {
          list-style: none;
          font-weight: 500;
          padding: 0;
          margin: 7px 0 0;
          line-height: 1.2;

          li {
            span {
              font-size: 14px;
              color: #828588;

              &.green {
                color: #009f4d;
              }
            }
          }
        }

        .collapse {
          display: block;
          position: absolute;
          right: 0;
          top: 0;

          i {
            background: url(/assets/svg/arrow-down.svg) 0 0 no-repeat;
            transition: transform .2s linear;
            display: block;
            width: 24px;
            height: 24px;

            &.active {
              transform: rotate(180deg);
            }
          }
        }

        .text {
          font-size: 13px;
          color: #63666a;
          display: none;
          margin-top: 16px;

          strong {
            font-weight: 500;
          }

          &.active {
            display: block;
          }
        }
      }

      &.recharge-block {
        display: flex;
        align-items: center;

        > span {
          font-size: 14px;
          color: #333;
          line-height: 1.71;
          flex: 1;
          font-weight: 500;
        }

        .recharge-toggle {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            text-transform: uppercase;
            line-height: 1.6;
            font-size: 12px;
            margin-left: 8px;
            font-weight: 400;
          }
        }
      }
    }

    .tip {
      background-color: #f7f9fa;
      border-radius: 8px;
      padding: 10px 20px;
      margin-bottom: 10px;

      .title {
        line-height: 1.71;
        display: flex;
        color: #63666a;
        align-items: center;

        .dark-title {
          color: #333 !important;
        }

        strong {
          font-size: 14px;
          font-weight: 500;
          width: 50%;
          text-align: left;

          &.full-length {
            width: 100%;
          }
        }

        .price {
          text-align: right;
          font-size: 16px;

          .linethrough {
            text-decoration: line-through;
          }
        }
      }

      .text {
        font-size: 12px;
        line-height: 1.67;
        text-align: left;
        color: #63666a;
        font-weight: 400;

        > small {
          font-size: 12px;
          color: #828588;
        }

        > strong {
          font-size: 14px;
          line-height: 1.43;
          margin: 5px 0;
          display: block;
        }

        &.text-column {
          display: flex;

          strong {
            width: 50%;

            &:nth-child(2) {
              text-align: right;
            }
          }
        }
      }

      button {
        color: #1d4f91;
        font-size: 14px;
        border: 1px solid #1d4f91;
        margin-top: 5px;

        &:hover,
        &:active {
          background-color: transparent;
        }
      }

      &.green {
        background-color: rgba(227, 252, 221, 0.5);

        .title {
          color: #52cc6c;
        }
      }

      &.blue {
        .title {
          .info,
          .price {
            color: #1d4f91;
          }
        }
      }

      &.warning {
        background-color: #fff6d0;
        border: 1px solid #d9a509;

        .title {
          > strong {
            width: 100%;
          }

          .icon-alert {
            background: url(/assets/svg/icon-alert.svg) 0 0 no-repeat;
            width: 20px;
            height: 17px;
            margin-right: 8px;
          }
        }

        .text {
          margin-left: 27px;
          font-size: 14px;

          a {
            color: #1d4f91;
            text-decoration: underline;
          }
        }
      }

      &-voucher {
        background: #e3fcdd;
        color: #52cc6c;
        border: 2px solid #52cc6c;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        height: 56px;
        align-items: center;

        .check-svg {
          color: #52cc6c;
          height: 18px;
          width: 18px;
          margin-right: 8px;
        }

        .voucher-cancel {
          width: auto;
          padding: 0;
          border: 0;
          background: none;
          color: #1d4f91;
          font-size: 14px;
          text-decoration: underline;
          font-weight: normal;
          line-height: normal;
          margin: 0;
        }

        .price {
          font-size: 14px;
        }
      }
    }

    .voucher {
      &-new {
        margin-bottom: 4px;
        position: relative;

        input[type=text] {
          height: 56px;
          padding: 0 16px;
          font-size: 16px;
          border-radius: 8px;
          color: #8c8d8f;
          line-height: 56px;

          &::placeholder {
            font-size: 16px;
          }
        }

        .error-message {
          display: none;
        }

        &.error {
          input[type=text] {
            border: 1px solid #ff483c;
            padding: 16px 16px 0;
          }

          .error-message {
            display: block;
            color: #ff483c;
            font-size: 11px;
            position: absolute;
            top: 4px;
            left: 16px;
            font-weight: 500;
          }
        }
      }

      &-content {
        display: flex;
        align-items: center;
      }

      &-message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-apply {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        width: auto;
        outline: none;
        color: #1d4f91;
        font-size: 12px;
        line-height: 56px;
        padding: 0 18px;

        &.disabled {
          color: #cacbcc;
        }
      }
    }

    .payment-success-box {
      border-radius: 8px;
      border: solid 2px #1d4f91;
      margin-top: 24px;
      text-align: center;

      .title {
        font-size: 20px;
        font-weight: 500;
        color: #333;
        padding: 12px;
        border-bottom: 1px solid #e3e4e6;
      }

      .content {
        padding: 20px;

        .bubble {
          border-radius: 16px;
          background-color: #426da9;
          font-weight: 500;
          color: #fff;
          width: 235px;
          margin: 0 auto;
          padding: 4px;
          text-transform: uppercase;
        }

        .text {
          color: #8c8d8f;
          margin-top: 20px;
        }
      }
    }
  }

  .button-container {
    background-color: #fff;
    border-top: 1px solid #cacbcc;
    position: absolute;
    z-index: 200;
    padding: 20px;
    bottom: 0;
    left: 0;
    right: 0;

    button {
      width: 100%;
      color: #fff;
      font-weight: 500;
      padding: 0;

      &:disabled {
        background-color: #e9e9e9;
      }
    }

    a {
      display: block;
      font-size: 14px;
      line-height: 1.71;
      color: #1d4f91;
      text-decoration: underline;
      text-align: center;
      margin-top: 10px;
      font-weight: 400;
    }

    .btn-green {
      background: #52CC6C;
    }

    .btn-green:disabled {
      background: #d9dadb;
    }
  }

  .form-control {
    height: 3.5em;
    padding: 1em 0 0 1em;

    &-placeholder {
      position: absolute;
      top: 1rem;
      left: 1rem;
      transition: all .25s ease-in-out;
      font-size: 16px;
      color: #63666a;
      cursor: initial;
      pointer-events: none;
      font-weight: 400;
    }

    &:focus+.form-control-placeholder,
    &:valid+.form-control-placeholder {
      transform: translate(0, -100%);
      opacity: 1;
      color: #406eb3;
      font-size: 12px;
      top: 1.545rem;
    }
  }
}

.custom-checkbox label {
  background-image: none;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 1023px) {
  .overlay {
    width: 100%;

    > .header {
      .title {
        font-size: 18px;
      }
    }

    > .content {
      padding: 24px 16px;
      height: unset !important;

      .block {
        .content {
          .info {
            li {
              span {
                font-size: 11px;
              }
            }
          }

          .text {
            font-size: 12px;
          }
        }

        &.recharge-block {
          .recharge-toggle {
            span {
              font-size: 10px;
            }
          }
        }
      }

      .tip {
        .title {
          .price {
            font-size: 14px;
          }
        }
      }
    }

    .button-container {
      /*position: sticky;*/
      position: fixed;
    }
  }
}

@media (min-width: 768px) {
  .overlay {
    >.content {
      .tip .voucher {
        &-message {
          display: block;
          font-size: 14px;
        }

        &-cancel {
          margin-left: 8px;
        }

        .price {
          font-size: 16px;
        }
      }

      .voucher-new {
        input[type=text] {
          font-size: 16px;
        }
      }

      .voucher-apply {
        font-size: 14px;
      }
    }
  }
}
