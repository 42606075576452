.cards-home {
  font-family: Roboto, sans-serif;

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .btn-card-default {
    background: #fff;
  }

  .btn-card-default,
  .btn-card-color {
    display: inline-block;
    margin: 16px 10px 0;
    font-size: 22px;
    padding: 0px 20px;
  }

  &-media,
  &-description {
    flex: 1 346px;
  }

  &-media {
    text-align: center;

    .media-video {
      width: 100%;
      height: 282px;
    }
  }

  &-title {
    display: flex;
    align-self: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 7px;

    .serasa-icon {
      margin-right: 12px;
    }
  }

  &-text {
    font-size: 32px;
    line-height: 42px;
    margin-top: 12px;
  }

  &-list {
    .serasa-icon {
      margin-right: 9px;
    }

    font-size: 14px;
    list-style: none;
    padding: 0;
  }

  .description-content {
    margin: 0 7px;
  }

  .cards-home-list {
    padding-left: 20px;
    list-style-image: url(https://empresas-uat.serasaexperian.com.br/prints/periscope/icon/fill.png);

    li {
      padding-left: 5px;
    }
  }
}

@media (min-width: 768px) {
  .cards-home {

    &-media,
    &-description {
      padding: 0 30px;
    }

    &-title {
      font-size: 24px;
      justify-content: left;
      margin: 0;
      line-height: 42px;

      .serasa-icon {
        margin-right: 20px;
      }
    }

    &-text {
      font-size: 48px;
      line-height: 48px;
      margin-top: 30px;
    }

    &-list {
      font-size: 18px;
    }

    .description-content {
      margin-left: 70px;
    }

    .btn-card-default,
    .btn-card-color {
      margin: 20px 10px 0;
    }
  }
}
